import { lazy, memo } from "react";
import TreePosCell from "./cell-types/TreePosCell";

import { useStyles } from "./cell.styles";
import { IProps } from "./cell.types";
import { getType } from "./getCellType";
import CounterCell from "./cell-types/CounterCell";

const ProgressBarCell = lazy(() => import("./cell-types/ProgressBarCell"));
const ButtonCell = lazy(() => import("./cell-types/ButtonCell"));
const CheckableCell = lazy(() => import("./cell-types/CheckableCell"));
const CheckedInOutCell = lazy(() => import("./cell-types/CheckedInOutCell"));
const EditableCell = lazy(() => import("./cell-types/EditableCell"));
const HtmlCell = lazy(() => import("./cell-types/HtmlCell"));
const TextCell = lazy(() => import("./cell-types/TextCell"));
const BreadcrumbCell = lazy(() => import("./cell-types/BreadcrumbCell"));
const JSXCell = lazy(() => import("./cell-types/JSXCell"));
const AvailableInCell = lazy(() => import("./cell-types/AvailableInCell"));
const LinksCell = lazy(() => import("./cell-types/LinksCell"));
const PersonaCell = lazy(() => import("./cell-types/PersonaCell"));
const MobileMenuCell = lazy(() => import("./cell-types/MobileMenuCell"));
const SelectionCell = lazy(() => import("./cell-types/SelectionCell"));
const DateCell = lazy(() => import("./cell-types/DateCell"));

// detects if mouse is highlighting a text
const isHighlighting = () => {
  return window.getSelection && window.getSelection().type === 'Range';
};

export default memo((props:IProps) => {
  const classes = useStyles();

  const { rowIndex, cellData, column, columnIndex, rowData } = props;
  const originalRowIndex = props.options.getCurrentFilteredIndex(rowIndex);

  let customElement;

  switch (props.column.key) {
    case "__selection__": {
      customElement = (
        <SelectionCell
          column={ column }
          rowIndex={ rowIndex }
          rowData={ rowData}
          rowStatus={ props.rowStatus }
        />
      );
      break;
    }
    case "__mobileMenu__": {
      customElement = (
        <MobileMenuCell
          rowIndex={ rowIndex }
          onContextMenu={ props.options.onContextMenu }
        />
      );
      break;
    }
  }

  if (!customElement) {
    switch (props.column.type) {
      case undefined:
      case "number":
      case "text": {
        customElement = (
          <TextCell
            cellData={ cellData }
            column={ column }
            selLocale={ props.options.selMultiLang } 
          />
        );
        break;
      }
      case "date": {
        customElement = (
          <DateCell
          cellData={ cellData }
          />
        );
        break;
      }
      case "treepos": {
        customElement = (
          <TreePosCell
            cellData={ cellData }
            column={ column }
            originalRowIndex={ originalRowIndex }
            originalData={ props.options.originalData }
            expandedTreeRows={ props.options.expandedTreeRows }
            onTreeViewToggle={ props.options.onTreeViewToggle }
          />
        );
        break;
      }
      case "html": {
        customElement = (
          <HtmlCell
            cellData={ cellData }
          />
        );
        break;
      }
      case "persona": {
        customElement = (
          <PersonaCell
            cellData={ cellData }
          />
        );
        break;
      }
      case "availablein": {
        customElement = (
          <AvailableInCell
            availableIn={ props.options.availableIn }
            originalRowIndex={ originalRowIndex }
          />
        );
        break;
      }
      case "links": {
        customElement = (
          <LinksCell
            cellData={ cellData }
            links={ props.options.links }
            originalRowIndex={ originalRowIndex }
          />
        );
        break;
      }
      case "jsx": {
        customElement = (
          <JSXCell
            cellData={ cellData }
          />
        );
        break;
      }
      case "counter": {
        customElement = (
          <CounterCell
            cellData={ cellData }
          />
        );
        break;
      }
      case "breadcrumb": {
        customElement = (
          <BreadcrumbCell
            cellData={ cellData }
          />
        );
        break;
      }
      case "editable": {
        customElement = (
          <>
            <EditableCell
              column={column}
              columnIndex={columnIndex}
              originalRowIndex={originalRowIndex}
              cellData={cellData}
              rowStatus={props.rowStatus as string}
              action={
                // multi editable columns support
                props.options.onEditableClick.callback
                  ? props.options.onEditableClick
                  : props.options.onEditableClick[column.key]
              }
            />
            {column.hasCheckboxInCell && !!cellData && (
              <CheckableCell
                column={column}
                columnIndex={columnIndex}
                originalRowIndex={originalRowIndex}
                cellData={[cellData[2],""]}
                action={props.options.onEditableCheckClick}
                askConfirm={props.column.askConfirm !== false}
              />
            )}
          </>
        );
        break;
      }
      case "checkinout": {
        customElement = (
          <CheckedInOutCell
            column={ column }
            columnIndex={ columnIndex }
            originalRowIndex={ originalRowIndex }
            cellData={ cellData }
            onChange={ props.options.onCellClick }
          />
        );
        break;
      }
      case "check": {
        customElement = (
          <CheckableCell
            column={ column }
            columnIndex={ columnIndex }
            originalRowIndex={ originalRowIndex }
            cellData={ cellData }
            action={ props.options.onEditableCheckClick }
            askConfirm={ props.column.askConfirm !== false }
          />
        );
        break;
      }
      case "button": {
        customElement = (
          <ButtonCell
            column={ column }
            columnIndex={ columnIndex }
            originalRowIndex={ originalRowIndex }
            cellData={ cellData }
            action={ props.options.onButtonClick }
          />
        );
        break;
      }
      case "progress": {
        customElement = (
          <ProgressBarCell
            cellData={ cellData }
          />
        );
        break;
      }
    }
  }

  const handleClick = (e) => {
    const adv = Array.isArray(cellData);
    
    if (isHighlighting()) return;

    if (props.column.type === "check") return;
    
    if (adv && props.options.onAdvCellClick && cellData[1]) {
      props.options.onAdvCellClick({
        second_arg: cellData[1],
        rowIndex, 
        columnIndex: props.columnIndex,
      });
    }
    
    if (column.clickable === true && props.options.onCellClick ) {
      props.options.onCellClick({
        rowIndex, 
        columnIndex: props.columnIndex,
        columnKey: props.column.key
      });
    }
  }

  const type = getType({
    cellData,
    column,
    rowStatus: props.rowStatus,
    columnIndex: props.columnIndex,
    originalRowIndex,
    additionalErrors: props.options.additionalErrors,
  });

  return (
    <div
      onClick={ handleClick }
      className={ type + " " + classes.cell }
    >
      { customElement }
    </div>
  );
});