import { Api } from "./api";

import { initializeApp } from "firebase/app";
import { getMessaging as FirebaseGetMessaging, getToken } from "firebase/messaging";

// firebase for push notifications subscrption
const firebaseConfig = {
  apiKey: "AIzaSyBPtO3R-eddrmnhT85hux8XHAAdB5qdn0w",
  authDomain: "drass-dwa.firebaseapp.com",
  projectId: "drass-dwa",
  storageBucket: "drass-dwa.appspot.com",
  messagingSenderId: "681838677350",
  appId: "1:681838677350:web:0601da88e187ea0657cca7"
};

export const getMessaging = () => {
  const app = initializeApp(firebaseConfig);
  return FirebaseGetMessaging(app);
};

export const subscribe = () => {
  const messaging = getMessaging();
  
  navigator.serviceWorker.getRegistration().then(registrations => {
    const initFirebaseToken = () => getToken(messaging, {
      vapidKey: 'BFcHtPidbGFERiU1nOBLvCZtNDUaPJjygD2N94SX7B30KJK0Vlf3TerjV-vy1pCgWLvCt2XtXn2g15EcbHQ6SWw',
      serviceWorkerRegistration: registrations
    }).then((currentToken) => {
      if (currentToken) {
        Api.get({
          api: "notifications/subscribe",
          data: {
            token: currentToken
          }
        });
      }
      // request notifications permission
      else {
        Notification.requestPermission().then(permission => {
          if (permission === "granted") {
            initFirebaseToken();
          }
        });
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  
    // check if the browser supports notifications
    if ("Notification" in window) {
      initFirebaseToken();
    }
  });
};