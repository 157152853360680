import React from "react";

import { isEmail } from "src/utils";
import { TagPicker } from "src/comps";
import { ValidationState } from "@fluentui/react";

export default (props) => {
  return (
    <TagPicker
      getTextFromItem={ (item) => item }
      pickerSuggestionsProps={{
        noResultsFoundText: "",
        suggestionsHeaderText: (
          "Insert a valid email address and then press enter"
        ),
      }}
      onResolveSuggestions={(filter,selectedItems) => {
        return [];
      }}
      defaultSelectedItems={ (props.emails || []).map(email => (
        { key: '', name: email }
      )) }
      onValidateInput={(input) => {
        return isEmail(input) ? ValidationState.valid : ValidationState.invalid;
      }}
      createGenericItem={(input) => {
        return { key: '', name: input };
      }}
      inputProps={{
        placeholder: ""
      }}
      onChange={(items) => {
        props.onChange(items.map(item => item.name));
      }}
    />
  );
};
