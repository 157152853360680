import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("3px"),
    ...shorthands.padding("5px", "10px"),
  },
  columnName: {
    fontWeight: "bold",
    ...shorthands.padding("5px", "5px"),
  }
});