import errors from "./errors";
import { getApiUrl } from "./getApiUrl";

export const notify = (msg) => {
  const div = document.createElement('div');
  const containerStyle = "position:fixed;bottom:10px;right:10px;padding:10px 20px;min-width: 200px;background: var(--grid-odd-bg);z-index:9999";
  div.id = "api-msg";
  div.innerHTML = (
    '<div style="' + containerStyle + '">' +
    '<p>' + msg + '</p>' +
    '</div>'
  );
  document.body.appendChild(div);
  setTimeout(
    () => document.getElementById("api-msg").remove(),
    4000
  );
};

export const startLoading = () => {
  if (document.getElementById("api-loading")) {
    return;
  }
  const div = document.createElement('div');
  div.id = "api-loading";
  div.innerHTML = '<div class="lds-ring"><div></div><div></div><div></div><div></div></div>';
  document.body.appendChild(div);
};

export const stopLoading = () => {
  document.getElementById("api-loading")?.remove();
};

export const showError = async ({ msg, desc = "", url = "", data = {}, req = {}, ticket = true }) => {
  const error = errors[msg];
  const message = error[1];

  let content = (
    '<h1>&#128583;</h1>' +
    '<h2>Oops! Something went wrong</h2>' +
    '<p>' + message + '</p>' +
    '<p>' + desc + '</p><br />' +
    '<p><b>If you need assistance, please open a ticket here:</b></p>'
  );
  if (ticket === true) {
    content  += (
      '<form id="ticket-form">' +
        '<textarea id="ticket-content" placeholder="Describe the problem"></textarea><br />' +
        '<button type="submit">Open ticket</button>' +
      '</form>'
    );
  }

  const div = document.createElement('div');
  div.id = "api-error";
  div.innerHTML = content;
  document.body.appendChild(div);

  // automatic ticket creation
  if (ticket === true) {
    document
      .getElementById("ticket-form")
      .addEventListener("submit", async (e) => {
        e.preventDefault();

        const userDefinedContent = (document.getElementById("ticket-content") as HTMLInputElement).value;

        document.getElementById("ticket-form").innerHTML = "Loading...";

        const ticketId = await Api.get({
          api: "ticket/create",
          data: {
            code: error[0],
            message,
            description: desc,
            url,
            data,
            req,
            custom: userDefinedContent
          }
        });

        document.getElementById("ticket-form").innerHTML = "<h2>Thank you! Ticket #" + ticketId + " created";
      });
  }
};

const onFetchError = ({ url, data = {}, req }) => {
  showError({
    msg: "API_ERROR_MSG",
    url,
    data,
    req
  });
  stopLoading();
};

export class ApiCommunication {
  getApiUrl = getApiUrl;
  
  async request({ url, req, type, signal }) {
    try {
      const request = await fetch(url, {
        method: "post",
    
        // support for sending form data in the case of a file upload request
        body: (type == "upload") ? req : JSON.stringify(req),
    
        cache: "no-cache",
        mode: "cors",
        credentials: "include",
        signal
      });
    
      // handle internal server error (500)
      if (request.status !== 200) {

        // check for session expired error
        if (request.status === 401) {
          // refresh to display login page
          window.location.reload();
          return;
        }
        onFetchError({ url, data: request, req });
      }
    
      // this API handling allows both json and blob responses, in order to allow
      // the same API system to be used for file downloads too
      return (type == "download") ? request.blob() : request.json();
    }
    catch (err) {
      if (err.name == "AbortError") { // handle abort()
        return;
      }
      onFetchError({ url, req });
    }
  }

  // parse Api response checking for its status
  parseResponse({
    data, f = (_) => {}, s, path, req
  }) {
    if (data) {
      if (data.status == "failed") {
        switch(data.notice) {
          case "auth_err": { // authorization error
            showError({
              msg: "AUTHORIZATION_ERROR_MSG",
              url: path,
              data,
              req,
              ticket: false
            });
            break;
          }
          case "query_err": { // query not successfull
            showError({
              msg: "QUERY_ERROR_MSG",
              url: path,
              data,
              req
            });
            break;
          }
          case "email_err": { // email not successfull
            showError({
              msg: "EMAIL_ERROR_MSG",
              url: path,
              data,
              req
            });
            break;
          }
          case "erp_err": { // params not right
            showError({
              msg: "ERP_ERROR_MSG",
              url: path,
              data,
              req
            });
            break;
          }
          case "param_err": { // params not right
            showError({
              msg: "PARAM_ERROR_MSG",
              url: path,
              data,
              req
            });
            break;
          }
          case "service_reject_err": { // ERP/... reject
            showError({
              msg: "REJECTED_REQUEST_BY_SERVICE",
              url: path,
              data,
              req
            });
            break;
          }
          case "light_err": { // light reject error
            showError({
              msg: "LIGHT_ERROR",
              desc: data.info,
              url: path,
              data,
              req
            });
            break;
          }
        }
        return f(data);
      }
      // if status is 'ok' launch the success function if requested
      else return (
        s ? s(data.data ?? data) : (data.data ?? data)
      );
    }
    else {
      showError({
        msg: "NETWORK_ERROR_MSG",
        url: path,
        data,
        ticket: false
      });
    }
    // in case of a parameter error reported by the APIs
    return f(data);
  }

  // function to construct the request url
  getUrl({ isExternal = false, path = "", par = {} }) {
    // add absolute path and extension to the requested URL if part of the APIs
    let url = (isExternal) ? path : (this.getApiUrl() + path)

    // set GET parameters
    for (var p in par) url += "&" + p + "=" + par[p];

    return url;
  }

  // actual Api function for request firing and handling
  // supports both success/failure functions firing and await return data
  async call(o) {
    // show loading
    if (!o.silent) startLoading();

    // response handling (url, data)
    let data = await this.request({
      url: this.getUrl({
        isExternal: o.external,
        path: o.path,
        par: o.params
      }),
      req: (o.data || { report: "no-data" }),
      type: o.type || "",
      signal: o.signal || undefined
    });

    if (!o.silent) {
      stopLoading();
    }

    if (!data) {
      return;
    }
    
    if (!o.silent) stopLoading();

    // if a blob (file download) has been requested, nothing should be parsed
    // instead the file should be downloaded
    if (o.type == "download") {
      let file = window.URL.createObjectURL(data);
      let a = <HTMLAnchorElement>document.createElement('A');

      a.href = file;
      a.target = "_blank";

      // if a filename is provided the file should be forced to be downloaded
      // and with that specific name
      if (o.filename) {
        a.download = o.filename;
      }

      a.click();
      return o.success ? o.success() : null;
    }

    // return the parsed response through succes/failure functions
    // taking into account all the options passed as parameter
    return this.parseResponse({
      data,
      f: o.failure,
      s: o.success,
      path: o.path,
      req: o.data
    });
  }

  async get({
    api, data = {}, msg = "", type = undefined, filename = "",
    success = undefined, failure = () => {}, silent = false,
    signal = undefined
  }) {
    return await this.call({
      path: api,
      type,
      data,
      filename,
      silent,
      signal,
      success: (data) => {
        if (msg) {
          notify(msg);
        }
        if (success) {
          success(data);
        }
        return data;
      },
      failure: failure
    });
  }
  
  async download({
    api, data = {}, msg = "", filename = "", silent = false
  }) {
    return this.get({
      api,
      data,
      msg,
      type: 'download',
      filename,
      silent
    });
  }
}

export const Api = new ApiCommunication();