/*
  Custom form mechanism
  ----------------------------------------
  this is a highly customizable form dialog that allows to display an
  unlimited number of controlled fields dynamically edited (required or not)
  that forms a sendable object to be retrived.

  Custom labels, field types, keys, cascading controlled fields, button texts
  and many other features can all be provided as a single configuration object
  passed in as unique prop.

  This supports fields default values, placeholders and checks.
  Supported field types are: select, text, multiline (with or without controls),
  check, multi-email, date, custom, empty, input-picker
*/

import { useState, useEffect, useContext, lazy } from "react";

import Moment from "moment";

import { MainContext } from "src/utils";
import {
  Grid,
  DatePicker,
  PeoplePicker,
  MultiEmail,
  Toggle,
  TextField,
  InputPicker,
  Select,
} from "src/comps";

import {
  IconButton,
  Text,
  Stack,
  Label,
  Callout,
  Shimmer,
  Link,
  PrimaryButton,
  DefaultButton,
  ActionButton,
  MaskedTextField,
  FontIcon,
  TooltipHost,
} from "@fluentui/react";
import { Input } from "@fluentui/react-components";
import { useStyles } from "./index.styles";

const errorStyles = {
  root: {
    fontSize: 10,
    fontWeight: "normal",
    color: "#973131",
  },
};

const RichTextEditor = lazy(() => import("src/comps/form/richTextEditor"));

function removeTags(str) {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
}

export function isFormFullfilled({ fields = [], values = {}, errors = {} }) {
  // Check for unfilled required fields
  const unfilledRequiredFields = (fields || []).filter((field) => {
    // Destructure field properties with defaults
    let {
      key = "value",
      required = false,
      disabled = false,
      type = "text",
      formatting = false,
    } = field;

    // Evaluate 'disabled' if it's a function
    if (typeof disabled === "function") {
      disabled = disabled(values);
    }

    // Skip disabled fields
    if (disabled) {
      return false;
    }

    // Evaluate 'required' if it's a function
    if (typeof required === "function") {
      required = required(values);
    }

    // Skip non-required fields
    if (!required) {
      return false;
    }

    // Get the value for the field
    const value = values[key];

    // Special handling for multiline fields with formatting
    if (type === "multiline" && formatting === true) {
      if (typeof value === "string") {
        const cleanValue = removeTags(value).toString().trim();
        // If cleaned value is empty, field is unfilled
        return cleanValue.length === 0;
      }

      // If value equals " " or "<p><br/></p>", field is unfilled
      return value === " " || value === "<p><br/></p>";
    }

    // General check for unfilled value
    if (value == null || value === "") {
      // value is null, undefined, or empty string
      return true; // Field is unfilled
    }

    // If value is an object, check its properties
    if (typeof value === "object") {
      // Check if the object has meaningful data
      const hasValueProperty = value.value != null && value.value !== "";
      const hasKeyProperty = value.key != null && value.key !== "";
      const isNonEmptyObject = Object.keys(value).length > 0;

      if (!hasValueProperty && !hasKeyProperty && !isNonEmptyObject) {
        return true; // Field is unfilled
      }
    }

    // Field is filled
    return false;
  });

  // Check if there are any errors
  const hasErrors = Object.values(errors).some((e) => e);

  // Form is fulfilled if there are no unfilled required fields and no errors
  return unfilledRequiredFields.length === 0 && !hasErrors;
}



export const Form = (props) => {
  const Store = useContext(MainContext);
  const classes = useStyles();

  const [reset, setReset] = useState(
    props.reset !== undefined ? props.reset : false
  );
  const [values, setValues] = useState(props.defaultValues || {});
  const [errors, setErrors] = useState(props.defaultErrors || {});
  const [cleared, setCleared] = useState([]);
  const [isCalloutVisible, setIsCalloutVisible] = useState({});
  const [focusKey, setFocusKey] = useState(props.focusKey || "");

  // shimmer timeout for data rerender
  useEffect(() => {
    if (!props.reset) {
      setReset(false);
    }
  }, [values, errors]);

  // optional custom values/errors onChange events
  useEffect(() => {
    if (props.onValuesChange) {
      props.onValuesChange(values);
    }
  }, [values]);
  useEffect(() => {
    if (props.onErrorsChange) {
      props.onErrorsChange(errors);
    }
  }, [errors]);

  // custom reset firing
  useEffect(() => {
    if (props.reset !== undefined) {
      if (props.reset === true) {
        setValues(props.defaultValues || {});
        setErrors(props.defaultErrors || {});
        setCleared([]);
      }
      setReset(props.reset);
    }
  }, [props.reset]);

  // function to validate input
  async function checkValidation({
    key,
    validate,
    newValue,
    reset = true,
  }): Promise<boolean> {
    if (validate) {
      let valid = await validate({ newValue, values, key });

      // validation result can be an array in which the first elements defines
      // the fields key on which to display the error
      if (Array.isArray(valid)) {
        key = valid[0];
        valid = valid[1];
      }

      if (reset) {
        setReset(true);
      }
      const e = { ...errors };
      e[key] = valid === true ? undefined : valid;
      setErrors(e);

      return valid === true;
    }
  }

  const fields = (props.fields || []).map(
    (
      {
        key = "value",
        type = "input",
        placeholder = "",
        options = [],
        text = "",
        defaultValue = "",
        label = "",
        required = false,
        multi = false,
        readOnly = false,
        JSXComponent = null,
        width = "250px",
        height = "auto",
        showIf = [],
        onChangeFlush = [],
        checks = [],
        hidden = false,
        disabled = false,
        onChange = undefined,
        selectAll = false,
        deselectAll = false,
        clearable = false,
        parseValue = undefined,
        addNew = { text: "", href: "" },
        mode = "",
        isSearchable = true,
        minChar = undefined,
        formatting = false,
        validate = undefined,
        itemLimit = undefined,
        requestParams = undefined,
        maxLength = undefined,
        buttonStyle = "primary",
        iconProps = {},
        min = undefined,
        max = undefined,
        step = 1,
        labelDescription = undefined,
        mask = undefined,
        gridProps = undefined,
        onClick = undefined,
        info = "",
        customMenuOption = undefined,
        onCustomMenuOptionClick = undefined,
        getNameFromKey = false,
        fullYear = false,
        useCalendar = false,
        onText = "On",
        offText = "Off",
        forceReset = false,
        allowFreeform = undefined,
      },
      i
    ) => {
      let field = null;

      // allow "label" to be a function that returns a string
      if (label && {}.toString.call(label) === "[object Function]") {
        label = label(values);
      }

      // allow "type" to be a function that returns a string
      if (type && {}.toString.call(type) === "[object Function]") {
        type = type(values);
      }

      // allow "customMenuOption" to be a function that returns a string
      if (
        customMenuOption &&
        {}.toString.call(customMenuOption) === "[object Function]"
      ) {
        customMenuOption = customMenuOption(values);
      }

      // allow "disabled" to be a function that returns a boolean
      if (disabled && {}.toString.call(disabled) === "[object Function]") {
        disabled = disabled(values);
      }

      // allow "gridProps" to be a function that returns a boolean
      if (gridProps && {}.toString.call(gridProps) === "[object Function]") {
        gridProps = gridProps(values);
      }

      // allow "requestParams" to be a function that returns a boolean
      if (
        requestParams &&
        {}.toString.call(requestParams) === "[object Function]"
      ) {
        requestParams = requestParams(values);
      }

      // allow "defaultValue" to be a function that returns a boolean
      if (
        defaultValue &&
        {}.toString.call(defaultValue) === "[object Function]"
      ) {
        defaultValue = defaultValue(values);
      }

      // allow "hidden" to be a function that returns a boolean
      if (hidden && {}.toString.call(hidden) === "[object Function]") {
        hidden = hidden(values);
      }

      // allow "showIf" to be a function that returns a boolean
      if (showIf && {}.toString.call(showIf) === "[object Function]") {
        if (!showIf(values)) {
          return null;
        }
      }
      // conditional field display
      else if (
        showIf &&
        ((values[showIf[0]] || {}).value !== undefined
          ? (values[showIf[0]] || {}).value
          : values[showIf[0]]) != showIf[1]
      ) {
        return null;
      }

      // allow "options" to be a function that returns the actual options
      if (!Array.isArray(options)) {
        options = options(values);
      }

      // allow "checks" to be a function that returns the actual checks
      if (!Array.isArray(checks)) {
        checks = checks(values);
      }

      // show a shimmer on modal reset
      // calendar should not reset not to lose selected dates
      // TODO: make calendar reset too
      if (reset && type != "date") {
        field = (
          <Shimmer
            styles={{
              root: { marginTop: "2px" },
              shimmerWrapper: {
                height: type == "multiline" ? "60px" : "30px",
              },
            }}
          />
        );
      } else {
        if (type == "custom") {
          field = <div style={{ width }}>{JSXComponent()}</div>;
        } else if (type == "grid") {
          field = <Grid {...gridProps} />;
        } else if (type == "empty") {
          field = <div />;
        } else if (type == "select") {
          // single selects default Value should work by adding a
          // placeholder with the label of the selected value plus the
          // value of the currently iterated field should be setted
          if (defaultValue && !multi && !cleared[key]) {
            values[key] = values[key] || defaultValue;
            placeholder = (
              defaultValue.label
                ? defaultValue.label
                : (
                    options.filter(
                      (opt) => opt.value == defaultValue.value
                    )[0] || {}
                  ).label || ""
            ).toString();
          } else if (checks && multi) {
            values[key] = values[key] || checks;
          }

          if (values[key] && (values[key].label || multi)) {
            placeholder = (values[key].label || "").toString();
            if (multi) {
              checks = values[key];
            }
          }

          const hasSelectedOption =
            Array.isArray(values[key]) && values[key].length;

          field = (
            <Stack tokens={{ childrenGap: 8 }} horizontal>
              <Select
                options={options}
                multi={multi}
                checks={checks}
                readOnly={readOnly}
                defaultValue={cleared[key] ? undefined : defaultValue}
                placeholder={placeholder}
                disabled={disabled}
                isSearchable={isSearchable}
                isClearable={multi}
                style={multi ? { width: "200px" } : { width }}
                onChange={(option) => {
                  setFocusKey(key);
                  setReset(true);
                  const v = { ...values };
                  v[key] = option;

                  // flush related values if needed
                  if (onChangeFlush.length) {
                    for (let k of onChangeFlush) {
                      v[k] = "";
                    }
                  }
                  // set new value with custom callback if requested
                  setValues(onChange ? onChange(v) : v);
                }}
              />

              {multi && hasSelectedOption && (
                <div id={"call" + i}>
                  <IconButton
                    iconProps={{ iconName: "View" }}
                    title="List"
                    styles={{ root: { marginTop: "2px" } }}
                    ariaLabel="List"
                    onClick={() => {
                      setFocusKey(key);
                      const call = { ...isCalloutVisible };
                      call[key] = call[key] ? !call[key] : true;
                      setIsCalloutVisible(call);
                    }}
                  />
                </div>
              )}

              {isCalloutVisible[key] && hasSelectedOption ? (
                <Callout
                  styles={{ root: { maxWidth: 300, padding: 10 } }}
                  role="alertdialog"
                  gapSpace={0}
                  target={`#call${i}`}
                  onDismiss={() => setIsCalloutVisible({})}
                  setInitialFocus
                >
                  <Stack tokens={{ childrenGap: 5 }}>
                    {values[key].map((val) => {
                      return (
                        <Text>
                          {(options.filter((o) => o.value == val)[0] || {})
                            .label || val}
                        </Text>
                      );
                    }) || []}
                  </Stack>
                </Callout>
              ) : null}
            </Stack>
          );
        } else if (type == "date") {
          if (defaultValue && !cleared[key]) {
            values[key] = values[key] || defaultValue;
            placeholder = values[key] || defaultValue;
          }
          if (values[key]) {
            placeholder = values[key];
          }

          field = (
            <DatePicker
              multi={multi}
              fullYear={fullYear}
              useCalendar={useCalendar}
              value={values[key]}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readOnly}
              ariaLabel="Select a date"
              onSelectDate={(value) => {
                if (!multi) {
                  setReset(true);
                  setFocusKey(key);
                }
                const v = { ...values };
                checkValidation({
                  key,
                  validate,
                  newValue: value,
                });
                if (!multi) {
                  v[key] = Moment(value).format("YYYY-MM-DD");
                } else {
                  v[key] = value.map((date) =>
                    Moment(date.toString()).format("YYYY-MM-DD")
                  );
                }
                setValues(onChange ? onChange(v) : v);
              }}
            />
          );
        } else if (type == "check") {
          if (defaultValue && values[key] === undefined) {
            values[key] = values[key] || defaultValue;
          }
          field = (
            <Toggle
              {...(values[key] ? { defaultChecked: true } : {})}
              onText={onText}
              offText={offText}
              disabled={disabled}
              onChange={(ev, isChecked) => {
                if (forceReset) {
                  setReset(true);
                }
                setFocusKey(key);
                const v = { ...values };
                v[key] = isChecked;
                setValues(onChange ? onChange(v) : v);
              }}
            />
          );
        } else if (type == "button") {
          if (defaultValue && values[key] === undefined) {
            values[key] = values[key] || defaultValue;
          }

          let Button = null;
          switch (buttonStyle) {
            case "default":
              Button = DefaultButton;
              break;
            case "action":
              Button = ActionButton;
              break;
            default:
              Button = PrimaryButton;
          }

          const onClickFn = async () => {
            setReset(true);

            const result = await onClick({ values, errors });
            setErrors(result.errors);
            setValues(result.values);

            if (result) {
              setTimeout(() => {
                setReset(false);
                if (props.onButtonClick) {
                  props.onButtonClick({ values });
                }
              });
            }
          };

          field = (
            <Button
              text={text}
              iconProps={iconProps}
              disabled={disabled}
              onClick={onClickFn}
            />
          );
        } else if (type == "input-picker") {
          if (defaultValue && values[key] === undefined && !cleared[key]) {
            values[key] = defaultValue;
          }

          field = (
            <InputPicker
              mode={mode || "pn"}
              selected={values[key] || {}}
              minChar={minChar}
              disabled={disabled}
              itemLimit={itemLimit}
              requestParams={requestParams}
              allowFreeform={allowFreeform}
              getNameFromKey={getNameFromKey}
              onChange={async (selected) => {
                setFocusKey(key);
                setReset(true);

                let v = { ...values };
                if (Array.isArray(selected)) {
                  selected.forEach((sel) => {
                    checkValidation({
                      key,
                      validate,
                      newValue: sel,
                    });
                  });
                } else {
                  checkValidation({
                    key,
                    validate,
                    newValue: selected,
                  });
                }

                // on empty object returned make it undefined (otherwise if
                // required the form button will not be disabled)
                if (
                  !Array.isArray(selected) &&
                  Object.entries(selected).length === 0
                ) {
                  selected = {};
                }

                v[key] = selected;

                // set new value with custom callback if requested
                if (onChange) {
                  v = await onChange(v);
                }
                setValues(v);
              }}
            />
          );
        } else if (type == "people-picker") {
          if (defaultValue && !values[key]) {
            values[key] = defaultValue;
          }
          field = (
            <PeoplePicker
              limit={itemLimit}
              placeholder={placeholder}
              defaultValue={values[key]}
              onChange={(selected) => {
                setFocusKey(key);
                setReset(true);
                const v = { ...values };
                if (Array.isArray(selected)) {
                  selected.forEach((sel) => {
                    checkValidation({
                      key,
                      validate,
                      newValue: sel,
                    });
                  });
                } else {
                  checkValidation({
                    key,
                    validate,
                    newValue: selected,
                  });
                }
                v[key] = selected;
                setValues(v);
              }}
            />
          );
        } else if (type == "multi-email") {
          if (defaultValue) {
            values[key] = values[key] || defaultValue;
          }
          field = (
            <MultiEmail
              emails={values[key]}
              onChange={(emails) => {
                setFocusKey(key);
                setReset(true);
                const v = { ...values };
                v[key] = emails;
                setValues(v);
              }}
            />
          );
        } else if (type == "label") {
          if (defaultValue) {
            values[key] = values[key] || defaultValue;
          }
          field = (
            <Label>
              <span dangerouslySetInnerHTML={{ __html: values[key] }} />
            </Label>
          );
        } else if (type == "number") {
          if (values[key] === undefined && defaultValue !== undefined) {
            values[key] = values[key] || defaultValue;
          }

          const onInputChange = (e, data) => {
            let newValue = data.value;
            const v = { ...values };
            setFocusKey(key);
            if (onChange) {
              setReset(true);
            }
            checkValidation({
              key,
              validate,
              newValue,
              reset: false,
            });
            if (parseValue) {
              const parsed = parseValue(newValue);
              if (parsed != newValue) {
                setReset(true);
                newValue = parsed;
              }
            }
            v[key] = newValue;
            setValues(onChange ? onChange(v) : v);
          };

          field = (
            <Input
              type="number"
              className={classes.input}
              min={min}
              max={max}
              step={step}
              value={values[key]}
              disabled={disabled}
              onChange={onInputChange}
            />
          );
        } else if (type == "multiline" && formatting === true) {
          if (values[key] === undefined) {
            values[key] = defaultValue || " ";
          }
          if (values[key]) {
            defaultValue = values[key];
          }
          field = (
            <RichTextEditor
              value={defaultValue}
              readOnly={readOnly || disabled}
              maxLength={maxLength || 500}
              onChange={(newValue) => {
                setFocusKey(key);
                const v = { ...values };
                v[key] = newValue;
                setValues(v);
              }}
            />
          );
        } else if (type === "masked") {
          if (defaultValue && values[key] === undefined) {
            values[key] = defaultValue;
          }
          if (values[key]) {
            defaultValue = values[key];
          }

          // look for the mask validity
          const maskValidation = ({ newValue }) => {
            const maskCharCurrentOccurrences = newValue.split("_").length - 1;
            const maskCharTotalOccurrences = mask.split("9").length - 1;
            if (
              maskCharCurrentOccurrences != maskCharTotalOccurrences &&
              maskCharCurrentOccurrences > 0
            ) {
              return "Incomplete";
            }
            return true;
          };

          field = (
            <MaskedTextField
              value={defaultValue}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              mask={mask}
              validateOnFocusOut
              onChange={(e, newValue) => {
                setFocusKey(key);
                if (onChange) {
                  setReset(true);
                }
                checkValidation({
                  key,
                  validate: maskValidation,
                  newValue,
                  reset: false,
                });
                const v = { ...values };
                checkValidation({
                  key,
                  validate,
                  newValue,
                  reset: false,
                });
                if (parseValue) {
                  const parsed = parseValue(newValue);
                  if (parsed != newValue) {
                    setReset(true);
                    newValue = parsed;
                  }
                }
                v[key] = newValue;
                setValues(onChange ? onChange(v) : v);
              }}
            />
          );
        } else {
          if (defaultValue && values[key] === undefined && !cleared[key]) {
            values[key] = defaultValue;
          }
          if (values[key]) {
            defaultValue = values[key];
          }
          field = (
            <TextField
              multiline={type == "multiline" ? true : false}
              type={type && type != "multiline" ? type : "text"}
              defaultValue={cleared[key] ? undefined : defaultValue}
              disabled={disabled}
              readOnly={readOnly}
              placeholder={placeholder}
              maxLength={maxLength || 500}
              autoFocus={focusKey === key}
              onChange={(_, newValue) => {
                setFocusKey(key);
                if (onChange) {
                  setReset(true);
                }
                const v = { ...values };
                checkValidation({
                  key,
                  validate,
                  newValue,
                  reset: false,
                });
                if (parseValue) {
                  const parsed = parseValue(newValue);
                  if (parsed != newValue) {
                    setReset(true);
                    newValue = parsed;
                  }
                }
                v[key] = newValue;
                setValues(onChange ? onChange(v) : v);
              }}
            />
          );
        }
      }

      // "required" might be a function
      let isRequired = required;
      if (typeof isRequired === "function") {
        isRequired = isRequired(values);
      }

      return (
        <div
          key={i}
          style={{
            width,
            height,
            display: hidden ? "none" : "block",
          }}
        >
          {label ? (
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack horizontal>
                <Label required={isRequired}>{label}</Label>
                {info && (
                  <TooltipHost content={info}>
                    <FontIcon
                      aria-label="Info"
                      iconName="Info"
                      style={{
                        cursor: "pointer",
                        marginTop: 5,
                        marginLeft: 5,
                        marginRight: 5,
                      }}
                    />
                  </TooltipHost>
                )}
                {labelDescription && (
                  <Label styles={{ root: { fontWeight: "normal" } }}>
                    {labelDescription}
                  </Label>
                )}
              </Stack>
              {customMenuOption && (
                <Link
                  onClick={async () => {
                    setReset(true);
                    const v = await onCustomMenuOptionClick({ ...values });
                    setValues(v);
                  }}
                >
                  {customMenuOption}
                </Link>
              )}
              {selectAll && (
                <Link
                  onClick={() => {
                    setReset(true);
                    const v = { ...values };
                    v[key] = options
                      .filter((o) => !o.disabled)
                      .map((o) => o.value);
                    setValues(v);
                  }}
                >
                  Select All
                </Link>
              )}
              {selectAll && deselectAll && <Label>-</Label>}
              {deselectAll && (
                <Link
                  onClick={() => {
                    setReset(true);
                    const v = { ...values };
                    v[key] = [];
                    setValues(v);
                  }}
                >
                  Deselect All
                </Link>
              )}
              {clearable && (
                <Link
                  onClick={() => {
                    setReset(true);
                    const v = { ...values };
                    v[key] = undefined;
                    setValues(v);

                    // set field as cleared
                    const c = { ...cleared };
                    c[key] = true;
                    setCleared(c);
                  }}
                >
                  Clear
                </Link>
              )}
              {addNew.text && (
                <Link
                  onClick={async () => {
                    // a function can be defined instead of a link
                    if (addNew.function) {
                      setReset(true);

                      const val = await addNew.function(values);
                      setValues(val);
                      setTimeout(() => setReset(false));
                    } else {
                      Store.navigate(addNew.href);
                    }
                  }}
                >
                  {addNew.text}
                </Link>
              )}
            </Stack>
          ) : null}
          {field}
          {errors[key] ? (
            <Label styles={errorStyles}>{errors[key]}</Label>
          ) : null}
        </div>
      );
    }
  );

  return (
    <Stack wrap tokens={{ childrenGap: 15 }} horizontal>
      {fields}
    </Stack>
  );
};
