import { lazy } from "react";
import { Button } from "@fluentui/react-components";
import { ArrowCircleRight24Regular, ArrowCircleDown24Regular } from "@fluentui/react-icons";
import { hasChildren } from "../treeView";

const TextCell = lazy(() => import("./TextCell"));

interface ITreePosCellProps {
  cellData: Array<string>;
  column: any;
  originalRowIndex: number;
  originalData: Array<any>;
  expandedTreeRows: Array<any>;
  onTreeViewToggle?: Function;
};

// if a tree view mode is requested then the first col should display
// the +/- button is needed for navigation
export default (props:ITreePosCellProps) => {
  const { cellData, column, originalData, originalRowIndex, onTreeViewToggle, expandedTreeRows } = props;
  const adv = Array.isArray(cellData);
  const content = adv ? cellData[0] : cellData;

  if (!cellData[0]) {
    return null;
  }

  const treeHasChildren = (adv && cellData[3] !== undefined) ? cellData[3] : hasChildren(
    content,
    originalRowIndex, 
    originalData
  );

  const onToggle = (e) => {
    e.stopPropagation();
    onTreeViewToggle(content);
  };
  
  const beforeContent = treeHasChildren ? (
    <Button
      appearance="transparent"
      icon={ expandedTreeRows[
        content.replace(" ", "")
      ] ? <ArrowCircleDown24Regular /> : <ArrowCircleRight24Regular /> }
      onClick={ onToggle }
    />
  ) : <div style={{ float: "left", width: "34px", height: "50px" }} />;

  // get text ignoring has children flag
  const textCellData = adv ? cellData.slice(0,3) : cellData;
  
  return (
    <>
      { beforeContent }
      { (column.hideContent !== true ) && (
        <TextCell
          cellData={ textCellData }
          column={ column }
        />
      )}
    </>
  );
}