const MAX_LENGTH = 27;
const MIN_LENGTH = 5;
const MULTIPLY_FACTOR = 8;
const EDIT_BTN = 5;
const CHECKBOX_WIDTH = 3;
const FILTER_SELECT_WIDTH = 6;
const BUTTON_WIDTH = 3;
const CHECKED_IN_OUT_WIDTH = 7;
const TREE_POS_BTN = 5;

// calculate all lengths first (less function calls)
function calcColumnWidth({ matrix, cols, treeView }) {
  // initialize length of each column to minum length
  let maxLength = [];
  for (let col of cols) {
    // consider length of header cell too
    const header = col.title;
    if (header && (+header.length * 1.2) > MIN_LENGTH) {
      maxLength[col.key] = +header.length * 1.2;
    }
    else {
      maxLength[col.key] = MIN_LENGTH;
    }

    if (col.type === "button") {
      maxLength[col.key] -= 5;
    }
  }

  // get max data length for each column (50 elements max)
  const lengthRowsToIterate = matrix.length < 50 ? matrix.length : 50;
  for (let rowIndex = 0; rowIndex < lengthRowsToIterate; rowIndex++) {
    for (let col of cols) {
      const cell = matrix[rowIndex][col.key];
      if (!cell) continue;

      let data = Array.isArray(cell)
        ? (cell[0] || "").toString() : (cell || "").toString();

      if (data && +data.length > maxLength[col.key]) {
        maxLength[col.key] = +data.length;
      }
    }
  }

  // calculate actual with for every column
  let widths = {}, gridWidth = 0;
  for (let col of cols) {
    // forced width option
    if (col.width !== undefined) {
      gridWidth += (widths[col.key] = col.width * MULTIPLY_FACTOR);
    }
    
    if (!widths[col.key]) {
      // calc the total width of the column multipling by an hardcoded 8.4
      // coefficient calculated through in browser UI testing.
      // take into account that special columns might need to be larger
      gridWidth += (widths[col.key] = (
        ((maxLength[col.key] > MAX_LENGTH) ? MAX_LENGTH : maxLength[col.key]) +
        ((col.type === "treepos" && treeView) ? MIN_LENGTH : 0) +
        ((col.selectable === true) ? MIN_LENGTH : 0) +
        ((col.type === "treepos") ? TREE_POS_BTN : 0) +
        ((col.type === "editable") ? EDIT_BTN : 0) +
        ((col.type === "check") ? CHECKBOX_WIDTH : 0) +
        ((col.type === "button") ? BUTTON_WIDTH : 0) +
        ((col.filter?.type !== undefined) ? FILTER_SELECT_WIDTH : 0) +
        ((col.sortable === true) ? FILTER_SELECT_WIDTH : 0) +
        ((col.type === "checkinout") ? CHECKED_IN_OUT_WIDTH : 0)
      ) * MULTIPLY_FACTOR);
    }
  }

  return { widths, gridWidth };
}

// tells if a column has got a fixed width or is setted as non responsive
const isColFixed = (colInfo) => {
  if (!colInfo) return true;

  // forced width option or not-responsive option
  if (colInfo.width !== undefined) {
    return true;
  }
  if (colInfo.responsive === false) {
    return true;
  }
  return false;
};

// rearrange column widths to better fit in the grid view (actually changes the
// width of a col temporarly if the calculated grid view is smaller than the
// grid width returned by the AutoSizer). This is not recalulated responsively.
const calcResponsiveColWidth = (width, gridWidth, widths, column) => {
  let index_width = widths[column.key] || 200, calc_augment = 0;

  // if the column widths sum is less than the available width,
  // recalculate until width is fullfilled
  if (gridWidth < width) {

    // the remaining width is to be equally reparted between each
    // column (i.e. divided by the columns count)
    // forced width columns shouldn't be counted in the calculation
    calc_augment = (width - gridWidth - 15) / (Object.keys(widths).filter(colKey => {
      return !isColFixed(colKey);
    }).length);
  }
  // return column calculated width
  return index_width + (isColFixed(column) ? 0 : calc_augment);
};

// export all functions and constants for testing purposes
// only calcColumnWidth is used by parent components
export {
  MAX_LENGTH, MIN_LENGTH, MULTIPLY_FACTOR, CHECKBOX_WIDTH, FILTER_SELECT_WIDTH,
  CHECKED_IN_OUT_WIDTH, calcColumnWidth, calcResponsiveColWidth
};
