import React, { memo } from "react";
import { TextField, ITextFieldProps } from "@fluentui/react";

export default memo((props:ITextFieldProps) => {
  const fieldProps:ITextFieldProps = {
    ...props,
    styles: {
      fieldGroup: { borderColor: "#ddd" },
      ...(props.styles || {})
    },
  };
  return (
    <TextField { ...fieldProps } />
  );
});
