// custom sort by num
export const sortByNum = ({ data, sortBy = [,], index = 0 }) => {
  return data.sort((a, b) => {
    a = (a[index] || "");
    b = (b[index] || "");

    // support for advanced cell types (arrays with data as first element)
    if (Array.isArray(a)) {
      a = a[0];
    }
    if (Array.isArray(b)) {
      b = b[0];
    }

    // get num parts
    const a_parts = a.split(".");
    const b_parts = b.split(".");

    for (let i = 0; i < a_parts.length; i++) {
      // if equal until the last part that does not exsist on b, then b is a
      // children of a
      if (!b_parts[i]) {
        return (sortBy[1] ? -1 : 1);
      }

      // if the part number is longer that it is larger
      if (b_parts[i].length > a_parts[i].length) {
        return (sortBy[1] ? 1 : -1);
      }
      // if the part number is longer that it is larger
      if (b_parts[i].length < a_parts[i].length) {
        return (sortBy[1] ? -1 : 1);
      }

      if (a_parts[i] > b_parts[i]) {
        return (sortBy[1] ? -1 : 1);
      }
      if (a_parts[i] < b_parts[i]) {
        return (sortBy[1] ? 1 : -1);
      }
    }
    return 0;
  });
};

// sort data matrix by requested prop if any (check special cols for out of the
// norm sorting mechanisms request)
export const sortData = (data, sortBy, cols) => {
  const index = cols.indexOf(cols.filter(c => c.key == sortBy[0])[0]);
  
  // actual sorting function
  const sortFn = (a, b) => {
    if (!a || !b) return -1;

    a = (a[index] || "");
    b = (b[index] || "");

    // support for advanced cell types (arrays with data as first element)
    if (Array.isArray(a)) {
      a = a[0];
    }
    if (Array.isArray(b)) {
      b = b[0];
    }

    if (!sortBy[1]) {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    }
    else {
      if (a < b) return 1;
      if (a > b) return -1;
      return 0;
    }
  };

  // null is high prop, means a priority col, in which null values should be
  // considered low priority (high values). This is done by replacing the null
  // value with a really high and improbable value
  if (cols[index] && cols[index].nullIsHigh) {
    return data.map(row => {
      if (row[index] == null) {
        if (Array.isArray(row[index])) {
          row[index][0] = 123456789123456789;
        }
        else {
          row[index] = 123456789123456789;
        }
      }
      return row;
    })
    .sort((a, b) => sortFn(a, b))
    .map(row => {
      if (row[index] == 123456789123456789) {
        if (Array.isArray(row[index])) {
          row[index][0] = null;
        }
        else {
          row[index] = null;
        }
      }
      return row;
    });
  }
  // sort by num if requested by cols props, that means sorting by tree
  // position identifiers (e.g. 1.2.1.3).
  else if (cols[index]
    && cols[index].type === "treepos") {
    // custom sort version
    // See stackoverflow.com/q/50415200/sort-an-array-of-arrays-in-javascript
    return sortByNum({ data, sortBy, index });
  }
  // otherwise filter as usual by defined property (standard sort)
  else if (index !== undefined) {
    data.sort((a, b) => sortFn(a, b));
  }
  return data;
};
