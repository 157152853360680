export const cols = [
  {
    key: "certificate",
    title: "Certificate",
    filter: {
      type: "text"
    },
    sortable: true,
    width: "37"
  },
  {
    key: "insert",
    title: "Inserted by",
    filter: {
      type: "select"
    },
    sortable: true,
    type: "persona",
    width: "25"
  }
];