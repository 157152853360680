import "./App.css";

import { lazy, useContext, Suspense, useEffect } from "react";

import { Routes, Route, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { MainContext } from "src/utils";
import { Dialog } from "src/comps";

import { useStyles } from "./PageViewer.styles";
import platforms from "./platforms.json";
import PAGES from "../pages";

const Login = lazy(() => import("src/comps/login"));
const Header = lazy(() => import("src/comps/header"));
const Nav = lazy(() => import("src/comps/nav"));
const Sidebar = lazy(() => import("src/comps/sidebar"));
const Sections = lazy(() => import("src/comps/sections"));

// automatically generate lazy components based on the list of 
// pages available in the comps utils platforms definition js file
const pages = [].concat.apply([], Object.values(platforms).map(platform => {
  return Object.values(platform.pages)
    .filter(page => page[1] === "P")
    .map(page => {
      const path = page[0].toString().split("/");

      // dash based to camel case module names
      let module_name = path[path.length - 1].replace(/\-/g, " ").split(" ");
      module_name = module_name.map(part => {
        return part.charAt(0).toUpperCase() + part.slice(1);
      });
      const modName = (
        platform.id.toUpperCase() + "_" +
        path[0].replace(/\-/g, "") + "_" +
        (path[1] ? (path[1].replace(/\-/g, "") + "_") : "") +
        module_name.join("")
      );
      
      return [
        "/" + platform.id + "/" + page[0] + "/*",
        PAGES[modName]
      ];
    }
  );
}));

export default observer(() => {
  const Store = useContext(MainContext);
  const classes = useStyles();

  // update app on route change
  const location = useLocation();
  useEffect(() => {
    // app initialization can only happen after session being confirmed
    if (Store.isLoggedIn()) {
      // fire app init with current route path (removing leading slash)
      Store.initApp(location.pathname.substring(1));
    }
  }, [location]);

  if (!Store.isLoggedIn()) {
    return (
      <div id="pageViewer" style={{ top: 0 }}>
        <Suspense fallback={<div></div>}>
          <Header />
          <Login />
        </Suspense>
      </div>
    );
  }

  return (
    <div className={ classes.container }>
      <Suspense fallback={<div></div>}>
        <Header />
      </Suspense>

      <Dialog { ...Store.getConfirm() } />

      <div className={ classes.content }>
        <Suspense fallback={<div></div>}>
          <Nav />
        </Suspense>

        <div style={{ position: "relative", flexGrow: 1 }}>
          <Suspense fallback={<div></div>}>
            <Sections />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <Routes>
              {
                pages.map((page, i) => {
                  const Component = page[1];
                  return (
                    <Route
                      key={ i }
                      path={ page[0] }
                      element={ <Component /> }
                    />
                  );
                })
              }
            </Routes>
          </Suspense>
        </div>
        
        <Suspense fallback={<div></div>}>
          <Sidebar />
        </Suspense>
      </div>
    </div>
  );
});
