import { useContext } from "react";
import { ContextualMenu } from "@fluentui/react";
import { MainContext } from "src/utils";
import { ModalDescriptor } from "src/comps/dialogs/ModalDescriptor";
import { IConfirmDialog } from "src/types";
import { ConfirmDialogDescriptor } from "src/comps/dialogs/ConfirmDialogDescriptor";

interface ContextMenuOption {
  key: string;
  name: string;
  disabled: boolean;
  hidden?: boolean;
  icon: string;
  fn: Function;
  sub?: Array<ContextMenuOption>;
};

interface IProps {
  id?: string;
  options: Array<ContextMenuOption>;
  onDismiss: any;
  rowIndex: number;
  pos: Array<number>;
};

const generateMenuOptions = (Store, index, option) => {
  return {
    key: index.toString(),
    text: option.name,
    disabled: option.disabled,
    subMenuProps: (
      option.sub ? {
        items: option.sub.filter(
          (option:ContextMenuOption) => !option.hidden
        ).map((option:ContextMenuOption, index:number) => {
          return generateMenuOptions(Store, index, option);
        })
      } : undefined
    ),
    iconProps: option.icon ? { iconName: option.icon } : null,
    onClick: async () => {
      if (!option.disabled && option.fn) {
        if (option.fn instanceof ModalDescriptor) {
          Store.openModal(option.fn.id, option.fn.props);
        }
        else {
          const result = await option.fn();
          if (result instanceof ConfirmDialogDescriptor) {
            Store.setConfirm(result.props);
          }
        }
      }
    }
  }
};

export const contextMenu = (props:IProps) => {
  const Store = useContext(MainContext);
  
  const menuItems = [
    ...props.options.filter(
      (option:ContextMenuOption) => !option.hidden
    ).map(
      (option:ContextMenuOption, index:number) => {
        return generateMenuOptions(Store, index, option);
      }
    ),
    {
      key: "singleView",
      text: "Single View",
      iconProps: {
        iconName: "EntryView"
      },
      onClick: () => {
        Store.openModal("single-view-" + props.id)
      }
    }
  ];
  
  const target = {
    x: props.pos[0],
    y: props.pos[1]
  };

  return (
    <ContextualMenu
      items={ menuItems }
      hidden={ false }
      onItemClick={ props.onDismiss }
      onDismiss={ props.onDismiss }
      target={ target }
    />
  );
};
