import { sortByNum } from "./sorting";

// generate background color for grayscale trees to better understand depth
// usually used in tree where tree positions numbers are not shown
export const getBgColor = (row) => {
  if (!row) return;

  const pos = Array.isArray(row[0]) ? row[0][0] : row[0];
  const numParts = pos.toString().split(".");

  if (numParts.length == 1) {
    return "";
  }

  const addendum = 0 + (numParts.length % 5) * 0.1;
  return "rgb(92, 200, 195, " + addendum + ")";
}

// function that tells if a certain position has nodes
// checks very next element since tree views are always sorted by num!
export const hasChildren = (position, index, data) => {
  // format position number
  position = position.toString().replace(/\s/, "") + ".";
  
  let veryNext = data[index + 1];
  if (!veryNext) return false;
  veryNext = Array.isArray(veryNext[0]) ? veryNext[0][0] : veryNext[0];
  return (veryNext || "").toString().replace(/\s/, "").indexOf(position) == 0;
};

// function used to toggle a determined tree view branch
export const toggleBranch = ({ position, expandedTreeRows }) => {
  position = position.toString().replace(/\s/, "");

  const rows = { ...expandedTreeRows };
  rows[position] = !rows[position];

  // children trees should be hidden on one of the fathers being toggled
  for (const [key, value] of Object.entries(rows)) {
    if (key.indexOf(position) == 0 && key != position) {
      rows[key] = false;
    }
  }
  return rows;
};

// function to transform a normal generated grid into a tree view
export const generateTree = ({ grid, expand }) => {
  // constraint that all elements in a column should either be all represented
  // as arrays or none
  const useArr = Array.isArray((grid[0] || [])[0]);

  // iterate only expanded nums ("true" in "expand" array)
  const keys = Object.entries(expand).filter(([key, value]) => value)
    .map(([key, value]) => key);

  // sort by num
  grid = sortByNum({ data: grid });

  // foreach row (if not everything is shown)
  if (keys.length != grid.length) {
    let iteratedBranch = undefined;
    grid = grid.filter(row => {
      const data = (useArr ? row[0][0] : row[0]).replace(/\s/g, "");

      // display lonely branches as closed (needed for "contract all")
      if (!iteratedBranch || (data + ".").indexOf(iteratedBranch) != 0) {
        iteratedBranch = data + ".";
        return true;
      }

      let found = false, i = 0;
      while (!found && i < keys.length) {
        // use row if it is a child
        // after removing the parent part from a num check if the remaining is
        // just an integer
        // (es. V: 1.444.1 - 1.444. = 1; X: 1.2.1.3 - 1.2 = 1.3)
        if (
          data.indexOf(keys[i]) == 0
          && data.replace(keys[i] + ".", "").replace(/[0-9]/g, "").length == 0
        ) {
          found = true;
          continue;
        }
        i++;
      }

      // branches with no dots or being with no parents
      if (!found && data.indexOf(".") < 0) {
        return true;
      }

      return found;
    });
  }

  return grid;
};
