import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { getUserImg, MainContext } from "src/utils";
import { Avatar } from "@fluentui/react-components";
import { IPersona, IUser } from "src/types";

interface IPersonaWithStyles extends IPersona {
  styles?: React.CSSProperties;
}

export default observer((props: IPersonaWithStyles) => {
  const Store = useContext(MainContext);

  const [userImg, setUserImg] = useState<string>("");
  const [user, setUser] = useState<IUser>(undefined);

  useEffect(() => {
    let usr = undefined;

    
    if (!isNaN(+props.user) && +props.user !== null && props.user !== undefined) {
      // If props.user is a string containing a number, treat it as a number
      usr = Store.getUser(Number(props.user));
    } else if (typeof props.user === "string") {
      // If props.user is a non-numeric string, use the name-based method
      usr = Store.getUserFromName(props.user);
    } else {
      // If props.user is neither a string nor a number, just use it directly
      usr = props.user;
    }

    

    if (!usr && props.allowInvalidUser !== true) {
      usr = undefined;
    }

    if ((!usr || !usr.fullName) && props.allowInvalidUser === true) {
      usr = {
        img: "",
        fullName: props.user,
        company_id: 0,
        email: "",
        id: 0,
      };
    }

    if (usr) {
      setUser(usr);
      if (usr.img) {
        getUserImg({ path: usr.img }).then((img) => {
          setUserImg(img);
        });
      } else {
        setUserImg("");
      }
    } else {
      setUserImg("");
    }
  }, [props.user]);

  if (!props.user || !user?.fullName) {
    return null;
  }

  const personaStyles = {
    display: "flex", // Flexbox layout to align avatar and text horizontally
    flexGrow: 1,
    alignItems: "center", // Vertically center the avatar with the text
    marginTop: props.isGrid ? "-8px" : "3px",
    padding: props.iconOnly ? "0" : "0 10px",
    cursor: "pointer",
  };

  const secondaryText =
    props.iconOnly || props.nameOnly
      ? undefined
      : props.secondaryText ||
        (user?.fullName ? Store.getCompanies(user.company_id)?.name || "" : "");

  const onPersonaClick = () => {
    if (user.email && !props.silent) {
      Store.openContacts(user.fullName);
    }
  };

  const styles = props.styles !== undefined ? props.styles : personaStyles;
  if (props.silent === true) {
    styles.cursor = "default";
  }

  const onClick = props.onClick ? () => props.onClick() : onPersonaClick;
  

  if (props.iconOnly) {
    return (
      <Avatar
        size={props.size || 24}
        onClick={onClick}
        image={{ src: userImg }}
        badge={props.badge}
      />
    );
  }

  return (
    <div style={styles} onClick={onClick}>
      <Avatar
        name={props.iconOnly ? undefined : user?.fullName}
        image={{ src: userImg }}
        onClick={onClick}
        size={props.size || 24}
        badge={props.badge}
      />
      {/* Conditionally render the name and text container only if fullName, secondaryText, or tertiaryText exist */}
      {(user?.fullName || secondaryText || props.tertiarytext) && (
        <div
          style={{
            marginLeft: "10px",
            display: "flex",
            flexDirection: "column",
            flexGrow: 1, // Allow the text container to grow and take available space
            minWidth: 0, // Necessary for ellipsis to work properly in flex container
            justifyContent: "left", // Align items equally on the right
          }}
        >
          {/* Full name */}
          {user?.fullName && (
            <div
              style={{
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowed content
                textOverflow: "ellipsis", // Show ellipsis when text overflows
                justifyContent: "left", 
              }}
            >
              {user.fullName}
            </div>
          )}

          {/* Secondary text */}
          {secondaryText && (
            <div
              style={{
                fontSize: "12px",
                color: "gray",
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowed content
                textOverflow: "ellipsis", // Show ellipsis when text overflows
                justifyContent: "left", 
              }}
            >
              {secondaryText}
            </div>
          )}

          {/* Tertiary text */}
          {props.tertiarytext && (
            <div
              style={{
                fontSize: "10px",
                color: "lightgray",
                whiteSpace: "nowrap", // Prevent text from wrapping
                overflow: "hidden", // Hide overflowed content
                textOverflow: "ellipsis", // Show ellipsis when text overflows
                justifyContent: "left", 
              }}
            >
              {props.tertiarytext}
            </div>
          )}
        </div>
      )}
    </div>
  );
});
