import "./App.css";

import React, { useEffect, useContext, lazy } from "react";
import { observer } from "mobx-react-lite";

import CookieConsent from "react-cookie-consent";

import { MainContext } from "src/utils";
import { ThemeContext } from "./utils/themes";
import { ModalContainer } from "src/comps";

import { ThemeProvider } from "@fluentui/react";
import { FluentProvider } from "@fluentui/react-components";

import PageViewer from "./PageViewer";

const DisclaimerMod = lazy(() => import("src/comps/modals/Disclaimer"));
const PrivacyMod = lazy(() => import("src/comps/modals/Privacy"));

export default observer(() => {
  const Store = useContext(MainContext);
  const Theme = useContext(ThemeContext);

  useEffect(() => {
    // init new or restore the user session
    Store.initSession();
  }, []);

  const selTheme = {
    ...Theme.getFonts(),
    palette: Theme.getTheme(Store.getCurrentPlatform().id)
  };
  
  return (
    <FluentProvider
      style={{ height: "100%" }}
      theme={ Theme.getFluent9Theme(Store.getCurrentPlatform().id) }
    >
      <ThemeProvider
        applyTo="body"
        style={{ width: "100%", height: "100%" }}
        theme={ selTheme }
      >
        <ModalContainer
          modal={ DisclaimerMod }
          id="disclaimer"
        />

        <ModalContainer
          modal={ PrivacyMod }
          id="privacy"
        />

        <PageViewer />

        <CookieConsent
          buttonText="That's Fine"
          style={{ fontSize: "13px" }}
          buttonStyle={{ backgroundColor: "#ffffff" }}
        >
          We use cookies to track usage and preferences.&nbsp;
          <span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={ () => Store.openModal("privacy") }>
            More info
          </span>.
        </CookieConsent>
      </ThemeProvider>
    </FluentProvider>
  );
});
