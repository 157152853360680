import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Api, MainContext } from "src/utils";
import { Stack, DefaultButton, Text, Shimmer } from "@fluentui/react";

import { AvailableInPropsType, AvailableInNodeType } from "./AvailableIn.types";
import { AvailableInReqModal } from "./modals/reqs";
import { loadingStyles } from "./AvailableIn.styles";

// format PN for URL transmission (do not use "/")
const getPNforURL = (pn) => {
  return pn.replace(/\//g, "%2F");
};

const AvailableIn = observer((props:AvailableInPropsType) => {
  const Store = useContext(MainContext);
  const [data, setData] = useState([]);

  // get available in data
  useEffect(() => {
    if (props.lazy) {
      let lazyData = { 
        id: props.id,
        pn_x3: props.pn_x3
      };
      if (props.type == "supplier") {
        lazyData = {
          supplier_code: props.code || "",
          supplier_description: props.desc || ""
        };
      }
      else if (props.type == "manufacturing-books") {
        lazyData = {
          id: props.id,
          pn: props.pn[0]
        };
      }
      else if (props.type == "sn") {
        lazyData = {
          id: props.id,
          job: props.job[0],
          subjob: props.subjob[0]
        };
      }
      Api.get({
        api: props.type + "/get-available-in",
        data: lazyData
      }).then((response) => {
        response["pn"] = [props.pn_x3];
        setData(response);
      });
    }
    else {
      setData(props);
    }
  }, [props.id, props.code]);

  // display loading while retrieving available in
  if (!data) {
    return <Shimmer styles={ loadingStyles } />;
  }

  const buttons = [
    {
      name: (
        "In Stock " + (
          (props.type == "sn")
            ? ""
            : "(" +  parseInt(data.hasInStock) + ")"
        )
      ),
      disabled: !data.hasInStock,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate("/app/#/dcss/warehouse/pn=" + data.hasInStock);
        }
        else {
          Store.navigate(
            "/app/#/dcss/warehouse/pn=" + getPNforURL(data.pn[0] || data.Codice)
          );
        }
      }
    },
    {
      name: "Shipped Material",
      disabled: !data.hasShippedMaterial,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(
            "/app/#/dcss/warehouse/do=material-sent&pn=" + data.hasShippedMaterial
          );
        }
        else {
          Store.navigate(
            "/app/#/dcss/warehouse/do=material-sent&pn=" + getPNforURL(data.pn[0] || data.Codice)
          );
        }
      }
    },
    {
      name: (
        "List of PO " + (
          (props.type == "sn") ? "" : ("(" + data.hasPO + ")")
        )
      ),
      disabled: !data.hasPO,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(
            "/DCSS3/orders-x3.php?job=" + data.hasPO[0] + "&subjob=" +
            data.hasPO[1]
          );
        }
        else if (props.type == "supplier") {
          Store.navigate(
            "/DCSS3/orders-x3.php?supplier=" + data.description
          );
        }
        else {
          Store.navigate(
            "/DCSS3/orders-x3.php?pn=" + getPNforURL(data.pn[0] || data.Codice)
          );
        }
      }
    },
    {
      name: "List of OPD-OMD (" + data.hasOPDOMD + ")",
      disabled: !data.hasOPDOMD,
      onClick: () => Store.navigate(
        "/DCSS3/orders-production.php?pn=" + getPNforURL(data.pn[0] || data.Codice)
      ),
    },
    {
      name: "Safety stock (" +  parseInt(data.hasSafetyStock) + ")",
      disabled: !data.hasSafetyStock,
      onClick: () => {
        Store.navigate(
          "/app/#/dcss/warehouse/pn=" + getPNforURL(data.pn[0] || data.Codice)
        );
      }
    },
    {
      name: "OFI Closed",
      disabled: !data.hasOfiClosed,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/link_type=sn&link_id=" + data.id + "&status=Closed");
        }
        else if (props.type == "supplier") {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/supplier=" + data.code + "&status=Closed");
        }
        else {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/link_type=pn&link_id=" +  data.pn_id + "&status=Closed");
        }
      }
    },
    {
      name: "OFI Checked",
      disabled: !data.hasOfiChecked,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/link_type=sn&link_id=" + data.id + "&status=Checked");
        }
        else if (props.type == "supplier") {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/supplier=" + data.code + "&status=Checked");
        }
        else {
          Store.navigate(import.meta.env.VITE_BASE_URL + "dcss/ofi/link_type=pn&link_id=" +  data.pn_id + "&status=Checked");
        }
      }
    },
    {
      name: "Documents",
      disabled: !data.hasDocuments,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(
            import.meta.env.VITE_BASE_URL + "dcss/documents/link_type=sn&link_id=" + data.hasDocuments
          );
        }
        else if (props.type == "data-module") {
          Store.navigate(
            import.meta.env.VITE_BASE_URL + "dcss/documents/link_type=dm&link_id=" + data.id
          );
        }
        else {
          Store.navigate(
            import.meta.env.VITE_BASE_URL + "dcss/documents/link_type=pn&link_id=" + data.pn_id
          );
        }
      }
    },
    {
      name: "WBS",
      disabled: !data.hasWBS,
      onClick: () => {
        if (props.type == "pn" && props.onWbsClick) {
          props.onWbsClick();
        }
        else {
          Store.navigate(
            "/app/#/dcss/pn/do=wbs&code=" + getPNforURL(data.Codice) +
            "&rev=" + data.Revisione + "&var=" + data.Variante,
            true
          );
        }
      },
    },
    {
      name: "Data module",
      disabled: !data.hasDataModule,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(
            "/app/#/dcss/data-modules/link_type=sn&link_id=" + data.id
          );
        }
        else {
          Store.navigate(
            "/app/#/dcss/data-modules/code=" + data.hasDataModuleWBS
          );
        }
      }
    },
    {
      name: "Data module WBS",
      disabled: !data.hasDataModuleWBS,
      onClick: () => {
        if (props.type == "sn") {
          Store.navigate(
            "/app/#/dcss/data-modules/link_type=sn&link_id=" + data.id
          );
        }
        else {
          Store.navigate(
            "/app/#/dcss/data-modules/code=" + data.hasDataModuleWBS
          );
        }
      }
    },
    {
      name: "MDR",
      disabled: !data.hasMDR,
      onClick: () => {
        if (props.type == "pn") {
          Store.navigate(
            "/app/#/dcss/master-document-register/pn=" + getPNforURL(data.pn[0] || data.Codice)
          );
        }
        else {
          Store.navigate(
            "/app/#/dcss/master-document-register/sn=" + data.hasMDR
          );
        }
      }
    },
    {
      name: "Manufacturing book",
      disabled: data.hasPC || !data.hasMB,
      onClick: () => Store.navigate(
        "/app/#/dcss/manufacturing-books/view/sn=" + data.sn +
        "&date=" + data.hasMB
      ),
    },
    {
      name: "Physical configuration",
      disabled: !data.hasPC,
      onClick: () => Store.navigate(
        "/app/#/dcss/physical-configuration/sn=" + data.hasPC
      ),
    },
    {
      name: "Offer",
      disabled: !data.hasOffers,
      onClick: () => Store.navigate(
        "/app/#/dcss/documents/group=FG&subgroup=130603&pn=" +
        getPNforURL(data.Codice)
      ),
    }
  ];

  return (
    <Stack tokens={{ childrenGap: 10 }}>
      { buttons.map((item:AvailableInNodeType) => {
          if (item.disabled) {
            return null;
          }
          return (
            <DefaultButton
              text={ item.name }
              onClick={ item.onClick }
            />
          );
        })
      }
      { (
        !data.hasComplianceReq
        && !data.hasNoComplianceReq
        && !data.hasReq
      ) ? (
        buttons.filter(b => !b.disabled).length ? null : (
          <Text styles={{ root: { color: "white" }}}>No results</Text>
        )
      ) : (
        <DefaultButton
          text={ "Req" + (
            data.hasComplianceReq
              ? (" (" + data.hasComplianceReq + " compliant)") : ""
          ) + (
            data.hasNoComplianceReq
              ? (" (" + data.hasNoComplianceReq + " non-compliant)") : ""
          ) }
          onClick={ () => {
            props.onClickReq(props.id);
            Store.openModal("reqs");
          } }
        />
      )}
    </Stack>
  );
});

export {
  AvailableIn,
  AvailableInReqModal
};
