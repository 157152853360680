import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import { MainContext } from "src/utils";
import { Persona, TagPicker } from "src/comps";
import { IBasePickerStyles, ITag } from "@fluentui/react";

interface IValue extends Omit<ITag, "name"> {
  name?: string;
};

interface IProps {
  onChange: Function;
  defaultValue: IValue;
  limit?: number;
  styles?: IBasePickerStyles;
  disabled?: boolean;
  placeholder?: string;
};

const onRenderSuggestionsItem = (item) => {
  return (
    <Persona
      key={ item.key }
      user={ item.key }
      silent={ true }
    />
  );
};

const pickerSuggestionsProps = {
  suggestionsHeaderText: "",
  noResultsFoundText: "No results found",
};

export default observer((props:IProps) => {
  const Store = useContext(MainContext);

  const users = Store.getUsers().filter(u => {
    return u.active && !u.isExternal;
  });

  const onChange = (items) => props.onChange(
    (props.limit !== 1) ? items : items[0]
  );

  const onResolveSuggestions = (query) => {
    query = query.toLowerCase();
    return users.filter(u => {
      return (
        u.name.toLowerCase().startsWith(query)
        || u.surname.toLowerCase().startsWith(query)
      );
    }).map(u => {
      return { key: u.id, name: u.fullName };
    });
  };

  let defV = props.defaultValue;
  if (!Array.isArray(defV)) {
    defV = [defV];
  }
  const selUser:ITag = defV.filter(v => v !== undefined).map(v => ({
    key: v?.key,
    name: v?.name || Store.getUser(v?.key)?.fullName
  }));
  const selectedItems:Array<ITag> = selUser;

  const inputProps = {
    "aria-label": "Resource picker",
    "placeholder": props.placeholder || "Search by name/surname..."
  };

  return (
    <TagPicker
      removeButtonAriaLabel={ "Remove" }
      onRemoveSuggestion={ (item) => {} }
      onChange={ onChange }
      itemLimit={ props.limit || 1 }
      styles={ props.styles || {} }
      resolveDelay={ 300 }
      defaultSelectedItems={ selectedItems }
      disabled={ props.disabled || false }
      onResolveSuggestions={ onResolveSuggestions }
      onRenderSuggestionsItem={ onRenderSuggestionsItem }
      pickerSuggestionsProps={ pickerSuggestionsProps }
      inputProps={ inputProps }
    />
  );
});
