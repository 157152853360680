import { useState, useEffect, memo } from "react";

import { convert } from "html-to-text";

import {
  Combobox,
  makeStyles,
  shorthands,
  useId,
  Option,
  typographyStyles,
  Skeleton,
} from "@fluentui/react-components";

const useStyles = makeStyles({
  root: {
    // Stack the label above the field with a gap
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  description: {
    ...typographyStyles.caption1,
  },
});

export default memo((props: any) => {
  const comboId = useId("combo-multi");
  const styles = useStyles();

  const [options, setOptions] = useState([]);
  const [checks, setChecks] = useState(props.checks || []);
  const [selected, setSelected] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [matchingOptions, setMatchingOptions] = useState([]);

  const onChange = (event) => {
    const value = event.target.value.trim();

    const matches = options.filter((option) =>
      option.actualContent.toLowerCase().includes(value.toLowerCase())
    );

    setMatchingOptions(matches);

    if (value.length && matches.length < 1) {
      setQuery(value);
    } else {
      setQuery(undefined);
    }
  };

  // Handle selectedOptions both when an option is selected or deselected in the Combobox,
  // and when an option is removed by clicking on a tag
  const onSelect = (event, data) => {
    setChecks(data.selectedOptions);

    if (props.multi) {
      setSelectedOptions(data.selectedOptions);
    } else {
      props.onChange({
        value: data ? data.optionValue : data,
        label: (data ? data.optionText : data) || "",
        exactMatch: data !== undefined,
      });
    }
  };

  // on props value change the selected value should change accordingly
  // (usually used in double table comparison in which search queries are used
  // as shared between the two grids)
  useEffect(() => {
    let current = props.value
      ? props.value.label || props.value.value || props.value
      : "";
    if (props.value && !props.value.label) {
      current =
        props.options.filter((opt) => opt.value == props.value)[0]?.label ||
        current;
    }
    setSelected(current);
  }, [props.value]);

  useEffect(() => {
    if (props.reset !== undefined) {
      setChecks(props.checks || []);
      setSelected("");
    }
  }, [props.reset]);

  // Update options when props change and creates the base option list
  useEffect(() => {
    var retrivedOptions = props.options.map((option, i) => {
      return {
        key: props.multi ? option.value : i,
        actualValue: option.value,
        disabled: option.disabled !== undefined ? option.disabled : false,
        text: option.label?.toString(),
        actualContent: option.searchLabel || option.label?.toString(),
      };
    });

    setOptions(retrivedOptions);
  }, [props.options]);

  // label to be used with the counter in multi selection mode
  const counterLabel = props.counterLabel || "selected";
  const preCounterLabel = props.preCounterLabel || "";
  const disableCounterLabel = props.disableCounterLabel || false;

  // display the select box title, but if an option is selected,
  // display that instead. In the multi selectiom mode display the
  // counter of how many checks have been made referred to the
  // total of options
  const text = convert(
    (props.renderValue !== false
      ? props.multi
        ? disableCounterLabel
          ? ""
          : preCounterLabel + checks.length + " " + counterLabel
        : selected
      : ""
    ).toString()
  );

  const placeholder = convert(
    (
      props.placeholder ||
      (props.renderPlaceholder !== false ? "Select..." : "")
    ).toString()
  );
  // display a shimmer if a reset of the select is needed
  if (props.reset) {
    return (
      <div className={styles.root}>
        <Skeleton />
      </div>
    );
  }
  // options are strictly necessary
  if (
    (!props.options || !props.options.length) &&
    typeof props.options !== "function"
  ) {
    return null;
  }

  const checkboxStyle = {
    root: {
      width: "100%",
      borderColor: "#eee",
      ...props.buttonStyle,
      ...props.style,
    },
    input: {
      color: "var(--black-text-color) !important",
    },
  };

  return (
    <div className={styles.root}>
      <Combobox
        aria-labelledby={comboId}
        placeholder={placeholder}
        readOnly={props.readOnly}
        freeform={props.isSearchable}
        style={checkboxStyle.root}
        autoComplete={"off"}
        clearable={!props.multi && props.clearable}
        disabled={props.disabled}
        multiselect={props.multi}
        selectedOptions={props.multi ? checks : undefined}
        onChange={(ev) => {
          setQuery(ev.target.value);
          onChange(ev);
        }}
        onOptionSelect={onSelect}
        value={query}
        onOpenChange={(e, d) => {
          // callback on dismiss instead of onchange to allow for
          // multi options selection without reopening the menu each time
          if (!d.open) {
            // callback on dismiss instead of onchange to allow for
            // multi options selection without reopening the menu each time
            if (props.multi) {
              props.onChange(selectedOptions.length ? selectedOptions : checks);
            }
          }
        }}
      >
        {
          // Shows only the options that match the search
          matchingOptions.length > 0
            ? matchingOptions.map((option, i) => (
                <Option
                  key={option.key}
                  value={option.actualValue}
                  disabled={option.disabled}
                  text={option.text}
                >
                  {option.text}
                </Option>
              ))
            : // Shows all options if no search is made
              options.map((option, i) => {
                return (
                  <Option
                    key={option.key}
                    value={option.actualValue}
                    disabled={option.disabled}
                    text={option.text}
                  >
                    {option.text}
                  </Option>
                );
              })
        }
      </Combobox>
      {checks.length ? (
        <span className={styles.description}>{text}</span>
      ) : null}
    </div>
  );
});
