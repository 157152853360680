export default {
  UNKNOWN_ERROR_MSG: [
    101,
    "An unknown error occured",
  ],
  AUTHORIZATION_ERROR_MSG: [
    801,
    "You do NOT have the authorization to access the requested content"
  ],
  API_ERROR_MSG: [
    802,
    "An error occured with the request",
  ],
  PARAM_ERROR_MSG: [
    803,
    "An error occured with the request",
  ],
  NETWORK_ERROR_MSG: [
    804,
    "Your network seems failing<br />Please check your internet connection",
  ],
  ERP_ERROR_MSG: [
    805,
    "Can't contact the ERP",
  ],
  GENERAL_ERROR_MSG: [
    806,
    "This is an untraced error. Failed request",
  ],
  QUERY_ERROR_MSG: [
    807,
    "An error occured with the request",
  ],
  EMAIL_ERROR_MSG: [
    808,
    "An error occured with the request",
  ],
  EXECUTION_ERROR_MSG: [
    901,
    "A critical error occured while executing the requested action. In order " +
    "to prevent data corruptions, the execution of the app has been stopped"
  ],
  REJECTED_REQUEST_BY_SERVICE: [
    902,
    "The request has been rejected by the involved third party service"
  ],
  LIGHT_ERROR: [
    903,
    "Request rejected",
    true
  ]
};
