import { Suspense, useContext } from "react";
import { observer } from "mobx-react-lite";

import { MainContext } from "src/utils";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useStyles } from "./Custom.styles";

interface IProps {
  type?: string;
  title: string;
  width?: string|number;
  height?: string|number;
  onClose?: Function;
  store?: any;
  children: any;
  closeSingle?: boolean;
  styles?: any;
  useStack?: boolean;
  onDismiss?: Function;
  scrollable?: boolean;
};

const headerStackStyles = {
  root: {
    width: "100% !important",
    height: 50,
    cursor: "move"
  }
};

export const CustomDialog = observer((props:IProps) => {
  const Store = useContext(MainContext);
  const classes= useStyles();
  
  // get size to apply to modal
  let size = props.type || "default";
  size = size.charAt(0).toUpperCase() + size.slice(1);

  const onDismiss = () => {
    if (props.onClose) {
      props.onClose();
    }
    Store.closeModal(props.closeSingle ? true : false);
  };

  return (
    <Suspense>
      <Dialog
      open={ true }
      onOpenChange={ onDismiss }
    >
      <DialogSurface
        className={ classes["modalContainer" + size] }
        style={ props.styles }
      >
        <DialogBody className={ classes.modalBody }>
          <DialogTitle
            className={ classes.modalTitle }
            action={
              <DialogTrigger action="close">
                <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
              </DialogTrigger>
            }
          >
            { props.title }
          </DialogTitle>
          <DialogContent className={ classes.modalContent }>
            { (props.useStack === true) ? (
              <div className={ (props.scrollable === true) ? classes.scrollableStack : classes.stack }>
                { props.children }
              </div>
            ) : props.children }
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
    </Suspense>
  );
});
