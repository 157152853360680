import { searchRow } from "./search";

// get final filtered/ordered data by user query
export const filterData = (data, filters, specialCols, Store) => {
  let filteredRows = {};

  for (let col of specialCols) {
    const query = filters[col.key];
    if (query && query.value && (
      query.value.length || query.value.label)
    ) {
      filteredRows[col.key] = searchRow({
        colKey: col.key,
        matrix: data,
        query: (
          (query.value.values && Array.isArray(query.value.values))
            ? query.value.values : query.value
        ),
        strictMode: false, /* case insensitive */
        exactMatch: query.exactMatch,
        colInfo: col,
        Store
      });
    }
  }

  // join filtered rows array to get only satisfiable indexes
  // this is done by concatenating all the filtered arrays (only the ones with
  // currently related queries), and then finding the indexes with "available
  // queries number" occurrences
  let allowed_indexes = [], concat_indexes = [], queries_count = 0;
  Object.entries(filteredRows).forEach(([colKey, results]) => {
    const query = filters[colKey];
    if (query && query.value && (
      query.value.length || query.value.label
    )) {
      queries_count++;
      concat_indexes = concat_indexes.concat(results);
    }
  });

  // sort the concatenated array in order to find occurrences count for each
  // index. Then see if the occurrences are enough for the index to display
  concat_indexes.sort((a, b) => a - b);
  let curr_el = -1, curr_count = 0;
  for (let i = 0; i < concat_indexes.length + 1; i++) {
    curr_count++;
    if (concat_indexes[i] !== curr_el) {
      if (curr_count === queries_count && curr_el != -1) {
        allowed_indexes.push(curr_el);
      }
      curr_el = concat_indexes[i];
      curr_count = 0;
    }
  }

  // actually get the filtered data
  data = queries_count ? data.filter(
    (d, i) => allowed_indexes.includes(i)
  ) : data;

  return data.map(row => Object.values(row));
};

// get distinct values for select options of a single column filtering
export const getFilterSelectOptions = ({
  grid, originalData, additionalOptions, query, specialCols,
  Store, colKey
}) => {
  let options = [];

  // additional user defined options
  if (
    (!query || !query.value) && additionalOptions && additionalOptions.length
  ) {
    for (let j = 0; j < additionalOptions.length; j++) {
      options.push({
        value: "$$SAO$$" + j,
        label: additionalOptions[j].label || ""
      });
    }
  }

  // multi select should always display all the options while normal select
  // should behave with the table filtering
  // added support for object columns
  const data = specialCols.filter?.multi ? originalData : grid;

  // for each row get MULTI select options
  for (let row of originalData) {
    // range select only uses additional options
    if (specialCols.filter?.range !== true) {
      let value = row[colKey];
      if (value instanceof Array) {
        value = value[0];
      }
      if (specialCols && specialCols.type === "persona") {
        if (typeof value === 'object' && value !== null) {
          value = ((value || {}).fullName || "");
        }
        else if (value == +value) {
          value = (Store.getUser(value) || {}).fullName || "";
        }
      }
      if (value !== undefined && value !== null) {
        value = value.toString();
        if (value.length) {
          options.push(value || "");
        }
      }
    }
  }

  // remove duplicates
  // this is actually faster than checking on insert with .includes()
  options = [... new Set(options)];

  // generate general purpose options
  if (!options.length) {
    options = [{ value: "", label: "" }];
  }
  else {
    if (specialCols.type === "check") {
      if (
        options.length === 1
        && (options[0] == "0" || options[0] == "1")
      ) {
        options = [
          { value: options[0], label: +options[0] ? "Yes" : "No" },
        ];
      }
      else if (
        (options[0] == "0" || options[1] == "0") &&
        (options[0] == "1" || options[1] == "1")
      ) {
        options = [
          { value: "0", label: "No" },
          { value: "1", label: "Yes" }
        ];
      }
      else {
        options = options.map(opt => ({ value: opt, label: opt }));
      }
    }
    else {
      options = options.map(opt => {
        return opt.value ? opt : { value: opt, label: opt }
      });
    }
  }

  // support OU filtering (maybe moved out of Grid in the future)
  // TODO: MOVE OUT!!!!! special cols arrays should first become objects?!
  if (specialCols.filter.mode === "ou") {
    options.sort((a, b) => {
      a = a.label.toString();
      b = b.label.toString();
      const a_letters = a.substring(0, 2);
      const b_letters = b.substring(0, 2);
      if (a_letters != b_letters) {
        if (a_letters > b_letters) return -1;
        return 1;
      }
      else {
        return +a.substring(2, a.length) - +b.substring(2, b.length);
      }
    });
  }
  else {
    options.sort((a, b) =>  {
      a = a.label.toString();
      b = b.label.toString();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
  }

  return options;
};
