import { createContext } from "react";
import { makeAutoObservable } from "mobx";

import { Api, showError, startLoading, stopLoading, notify } from "../api";
import * as Session from "../Session";
import * as Notifications from "../Notifications";

import store from "./store";
import platforms from "../../platforms.json";

import DATA_INIT from "./dataInit";

class Dispatcher {
  data
  status
  confirm

  constructor() {
    this.data = { ...DATA_INIT };
    this.status = store;
    this.confirm = {};

    // get dark mode saved setting
    if (localStorage.getItem("darkMode") === null) {
      this.status.darkMode = (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? true : false;
    }
    else {
      this.status.darkMode = (localStorage.getItem("darkMode") == "true") ? true : false;
    }
    makeAutoObservable(this);
    this.updatePlatform();
  }

  // general utility ___________________________________________________________

  getPlatforms() {
    return this.data.privileges.filter(p => p.type === "C");
  }

  getPlatform(id) {
    return platforms[id];
  }
  
  updatePlatform(platform = undefined) {
    let path = window.location.href.split("/#/")[1].split("/");
    this.status.selPlatform = platforms[platform || path[0] || "dwa"];

    // set page title as section name
    document.title = this.status.selPlatform.pageTitle;
  }

  // this is used as an handling for navigation to both internal and external
  // app links. Even if this code does not involve store manipulation right now,
  // it might in the future and therefore should be left here
  navigate(url:string = "/Dashboard/", samePage:boolean|number = 0) {
    if (samePage) {
      window.open(url, "_self");
    }
    else {
      window.open(url, "_blank");
    }
    return url;
  }

  toggleNavSize() {
    return this.status.navIsReduced = !this.status.navIsReduced;
  }

  toggleMobileNav() {
    return this.status.displayMobileNav = !this.status?.displayMobileNav;
  }

  isMobileNavHidden() { return (this.status?.displayMobileNav == false) }
  isNavReduced() { return this.status.navIsReduced }

  logAndNavigate(url) {
    const navigate = this.navigate;
    Api.get({
      api: "tracker/log-redirect",
      data: { url },
    }).then(() => navigate(url));
  }

  copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(() => {
      notify("Copied to clipboard")
    })
  }

  // wrapper to execute whatever function in between loading toggles
  withLoading(fn, onStop = () => {}) {
    startLoading();
    setTimeout(() => {
      fn();
      setTimeout(() => {
        onStop();
        stopLoading();
      });
    }, 100);
  }

  // privileges ________________________________________________________________
  private getPrivilege(id) {
    return this.data.privileges.filter(p => p.id == id)[0] || {};
  }

  hasPrivilege(id) {
    return (this.data.privileges.filter(p => p.id == id)[0] !== undefined) ? 1 : 0;
  }

  getPrivilegesStructure(platformId) {
    return [
      // make dashboard the first button (TO BE REMOVED!!!)
      this.data.privileges.filter(p => {
        return p.platform == platformId && (p.title == "Dashboard" || p.title == "Projects View") && p.type == "S"
      })[0],
      ...this.data.privileges.filter(p => p.platform == platformId && p.title != "Dashboard" && p.title != "Projects View")
    ];
  }

  getTools() {
    return this.getPrivilegesStructure("dwa");
  }

  // tools displaying __________________________________________________________
  async initApp(refPath = window.location.href.split("/#/")[1]) {
    const path = refPath.split("/");

    // on mobile startup the DWA showing the full navigation menu
    // but only on the DWA Dashboard
    if (path[0] == "dwa" && path[2] == "dashboard") {
      this.toggleMobileNav();
    }
    
    // remove params from URL
    if (path[path.length - 1].indexOf("=") > 0) {
      path.pop();
    }

    // get privilege from URL
    const platform = path.shift();
    let privId = 0;
    if (platform !== "dwa") {
      const relativePath = path.join("/");
      privId = +Object.entries(platforms[platform].pages)
        .filter(([, value]) => value[0] === relativePath)[0][0];
    }

    // sel section
    this.updatePlatform(platform);

    this.status.selSectionID = privId;
    this.status.selSection = this.getPrivilege(privId).title;
    this.status.displayMobileNav = false;
    stopLoading();

    this.loadVideoHelp(privId);

    // get page attributes
    const attributes = await Api.get({
      api: "attributes/get-types",
      data: { page_id: privId }
    });
    this.status.attributes[privId] = attributes;

    // set app style
    document.documentElement.style.setProperty(
      "--theme-color", 
      this.status.selPlatform.themes.light.themePrimary
    );

    // check DCSS privileges for DCSS pages
    if (platform !== "dwa" && !this.data.privileges.filter(p => p.id == privId)[0]) {
      showError({
        msg: "AUTHORIZATION_ERROR_MSG",
        desc: "Missing privileges for this page"
      });
    }
  }

  // on a tool block click decide wheter it is a DWA Dev Package App or not and
  // therefor act accordingly (default is dashboard)
  onToolClick(tool) {
    return this.navigate(
      tool.path,
      (tool.path.indexOf(import.meta.env.VITE_BASE_URL) == 0) ? 1 : 0
    );
  }

  // Even if this code does not involve store manipulation right now,
  // it might in the future and therefore should be left here
  // Maybe it should get the ID instead of the name as a parameter
  getToolInitials(name) {
    let arr = name.replace(/[^a-zA-Z\s]/g, "").split(" ");
    return (arr[0][0] + (arr[1] ? arr[1][0] : arr[0][1])).toUpperCase();
  }

  getCurrentSection() { return this.status.selSection }
  setSection(path) { this.status.selSection = path }
  getCurrentSectionID() { return this.status.selSectionID }

  // function to get the complete path currently being navigated inside the app
  getCurrentPath() {
    return window.location.href.split("/#/")[1];
  }

  // get current platform info. If no platform parameter has beeen register
  // (probably caused by an invalid, expired or never setted session) try to
  // guess the platform from the request url
  getCurrentPlatform() {
    if (this.status.selPlatform.pageTitle) {
      return this.status.selPlatform;
    }
    const platform = window.location.href.split("/#/")[1].split("/")[0];
    // set app style
    document.documentElement.style.setProperty(
      "--theme-color", platforms[platform].theme_color
    );
    return platforms[platform];
  }
  getCurrentApp() { return this.status.selApp || {} }

  getVideoHelp() {
    return this.status.videoHelp || [];
  }
  async loadVideoHelp(priv_id) {
    // get video help
    const videos = await Api.get({
      api: "video-help/get",
      data: { page_id: priv_id }
    });
    this.status.videoHelp = videos;
  }

  // session ___________________________________________________________________

  async getBasics (force = false) {
    // current app and session status
    const data = await Session.checkLogin({ store: this, force });
    
    // in desktop environment a login form should be provided
    // or if there is any stored login credentials those should be used
    this.status.isLoggedIn = data ? true : false;
    stopLoading();

    if (!data || !data.users) return;

    // generate associative users array
    // this should replace the main users array in the future (duplicate)
    const usersAssoc = [];
    data.users.forEach((user, index) => usersAssoc[user.id] = user);
    data.usersAssoc = usersAssoc;
    
    this.status.isDataRetrieved = true;
    this.data = data;
    this.initApp();
    Notifications.subscribe();
    
    return data;
  };

  // move and split between Session.js and App.js, no need to be here (?)
  async initSession (force = false) {
    // in the case of data session passed by parameter
    // TODO: this is used for testing but could in the near future become a way to
    // store the session token in LocalStorage
    /*if (session && session.users && session.users.length) {
      this.status.isLoggedIn = true;
      this.stopLoading();
      return this.data = session;
    }*/

    // if already logged in do nothing (prevents function refire)
    if (this.status.isLoggedIn == true) {
      return;
    }
    
    // if data has not been already retrieved
    if (force || !this.data.users || !this.data.users.length) {
      await this.getBasics();
    }
    return true;
  }

  isDataRetrieved() {
    return this.status.isDataRetrieved;
  }

  // login through exsistent DWA login mechanism. To be soon replaced by an
  // internal APIs and JWT based login system
  async login ({ username = "", password = "", pin = "" }) {
    if (!username || !password) {
      return { msg: "Invalid credentials. Please, try again.", code: 3 };
    }
    else {
      const result = await Api.get({
        api: "session/init-session",
        data: { username, password, pin }
      });
      let msg = "";
      switch (result) {
        case 1:
          Session.login({ store: this }).then(() => this.initSession(true));
          break;
        case 2:
          msg = "The requested username does not exist";
          break;
        case 3:
          msg = "Username not active";
          break;
        case 4:
          msg = "Password expired. Please enter a new one.";
          break;
        case 5:
          msg = "Too many attempts. Wait a few minutes and try again.";
          break;
        case 6:
          msg = "Too many attempts on the requested username. Wait a few minutes and try again.";
          break;
        default:
          msg = "Invalid credentials. Please, try again.";
          break;
      }
      return { msg, code: result };
    }
  };

  logicLogout () {
    this.data = { ...DATA_INIT };
    this.status.isLoggedIn = false;
  }

  // move and split between Session.js and App.js, no need to be here (?)
  logout() {
    Api.get({
      api: "session/logout"
    }).then(() => {
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    });
  }

  isLoggedIn() {
    return this.status.isLoggedIn;
  }

  // modals ____________________________________________________________________
  openModal(id, props = {}) {
    this.status.selModalProps = {
      ...this.status.selModalProps,
      [id]: props
    };
    this.status.selModal.push(id);
  }

  closeModal(closeSingle = false) {
    if (!closeSingle) {
      this.status.selModal = [];
      this.status.selModalProps = {};
    }
    else {
      this.status.selModal.splice(-1, 1);
    }
  }

  getModalProps(id) {
    return this.status.selModalProps[id] || {};
  }

  isModalOpen(id) {
    return this.status.selModal.includes(id) ? true : false;
  }

  // hierarchy getters _________________________________________________________
  getUser(id:string|number = 0) {
    if (!id) {
      return this.data.user;
    }
    return this.data.usersAssoc[id];
  }

  setUserBackground(bg) {
    this.data.user.background = bg;
  }

  getUserFromName(name) {
    if (!name || name == "-") return {};
    name = name.toString();
    name = name.replace(/\./g, " ");
    let user_name = name.toLowerCase().replace(/ /g, "").replace(/\'/g, "");
    let user = this.data.users.filter(u => {
      return u.active == 1 && u.fullName.toLowerCase().replace(/ /g, "").replace(/\'/g, "")
        == user_name
    })[0] || {};

    // search users (separated function because it is first used with active
    // users than with inactive)
    const userFound = (u, words) => {
      const iterated_user_name_words = u.fullName.toLowerCase().split(" ");
      let found = true;

      for (let word of words) {
        if (!iterated_user_name_words.includes(word)) {
          found = false;
        }
      }
      if (found) {
        return true;
      }
      return false;
    };

    // in case of no user found try the scrubble way. Meaning verify if the user
    // can be found changing the words order in the name
    // (this works in the case of a given surname-name input name)
    if (!user.fullName) {
      const words = name.toLowerCase().split(" ");
      if (words.length) {
        user = this.data.users.filter(u => u.active == 1 && userFound(u, words))[0];

        // try inactive users if nothing have been found
        if (!user?.fullName) {
          user = this.data.users.filter(u => u.active == 0 && userFound(u, words))[0];
        }
      }
    }
    if (!user?.fullName) {
      user = {};
    }
    return user;
  }

  // get ou name to be displayed under the user name
  getOUname(id, max_length = 0) {
    let ou = this.getOU(id).name || "";
    if (max_length && ou.length > max_length) {
      return ou.substring(0, max_length) + "...";
    }
    return ou;
  }

  getUsers() { return this.data.users; }

  async updateUsers() { 
    const result = await Api.get({ 
      api: "resources/get-list", 
      silent: true
    });
    return this.data.users = result;
  }

  getGlobals(id = 0) {
    if (!id) return this.data.globals;
    return this.data.globals.filter(g => g.id == id)[0] || {};
  }

  getCompanies(id = 0) {
    if (!id) return this.data.companies;
    return this.data.companies.filter(c => c.id == id)[0] || {};
  }

  getOU(id = 0) {
    if (!id) return this.data.ou;
    return this.data.ou.filter(o => o.id == id)[0] || {};
  }

  getCompanyOU(company_id = 0) {
    if (!company_id) return this.data.ou;
    return this.data.ou.filter(o => o.company_id == company_id) || [];
  }

  getRoles() { return this.data.roles; }
  getModal() { return this.status.selModal; }

  // action/confirmation dialogs _______________________________________________

  getConfirm() {
    return this.confirm;
  }
  setConfirm(confirm) {
    confirm.clearConfirm = () => {
      this.clearConfirm();
    }
    this.confirm = confirm;
  }
  clearConfirm() {
    this.confirm = {};
  }

  // contacts ________________________________________________________________
  openContacts(userName = true) {
    this.status.showContacts = userName;
  }
  closeContacts() {
    this.status.showContacts = false;
  }
  isOpenContacts() {
    return this.status.showContacts;
  }

  // user settings _____________________________________________________________

  // get user defined columns preferences for a specified Grid table
  // identified by its DB table_id field. If no preferences have been saved yet
  // return undefined, that way Grid will render all the columns
  getCols(table_id) {
    const data = (this.data.column_settings || [])[table_id];
    if (!data) return {};
    return {
      columns: data?.columns ? (data?.columns || "").split(",") : undefined,
      pinned_left: data?.pinned_left ? (data?.pinned_left || "").split(",") : undefined,
      pinned_right: data?.pinned_right ? (data?.pinned_right || "").split(",") : undefined
    }
  }

  private async reloadColsPreferences(tableId) {
    const column_settings = await Api.get({
      api: "resources/get-column-preferences",
      data: {
        tableId
      }
    })
    this.data.column_settings[tableId] = column_settings[0];
  }

  // save user defined Grid columns preferences in DB (as a string of all
  // the columns ID divided by commas)
  async setColsPin({ tableId, columnKey, pin, callback }) {
    await Api.get({
      api: "resources/set-column-pin",
      data: {
        tableId,
        columnKey,
        pin
      }
    });
    await this.reloadColsPreferences(tableId);
    callback();
  }

  // save user defined Grid columns preferences in DB (as a string of all
  // the columns ID divided by commas)
  async setCols(table_id, columns, onSuccess) {
    columns = columns.join(",");
    await Api.get({
      api: "resources/set-column-preferences",
      data: {
        table_id,
        columns
      }
    });
    await this.reloadColsPreferences(table_id);
    onSuccess(columns);
  }
  
  // page attributes ___________________________________________________________
  getAttributes (page_id, filter = undefined) {
    let result = this.status.attributes[page_id] || [];
    return filter ? result.filter(filter) : result;
  }
};

const observableStore = new Dispatcher();
export const MainStore = observableStore;

export const MainContext = createContext<Dispatcher>(null);