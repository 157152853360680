import React, { lazy } from "react";

export default {
	DWA_tools_Tools: lazy(() => import("./src/pages/dwa/tools")),
	DWA_organizationalchart_OrganizationalChart: lazy(() => import("./src/pages/dwa/organizational-chart")),
	DWA_main_tools_Tools: lazy(() => import("./src/pages/dwa/main/tools")),
	DWA_main_assignments_Assignments: lazy(() => import("./src/pages/dwa/main/assignments")),
	DWA_main_groups_Groups: lazy(() => import("./src/pages/dwa/main/groups")),
	DWA_main_storage_Storage: lazy(() => import("./src/pages/dwa/main/storage")),
	DWA_smartworkingbadge_main_Main: lazy(() => import("./src/pages/dwa/smart-working-badge/main")),
	DWA_main_sio_Sio: lazy(() => import("./src/pages/dwa/main/sio")),
	DCSS_management_ims_Ims: lazy(() => import("./src/pages/dcss/management/ims")),
	DCSS_dashboard_Dashboard: lazy(() => import("./src/pages/dcss/dashboard")),
	DCSS_datamodules_DataModules: lazy(() => import("./src/pages/dcss/data-modules")),
	DCSS_documents_Documents: lazy(() => import("./src/pages/dcss/documents")),
	DCSS_masterdocumentregister_MasterDocumentRegister: lazy(() => import("./src/pages/dcss/master-document-register")),
	DCSS_ofi_Ofi: lazy(() => import("./src/pages/dcss/ofi")),
	DCSS_pn_Pn: lazy(() => import("./src/pages/dcss/pn")),
	DCSS_cpn_Cpn: lazy(() => import("./src/pages/dcss/cpn")),
	DCSS_suppliers_Suppliers: lazy(() => import("./src/pages/dcss/suppliers")),
	DCSS_sn_Sn: lazy(() => import("./src/pages/dcss/sn")),
	DCSS_warehouse_Warehouse: lazy(() => import("./src/pages/dcss/warehouse")),
	DCSS_manufacturingbooks_view_View: lazy(() => import("./src/pages/dcss/manufacturing-books/view")),
	DCSS_physicalconfiguration_PhysicalConfiguration: lazy(() => import("./src/pages/dcss/physical-configuration")),
	DCSS_compliancematrix_ComplianceMatrix: lazy(() => import("./src/pages/dcss/compliance-matrix")),
	DCSS_manufacturingbooks_attributes_Placements: lazy(() => import("./src/pages/dcss/manufacturing-books/attributes/placements")),
	DCSS_manufacturingbooks_design_Design: lazy(() => import("./src/pages/dcss/manufacturing-books/design")),
	DCSS_manufacturingbooks_production_Production: lazy(() => import("./src/pages/dcss/manufacturing-books/production")),
	DCSS_manufacturingbooks_quality_Quality: lazy(() => import("./src/pages/dcss/manufacturing-books/quality")),
	DCSS_manufacturingbooks_comparison_Comparison: lazy(() => import("./src/pages/dcss/manufacturing-books/comparison")),
	DCSS_hiddenservices_ecosbuildchartexport_EcosBuildChartExport: lazy(() => import("./src/pages/dcss/hidden-services/ecos-build-chart-export")),
	DCSS_manufacturingbooks_attributes_Materials: lazy(() => import("./src/pages/dcss/manufacturing-books/attributes/materials")),
	DCSS_management_jobs_Jobs: lazy(() => import("./src/pages/dcss/management/jobs")),
	DCSS_manufacturingbooks_attributes_Status: lazy(() => import("./src/pages/dcss/manufacturing-books/attributes/status")),
	DCSS_manufacturingbooks_purchase_Purchase: lazy(() => import("./src/pages/dcss/manufacturing-books/purchase")),
	DCSS_manufacturingbooks_logistic_Logistic: lazy(() => import("./src/pages/dcss/manufacturing-books/logistic")),
	DCSS_orders_Orders: lazy(() => import("./src/pages/dcss/orders")),
	DCSS_management_requestofpurchase_RequestOfPurchase: lazy(() => import("./src/pages/dcss/management/request-of-purchase")),
	DCSS_management_requestofshipment_RequestOfShipment: lazy(() => import("./src/pages/dcss/management/request-of-shipment")),
	DCSS_productionorders_ProductionOrders: lazy(() => import("./src/pages/dcss/production-orders")),
	GPROJECT_projectsview_ProjectsView: lazy(() => import("./src/pages/gproject/projects-view")),
	GPROJECT_resourcesview_ResourcesView: lazy(() => import("./src/pages/gproject/resources-view")),
	GPROJECT_calendars_Calendars: lazy(() => import("./src/pages/gproject/calendars")),
	GPROJECT_teams_Teams: lazy(() => import("./src/pages/gproject/teams")),
	GPROJECT_gantt_Gantt: lazy(() => import("./src/pages/gproject/gantt")),
	GPROJECT_pert_Pert: lazy(() => import("./src/pages/gproject/pert")),
	GPROJECT_smartjustification_SmartJustification: lazy(() => import("./src/pages/gproject/smart-justification")),
	GPROJECT_links_Links: lazy(() => import("./src/pages/gproject/links")),
	ADMINER_users_Users: lazy(() => import("./src/pages/adminer/users")),
	ADMINER_companies_Companies: lazy(() => import("./src/pages/adminer/companies")),
	ADMINER_classification_Classification: lazy(() => import("./src/pages/adminer/classification")),
	ADMINER_logs_Logs: lazy(() => import("./src/pages/adminer/logs")),
	ADMINER_viewablelogs_ViewableLogs: lazy(() => import("./src/pages/adminer/viewable-logs")),
	ADMINER_groups_Groups: lazy(() => import("./src/pages/adminer/groups")),
	ADMINER_news_News: lazy(() => import("./src/pages/adminer/news")),
	ADMINER_changelog_Changelog: lazy(() => import("./src/pages/adminer/changelog")),
}