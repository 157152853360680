import { CounterBadge } from "@fluentui/react-components";

interface ICounterCellProps {
    cellData: number | Array<number>;
  }
  
  export default function CounterCell({ cellData }: ICounterCellProps) {
    // Check if cellData is an array and extract the count, otherwise use cellData directly
    const count = Array.isArray(cellData) ? cellData[0] : cellData;
  
    return (
        <>
      <CounterBadge count={count} appearance="filled" />
        </> 
    );
  }
  