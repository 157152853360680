import React, { memo } from "react";
import { SearchBox, ISearchBoxProps } from "@fluentui/react";

export default memo((props: ISearchBoxProps) => {
  const fieldProps: ISearchBoxProps = {
    ...props,
    styles: {
      root: { borderColor: "#ddd", ...(props.styles || {}) },
    },
  };
  return <SearchBox {...fieldProps} />;
});
