export const pickerStyles = {
  text: {
    borderColor: "#dddddd",
    minWidth: 0
  }
};
  
export const objectStyles = {
  "danger": {
    color: "var(--danger-color)",
  },
  "warning": {
    color: "var(--warning-color)",
  },
  "warning line-through": {
    color: "var(--warning-color)",
    textDecoration: "line-through",
  },
  "link-blue": {
    color: "var(--link-blue-color)",
  },
  "link-black": {
    color: "var(--black-text-color)",
  },
  "success": {
    color: "var(--success-color)",
  }
};