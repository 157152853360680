import { makeStyles, shorthands } from "@fluentui/react-components";
import { isMobile } from "react-device-detect";

export const useStyles = makeStyles({
  commands: {
    width: "100%",
    flexWrap: "nowrap",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    marginLeft: "8px",
    marginTop: "15px",
    marginBottom: "15px",
    justifyContent: isMobile ? undefined : "space-between",
  },
  mainCommads: {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    ...shorthands.gap("10px")
  },
  sideCommands: {
    width: "auto",
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
    order: isMobile ? 0 : 1,
    marginRight: "15px",
    ...shorthands.gap("10px")
  },
  mobileButton: {
    width: "120px",
    marginTop: "10px",
    marginLeft: "20px",
    marginBottom: "15px",
    marginRight: "10px"
  }
});