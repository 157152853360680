import { memo, useContext, useState, useEffect, lazy } from "react";

import {
  Tooltip,
  Text,
  Button,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuItemRadio,
  Checkbox,
} from "@fluentui/react-components";
import {
  ArrowStepInLeft24Filled,
  ArrowStepInRight24Filled,
  PinOff24Regular,
  ReOrderDotsVertical24Regular,
  Filter24Regular,
  Delete24Regular,
} from "@fluentui/react-icons";
import { MainContext } from "src/utils";

import { getLinkModeFromKey, linkTypes } from "src/comps/links/Links";
import { getNameFromKey } from "src/comps/form/inputPicker/nameFromKey";
import { api } from "src/comps/form/inputPicker/api";

import { IProps } from "./HeaderCell.types";
import { useStyles } from "./HeaderCell.styles";
import { getDataObject } from "./getDataObject";
import { getFilterSelectOptions } from "./filtering";
import { htmlToText } from "./htmlToText";

const SelectionCell = lazy(() => import("./cell-types/SelectionCell"));

export default memo((options: IProps) => {
  const Store = useContext(MainContext);

  const [currentKeyword, setCurrentKeyword] = useState("");
  const [showContextMenu, setShowContextMenu] = useState(false);

  const classes = useStyles();

  const { index, onPin, column } = options;

  const isPinned = column.pinned !== undefined;

  let query = options.filters[column.key];

  // get column info defined in the cols prop passed to the Matrix class
  let filter = column.filter;
  if (filter && {}.toString.call(column.filter) === "[object Function]") {
    filter = column.filter({ filters: options.filters });
  }

  useEffect(() => {
    // get name instead of key also for displayed keyword on column
    if (filter?.type === "picker") {
      const shouldGetNameFromKey = filter.getNameFromKey;

      if (shouldGetNameFromKey) {
        const mode = filter.mode || "pn";
        let selectedPick = {};
        let value = "";
        if ((value = query?.passAs)) {
          // support for multi picks
          if (Array.isArray(value)) {
            selectedPick = value.map((v) => ({
              key: v,
            }));
          } else {
            selectedPick = {
              key: value,
            };
          }
        }
        getNameFromKey(
          selectedPick, //query
          api(mode), // endpoint
          filter.requestParams // request params (optional)
        ).then((value) => {
          if (Array.isArray(value)) {
            setCurrentKeyword(value.map((v) => v.name).join(", "));
            return;
          }
          setCurrentKeyword(value.name);
        });
        return;
      }
    }
    // for links get name from id (pn use freeform search)
    if (filter?.type === "links") {
      if (query?.value && query.exactMatch) {
        const [key, id] = query.value;
        const mode = getLinkModeFromKey(key);
        getNameFromKey(
          { key: id }, //query
          api(mode) // endpoint
        ).then((value) => {
          if (Array.isArray(value)) {
            setCurrentKeyword(value.map((v) => v.name).join(", "));
            return;
          }
          setCurrentKeyword(mode.toUpperCase() + " " + value.name);
        });
      }
    }
    if (
      filter?.type === "date" &&
      filter.range === true &&
      query?.value &&
      query?.to
    ) {
      setCurrentKeyword(`${query?.value} - ${query?.to}`);
      return;
    }
    setCurrentKeyword(query?.value);
  }, [query]);

  if (options.column.key === "__selection__") {
    return <SelectionCell column={options.column} />;
  }

  const hasFilter = currentKeyword && filter?.type ? true : false;

  // not filterable
  const isReadOnly = filter?.type === undefined;

  const handleQueryInput = ({
    colIndex,
    value,
    exactMatch = false,
    opt = () => {},
    passAs = undefined,
    params = undefined,
  }) => {
    if (query?.value === value && params === undefined) return;

    // update state filters
    let newFilters = {
      ...options.filters,
      [column.key]: {
        value,
        passAs,
        exactMatch,
        ...params,
      },
    };

    // custom filters process function
    if (filter.onConfirm) {
      newFilters = filter.onConfirm({ filters: newFilters });
    }
    options.setFilters(newFilters);

    // in the case of a props custom function to handle the results
    // (use mainly to scroll sync comparison tables)
    if (options.onSearchQuery) {
      options.onSearchQuery({
        colKey: column.key,
        value: passAs || value,
        exactMatch,
        getOptions: opt,
        filters: newFilters,
      });
    }
  };

  const getOptions = () => {
    if (column.type === "check") {
      return [
        { value: "1", label: "Yes" },
        { value: "0", label: "No" },
      ];
    }

    if (options.lazy) {
      // for checks use a simply Yes/No filter
      // if no custom filter is defined
      if (!(options.lazy.filtersOptions || {})[column.key]) {
        return filter.options;
      }
      return (options.lazy.filtersOptions || {})[column.key];
    }

    if (filter.options) {
      // remove duplicates
      const values = [...new Set(filter.options.map((o) => o.value))];
      return values.map((v) => filter.options.filter((o) => o.value === v)[0]);
    }

    let selectOptions = getFilterSelectOptions({
      colKey: column.key,
      grid: options.dataObject,
      originalData: getDataObject({
        rows: options.data,
        cols: options.cols,
      }),
      additionalOptions: (options.selectAdditionalOptions || [])[column.key],
      query,
      specialCols: column,
      Store,
    });

    // return null if select options are still being calculated
    if (!selectOptions) {
      return null;
    }

    // not empty option in select if needed
    if (filter.enableNotEmptyOption === true) {
      selectOptions.unshift({
        value: [
          ...new Set(Object.values(selectOptions.map((obj) => obj.value))),
        ],
        label: "-- Not Empty --",
      });
    }

    return selectOptions;
  };

  // multi language fields selection
  let languageOptions = null;
  if (column.locales !== undefined) {
    const items = options.multiLangOptions.map((lang, key) => (
      <MenuItemRadio
        name="language"
        value={lang}
        onClick={() => options.setSelMultiLang(key)}
      >
        {lang}
      </MenuItemRadio>
    ));

    languageOptions = (
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <MenuItem disabled={(query || {}).value}>Language</MenuItem>
        </MenuTrigger>
        <MenuPopover>
          <MenuList
            checkedValues={options.multiLangOptions[options.selMultiLang]}
          >
            {...items}
          </MenuList>
        </MenuPopover>
      </Menu>
    );
  }

  // support custom html content
  if (column.type === "html") {
    return (
      <div className={"filterable"}>
        <span dangerouslySetInnerHTML={{ __html: column.title }} />
      </div>
    );
  }

  let keyWordFilter = {};
  let additionalFilters = [];
  let beforeFilters = [];
  let handleSend = (values) => {
    handleQueryInput({ colIndex: index, value: values.keyword });
  };

  switch (filter?.type) {
    case "select":
    case "range": {
      // support Yes/No selects
      if (column.type === "check" && query) {
        query.label = query.value == "0" ? "No" : "Yes";
      }

      const selectOptions = getOptions();

      handleSend = (values) => {
        let value = "";
        // support for multi selction if needed
        if (filter.multi === true) {
          value = values.keyword;
        } else {
          value = values.keyword ? values.keyword.value : "";

          // support for user defined options
          if (
            value &&
            !value.values &&
            value.toString().indexOf("$$SAO$$") === 0
          ) {
            value =
              options.selectAdditionalOptions[column.key][
                +value.replace("$$SAO$$", "")
              ];
          }
        }
        handleQueryInput({
          colIndex: index,
          value,
          exactMatch:
            filter.exactMatch === false || filter.multi === true ? false : true,
          opt: getOptions,
        });
      };

      const selectValue = (query || {}).value
        ? query && query.value && query.value.label
          ? query.value.label
          : query.value !== undefined
          ? selectOptions.filter((o) => o.value == query.value)[0]?.label ||
            query
          : query
        : undefined;

      keyWordFilter = {
        type: "select",
        options: selectOptions,
        allowFreeform: true,
        checks: filter.multi === true ? (query || {}).value : undefined,
        multi: filter.multi === true,
        disableCounterLabel: !((query || {}).value || []).length,
        preCounterLabel: column.title + " (",
        counterLabel: "sel.)",
        value: selectValue,
        onChange: filter.onChange
          ? (values) =>
              filter.onChange({
                values,
                options: selectOptions,
              })
          : undefined,
      };
      break;
    }
    case "picker": {
      query =
        query && query.value && query.value.label ? query.value.label : query;

      let selectedPick = {};
      let value = "";
      if ((value = (query || {}).passAs)) {
        // support for multi picks
        if (Array.isArray(value)) {
          selectedPick = value.map((v) => ({
            key: v,
          }));
        } else {
          selectedPick = {
            key: query.passAs,
            name: query.passAs,
          };
        }
      }

      const mode = filter.mode || "pn";
      const shouldGetNameFromKey = filter.getNameFromKey;

      const itemLimit = filter.itemLimit;

      keyWordFilter = {
        type: "input-picker",
        mode,
        allowFreeform: filter.allowFreeform !== false,
        getNameFromKey: shouldGetNameFromKey,
        minChar: filter.minChar || undefined,
        itemLimit,
        requestParams: (values) => ({
          show_closed: values.show_closed ? 1 : 0,
          ...filter.requestParams,
        }),
        defaultValue: selectedPick,
      };

      if (filter.mode === "jobs") {
        beforeFilters = [
          {
            key: "show_closed",
            type: "check",
            label: "Show all (active and inactive)",
          },
        ];
      }

      // multi picker support
      if (itemLimit > 1) {
        handleSend = (values) => {
          const selected = values?.keyword || [];
          handleQueryInput({
            colIndex: index,
            value: selected?.map((sel) => sel?.name) || [],
            exactMatch: filter.allowFreeform === false,
            passAs: selected.length
              ? filter.getField
                ? selected?.map((sel) => sel[filter.getField])
                : selected?.map((sel) => sel.name)
              : [],
          });
        };
      } else {
        handleSend = (values) => {
          const selected = values.keyword;
          handleQueryInput({
            colIndex: index,
            value: selected?.name || "",
            passAs: selected
              ? filter.getField
                ? selected[filter.getField]
                : selected.name
              : "",
          });
        };
      }
      break;
    }
    case "links": {
      keyWordFilter = {
        type: "select",
        label: "Select link type",
        options: linkTypes.map((type) => ({
          value: type.key,
          label: type.label,
        })),
      };

      additionalFilters = linkTypes.map((type) => ({
        key: type.key,
        label: type.label,
        type: "input-picker",
        mode: type.mode,
        showIf: ["keyword", type.key],
        allowFreeform: type.key === "pn",
      }));

      handleSend = (values) => {
        handleQueryInput({
          colIndex: index,
          exactMatch:
            values[values.keyword?.value]?.key !==
            values[values.keyword?.value]?.name,
          value: values.keyword?.value
            ? [values.keyword?.value, values[values.keyword?.value]?.key]
            : undefined,
        });
      };
      break;
    }
    case "text": {
      keyWordFilter = {
        type: "text",
        readOnly: isReadOnly,
        defaultValue: (query || {}).value || "",
      };
      break;
    }
    case "number": {
      keyWordFilter = {
        type: "number",
        readOnly: isReadOnly,
        defaultValue: (query || {}).value || "",
      };
      if (filter.mode !== undefined) {
        const options = [];
        if (filter.mode.equal === true) {
          options.push({ value: "eq", label: "Equal to" });
        }
        if (filter.mode.greater_than === true) {
          options.push({ value: "gt", label: "Greater than" });
        }
        additionalFilters = [
          {
            key: "mode",
            label: "Comparison mode",
            type: "select",
            defaultValue: query?.mode
              ? options.filter((opt) => opt.value == query.mode)[0]
              : { value: "eq", label: "Equal" },
            options,
          },
        ];
      }

      handleSend = (values) => {
        handleQueryInput({
          colIndex: index,
          value: values.keyword?.split(" ")[0],
          params: {
            mode: values.mode?.value,
          },
        });
      };
      break;
    }
    case "date": {
      if (filter.range === true) {
        keyWordFilter = {
          type: "date",
          label: "From date",
          readOnly: isReadOnly,
          info: "If no 'To date' is specified, the filter will be looking for exact matches of the 'From date'",
          defaultValue: (query || {}).value || "",
        };
        additionalFilters = [
          {
            key: "to",
            label: "To date (optional)",
            type: "date",
            clearable: true,
            defaultValue: (query || {}).to || "",
          },
        ];
      } else {
        keyWordFilter = {
          type: "date",
          readOnly: isReadOnly,
          defaultValue: (query || {}).value || "",
        };
      }

      handleSend = (values) => {
        handleQueryInput({
          colIndex: index,
          value: values.keyword?.split(" ")[0],
          params: {
            to: values.to?.split(" ")[0],
          },
        });
      };
      break;
    }
  }

  const onFilter = (e) => {
    e.stopPropagation();
    Store.setConfirm({
      title: column.title,
      msg: "Filter current column",
      fields: [
        ...beforeFilters,
        {
          key: "keyword",
          label: "Search by",
          ...keyWordFilter,
          autoFocus: true,
        },
        ...additionalFilters,
      ],
      focusKey: "keyword",
      sendOnEnter: true,
      hideDiscardBtn: true,
      action: { success: handleSend },
    });
  };

  // for checks, display Yes/No instead of 1/0
  let currentQueryValue = currentKeyword;
  if (column.type === "check") {
    currentQueryValue = currentKeyword === "1" ? "Yes" : "No";
  }
  currentQueryValue = htmlToText(
    (currentQueryValue || "").toString().split(" - ")[0]
  );

  const onClearFilter = (e) => {
    e.stopPropagation();
    handleSend({});
  };

  const onOptionsClick = (e) => {
    e.stopPropagation();
    setShowContextMenu(true);
  };

  const onPinClick = (e, pin = undefined) => {
    e.stopPropagation();
    onPin({
      columnKey: column.key,
      pin,
    });
  };

  return (
    <Tooltip withArrow relationship="label" content={column.title}>
      <div className={classes.container}>
        <Text
          truncate
          wrap={false}
          className={classes.columnName}
          onClick={isReadOnly ? undefined : onFilter}
        >
          {hasFilter && !Array.isArray(currentQueryValue)
            ? currentQueryValue
            : column.title}
        </Text>
        {column.hasCheckbox && (
          <Checkbox
            checked={
              options.colsData.find((e) => e.column === column.dataKey)?.checked
            }
            onChange={(ev) => {
              ev.stopPropagation();
              options.onChangeColumn(ev, column.dataKey);
            }}
            onClick={(e) => e.stopPropagation()}
          />
        )}
        {!hasFilter ? null : (
          <Tooltip withArrow relationship="label" content="Clear filter">
            <Button
              appearance="subtle"
              aria-label="Clear filter"
              icon={<Delete24Regular />}
              onClick={onClearFilter}
            />
          </Tooltip>
        )}
        {isReadOnly ? null : (
          <Tooltip withArrow relationship="label" content={"Filter"}>
            <Button
              appearance="subtle"
              aria-label="Filter"
              icon={<Filter24Regular />}
              onClick={onFilter}
            />
          </Tooltip>
        )}
        <Menu
          open={showContextMenu}
          onOpenChange={() => setShowContextMenu(!showContextMenu)}
        >
          <MenuTrigger disableButtonEnhancement>
            <Tooltip withArrow relationship="label" content={"Options"}>
              <Button
                appearance="subtle"
                aria-label="Options"
                icon={<ReOrderDotsVertical24Regular />}
                onClick={onOptionsClick}
              />
            </Tooltip>
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              {isPinned ? (
                <MenuItem
                  icon={<PinOff24Regular />}
                  onClick={(e) => onPinClick(e)}
                >
                  Unpin
                </MenuItem>
              ) : (
                <>
                  <MenuItem
                    icon={<ArrowStepInLeft24Filled />}
                    onClick={(e) => onPinClick(e, "left")}
                  >
                    Pin left
                  </MenuItem>
                  <MenuItem
                    icon={<ArrowStepInRight24Filled />}
                    onClick={(e) => onPinClick(e, "right")}
                  >
                    Pin right
                  </MenuItem>
                </>
              )}
              {languageOptions}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>
    </Tooltip>
  );
});
