export const grid = [
  {
    key: "print",
    title: "Print",
    type: "check",
    askConfirm: false
  },
  {
    key: "compliance",
    title: "Compliance",
    type: "check"
  },
  {
    key: "cm",
    title: "C M",
    filter: {
      type: "text"
    },
    sortable: true
  },
  {
    key: "pos_cm",
    title: "Pos CM",
    filter: {
      type: "text"
    },
    sortable: true
  },
  {
    key: "title",
    title: "Req Title",
    filter: {
      type: "text"
    },
    sortable: true
  },
  {
    key: "description",
    title: "Requirements Description",
    filter: {
      type: "text"
    },
    sortable: true
  },
  {
    key: "authority",
    title: "Req Authority",
    filter: {
      type: "select"
    },
    sortable: true
  },
  {
    key: "last_note",
    title: "Last note",
    filter: {
      type: "text"
    }
  },
  {
    key: "trd_prty_demo",
    title: "Demonstration to 3rd party",
    filter: {
      type: "text"
    },
    sortable: true
  },
  {
    key: "demo_method",
    title: "Verification method",
    filter: {
      type: "text"
    },
    sortable: true
  }
];