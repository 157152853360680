import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { MainContext, Api } from "src/utils";
import { isFunction, notify } from "src/utils";
import { Persona, Form } from "src/comps";
import {
  ActivityItem,
  Dialog as MSDialog,
  DialogFooter,
  DialogType as MSDialogType,
  PrimaryButton,
  Link,
  ScrollablePane,
  ScrollbarVisibility,
  Text,
  IconButton,
  TooltipHost,
} from "@fluentui/react";
import { ThemeContext } from "src/utils/themes";
import { max } from "moment";

interface IProps {
  id?: string;
  title?: string;
  subtitle?: string;
  refID?: string | number;
  alertContext?: boolean | string;
  get?: string | Function;
  basePath: string;
  getData?: any;
  archive?: string;
  onArchive?: Function;
  size?: string;
  onDismiss?: Function;
  saveBtnText?: string;
  alertLink?: string;
  save?: string | Function;
  onAdd?: Function;
  saveData?: any;
}

export default observer((props: IProps) => {
  const Store = useContext(MainContext);
  const Theme = useContext(ThemeContext);

  const [notes, setNotes] = useState([]);
  const [reset, setReset] = useState(false);
  const [values, setValues] = useState({});

  const modalName = props.id || "notes";

  props = { ...props, ...Store.getModalProps(modalName) };

  const fields = [
    {
      key: "recipients",
      placeholder: "Select alert recipients by name/surname",
      type: "people-picker",
      minChar: 1,
      itemLimit: 1000,
      width: "100%",
      hidden: !props.alertContext,
    },
    {
      key: "note",
      type: "multiline",
      formatting: true,
      maxLength: 2048,
      width: "100%",
    },
  ];

  const get = async () => {
    if (isFunction(props.get)) {
      return await props.get();
    }
    return await Api.get({
      api: props.basePath + "/" + props.get,
      data: {
        id: props.refID,
        ...props.getData,
      },
    });
  };

  // load notes and save them is state (used for reload functionality too)
  const loadNotes = async () => {
    const notes = await get();
    setNotes(notes);
    return notes;
  };

  // function to archive a note with confirm dialog
  const archive = (note) => {
    Store.setConfirm({
      title: "Archive",
      msg: "Are you sure you would like to archive the selected note?",
      action: {
        success: (values) => {
          Api.get({
            api: props.basePath + "/" + props.archive,
            data: {
              ref_id: props.refID,
              note_id: note.id,
            },
            msg: "Note archived",
          }).then(async () => {
            // when the archiviation process is complete, reload the notes list
            // and call optional onArchive props function
            const updatedNotes = await loadNotes();
            if (props.onArchive) {
              props.onArchive({
                note_id: note.id,
                last_active: updatedNotes.filter(
                  (note) => note.active !== false && note.active !== 0
                )[0],
              });
            }
          });
        },
      },
    });
  };

  useEffect(() => {
    if (!Store.isModalOpen(modalName)) {
      return;
    }
    loadNotes();
  }, [Store.isModalOpen(modalName)]);

  useEffect(() => {
    setReset(false);
  }, [notes]);

  let no_notes = !notes || !notes.length ? <p>No results</p> : null;

  if (!Store.isModalOpen(modalName)) {
    return null;
  }

  const width = (props.size == "medium" ? "700px" : "500px") + " !important";

  const isInputEmpty = values.note?.length <= 2;

  return (
    <MSDialog
      hidden={false}
      onDismiss={() => {
        if (props.onDismiss) {
          props.onDismiss({ last_note: notes[0] });
        }
        Store.closeModal(true);
      }}
      modalProps={{
        isBlocking: true,
        dragOptions: {
          closeMenuItemText: "Close",
          moveMenuItemText: "Move",
          menu: undefined,
        },
      }}
      dialogContentProps={{
        type: MSDialogType.close,
        title: props.title || "Notes",
      }}
      styles={{
        main: {
          width: width,
          maxWidth: width,
          height:
            (props.size == "medium" ? 620 : 520) +
            (props.alertContext ? 55 : 0) +
            "px !important",
        },
      }}
    >
      <div style={{ height: props.size == "medium" ? "370px" : "270px" }}>
        {props.subtitle ? (
          <div style={{ marginTop: "-10px" }}>
            <Text>{props.subtitle}</Text>
          </div>
        ) : null}
        <ScrollablePane
          styles={{
            root: {
              height:
                (props.size == "medium" ? 360 : 260) +
                (props.subtitle ? 0 : 40) +
                "px",
              marginTop: props.subtitle ? "30px" : "0",
            },
          }}
          scrollbarVisibility={ScrollbarVisibility.auto}
        >
          {no_notes}
          {!reset
            ? (notes || []).map((note, index) => {
                let author =
                  note.author == +note.author
                    ? Store.getUser(note.author) || {}
                    : Store.getUserFromName(note.author) || {};
                return (
                  <ActivityItem
                    key={index}
                    styles={{ root: { marginTop: "20px" } }}
                    activityDescription={[
                      <Link
                        key={index}
                        onClick={() => {
                          Store.navigate("sip:" + author.email);
                        }}
                      >
                        <span
                          style={
                            note.active === 0 || note.active === false
                              ? { textDecoration: "line-through" }
                              : {}
                          }
                        >
                          {author.fullName}
                        </span>
                      </Link>,
                      <span
                        style={
                          note.active === 0 || note.active === false
                            ? { textDecoration: "line-through" }
                            : {}
                        }
                      >
                        - {note.date}
                      </span>,
                      props.archive ? (
                        <TooltipHost
                          content="Archive"
                          calloutProps={{ gapSpace: 0 }}
                        >
                          <IconButton
                            iconProps={{ iconName: "Delete" }}
                            title="Archive"
                            ariaLabel="Archive"
                            disabled={
                              note.active === 0 || note.active === false
                            }
                            onClick={() => archive(note)}
                          />
                        </TooltipHost>
                      ) : null,
                    ]}
                    activityIcon={<Persona user={author} iconOnly={true} />}
                    comments={[
                      <span
                        key={index}
                        dangerouslySetInnerHTML={{ __html: note.note }}
                        style={{
                          ...(note.active === 0 || note.active === false
                            ? { textDecoration: "line-through" }
                            : {}),
                          color: Theme.getTheme().black,
                        }}
                      />,
                    ]}
                  />
                );
              })
            : null}
        </ScrollablePane>
      </div>

      {props.save && !reset && (
        <div style={{ marginTop: 45 }}>
          <Form
            fields={fields}
            defaultValues={values}
            onValuesChange={setValues}
          />
        </div>
      )}
      {props.save && (
        <DialogFooter>
          <PrimaryButton
            styles={{ root: { marginTop: "10px", marginRight: "-10px" } }}
            text={props.saveBtnText || "Add note"}
            disabled={isInputEmpty}
            onClick={() => {
              setReset(true);

              const saveCallback = (data) => {
                // send alert to recipients if necessary
                if (props.alertContext && values.recipients?.length) {
                  Api.get({
                    api: "notifications/send-note-alert",
                    silent: true,
                    data: {
                      context: props.alertContext,
                      link: props.alertLink || "",
                      note: values.note,
                      recipients: values.recipients.map((r) => r.key),
                    },
                  });
                }

                notify("Note added");
                loadNotes();

                if (props.onAdd) {
                  props.onAdd(values.note);
                }
              };

              setValues({});

              if (isFunction(props.save)) {
                props.save(values.note).then(saveCallback);
              } else {
                Api.get({
                  api: props.basePath + "/" + props.save,
                  data: {
                    ...props.saveData,
                    note: values.note,
                  },
                }).then(saveCallback);
              }
            }}
          />
        </DialogFooter>
      )}
    </MSDialog>
  );
});
