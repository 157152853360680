import { Api } from "src/utils";

// tells if it is requested to find an associated name
const isRequested = (item) => {
  return (
    item?.key
    && (
      item.name === undefined
      || item.name === item.key
    )
  );
}
// get name of currently selected key when requested
// TODO: this could probably become the default behavior
export const getNameFromKey = async (selected, api, params = {}) => {
  const isArray = Array.isArray(selected);
  
  if (
    !isArray
    && isRequested(selected)
  ) {
    selected = (await Api.get({
      api,
      silent: true,
      data: {
        id: selected.key,
        ...params
      }
    }) || [])[0];
  }
  // for arrays use batch fetching
  else if (isArray) {
    const idsToFetch = [];
    for (let sel of selected) {
      if (isRequested(sel)) {
        idsToFetch.push(sel.key);
      }
    }
    const results = (await Api.get({
      api,
      silent: true,
      data: {
        ids: idsToFetch,
        ...params
      }
    }) || []);
    for (let sel of selected) {
      if (isRequested(sel)) {
        sel.name = results.filter(r => r.key == sel.key)[0]?.name;
      }
    }
  }
  return selected;
};