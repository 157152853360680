import "./GridRenderer.styles.css";

import React, { useState, useEffect, useRef, useContext, useImperativeHandle, forwardRef, lazy, useCallback } from "react";
import { observer } from "mobx-react-lite";
import { MainContext } from "src/utils";

import Table, { Column } from "react-base-table";

import { contextMenu } from "./src/contextMenu";

import { IContextualMenuProps } from "@fluentui/react";
import { IGridMainProps } from "src/types";

import Cell from "./src/Cell";
import HeaderCell from "./src/HeaderCell";
import areMatrixEqual from "./src/matrixEquality";
import { toggleBranch, generateTree } from "./src/treeView";
import { calcColumnWidth, calcResponsiveColWidth } from "./src/colSize";
import { checkRow, onCheck, checkAll } from "./src/checks";
import { filterData } from "./src/filtering";
import { sortData } from  "./src/sorting";

import { ModalContainer } from "src/comps";
import { isMobile } from "react-device-detect";
import { getDataObject } from "./src/getDataObject";

const LoadingMore = lazy(() => import("./src/LoadingMore"));
const MobileColumn = lazy(() => import("./src/column-types/MobileColumn"));
const GridContainer = lazy(() => import("./src/Container"));
const GridTitle = lazy(() => import("./src/Title"));
const SingleView = lazy(() => import("./src/SingleView"));

const sanitizeFilters = (requestedFilters) => {
  const newFilters = {};
  Object.entries(requestedFilters).forEach(([key, value]) => {
    newFilters[key] = (typeof value === "object" && !Array.isArray(value)) ? value : { value };
  });
  return newFilters;
};

// actual grid for data showing
// the grid is based on the data matrix received as prop
export default observer(forwardRef((props:IGridMainProps, ref) => {
  const Store = useContext(MainContext);

  const tableRef = props.tableRef ? props.tableRef : useRef(null);

  // checks container composed of checks, selectable, checkAllInCol and checkAll
  const [checksMap, setChecksMap] = useState({
    rowChecks: [], checks: [],
    checkAllInCol: [], checkAll: false
  });

  const [ordering, setOrdering] = useState(props.ordering || [,]);
  const [filters, setFilters] = useState<any>(sanitizeFilters(props.filters || props.defaultFilters || {}));
  const [matrix, setMatrix] = useState([]);
  const [data, setData] = useState([]);
  const [selMultiLang, setSelMultiLang] = useState(
    props.defaultLanguage ? props.multiLangOptions.indexOf(props.defaultLanguage) : 0
  );
  const [visibleCols, setVisibleCols] = useState(
    props.visibleCols || props.cols.map(col => col.key)
  );
  const [expandedTreeRows, setExpandedTreeRows] = useState(props.expandedRows || {});
  const [widths, setWidths] = useState(undefined);
  const [gridWidth, setGridWidth] = useState(0);
  const [selRowIndex, setSelRowIndex] = useState(undefined);
  const [contextMenuRow, setContextMenuRow] = useState([]);
  const [contextMenuItems, setContextMenuItems] = useState([]);
  const [lazyLoading, setLazyLoading] = useState(false);
 
  // public methods
  const onFilter = ({ colKey, value, passive = false, params }) => {
    const colInfo = props.cols.filter(col => col.key === colKey)[0];
    const useExactMatch = ["select", "picker"].includes(colInfo?.filter?.type);

    const newFilters = {
      ...filters,
      [colKey]: {
        value,
        passAs: value,
        exactMatch: useExactMatch,
        passive,
        ...params
      }
    };
    setFilters(newFilters);
    
    props.onSearchQuery({
      col: props.cols.findIndex(col => col.key === colKey),
      value: value,
      exactMatch: useExactMatch,
      getOptions: () => {},
      filters: newFilters,
      ordering
    });
  };
  
  useImperativeHandle(ref, () => ({
    filters,
    ordering,
    getState() { return { filters, ordering } },
    onFilter(props) {
      onFilter(props)
    },
    updateFilters(newFilters) {
      updateFilters(newFilters)
    },
    clearSelection() {
      setChecksMap({
        rowChecks: [], checks: [],
        checkAllInCol: [], checkAll: false
      });
    },
    async refresh() {
      props.lazy.onLoad(0, filters, ordering, []);
      setLazyLoading(true);
    }
  }))

  const updateFilters = (requestedFilters) => {
    setFilters(sanitizeFilters(requestedFilters));
  };

  // query update from props
  useEffect(() => {
    if (props.lazy || props.filters === undefined) return;
    updateFilters(props.filters);
  }, [props.filters]);

  useEffect(() => {
    // tree mode should expand all branches while filtering
    // do it only if there is a valid query parameter
    let expand = undefined;
    if (props.treeView && Object.values(filters).filter(q => {
      return (q != undefined && (!q || q.value != undefined));
    }).length) {
      let rows = {};
      data.forEach(row => {
        const position = Array.isArray(row[0]) ? row[0][0] : row[0];
        rows[position.replace(/\s/, "")] = true;
      });
      expand = rows;
    }

    generateMatrix({ expand });
  }, [filters]);

  useEffect(() => {
    if (props.scrollTop && props.onScroll) {
      tableRef.current.scrollToTop(props.scrollTop);
    }
  }, [props.scrollTop]);

  const generateMatrix = async ({
    new_filters = undefined, expand = expandedTreeRows,
    new_order = ordering
  }) => {
    // set values to be remembered
    if (new_filters !== undefined) {
      updateFilters(new_filters);
    }
    else {
      new_filters = filters;
    }
    setOrdering(new_order);

    // add the original index to every row before filtering and/or ordering
    // in order to avoid losing the original indexes that might be passed to
    // user defined callbacks
    let data = [...props.data];
    for (let i = 0; i < data.length; i++) {
      if (!Array.isArray(data[i])) {
        data[i].index = i;
        continue;
      }
      data[i].push(i);
    }
    
    // set matrix data with filters and ordering applied if not lazy
    let newMatrix = data;
    if (!props.lazy) {
      data = getDataObject({
        rows: data,
        cols: props.cols
      });
      newMatrix = sortData(
        await filterData(getDataObject({
          rows: data,
          cols: props.cols
        }), new_filters, props.cols, Store),
        new_order, props.cols
      );
    }
    
    if (props.treeView) {
      newMatrix = generateTree({ expand, grid: newMatrix });
    }
    
    if (!areMatrixEqual({ matrix, newMatrix })) {
      // show new data
      setMatrix(newMatrix);

      if (props.onMatrixChange) {
        props.onMatrixChange(newMatrix);
      }
    }
  };

  // update table rendering
  useEffect(() => {
    tableRef.current?.forceUpdate();
    setLazyLoading(false);
  }, [matrix]);

  const update = ({ expanded = expandedTreeRows }) => {
    if (props.loaded || props.loaded === undefined) {

      // set widths only if data is not empty or they have never been set
      // (fixes 0 results resizing issue)
      if (props.data.length || !gridWidth) {
        // use custom widths if available
        if (localStorage.getItem("cellSplit" + props.absolute_id)) {
          let customWdt = JSON.parse(
            localStorage.getItem("cellSplit" + props.absolute_id)
          );
          // convert old array saves
          let newWidths = {};
          if (typeof customWdt === 'object' && !Array.isArray(customWdt) && customWdt !== null) {
            props.cols.forEach((col) => {
              newWidths[col.key] = customWdt[col.key] || 200;
            });
          }
          else {
            props.cols.forEach((col, i) => {
              newWidths[col.key] = customWdt[i] || 200;
            });
          }
          setWidths(newWidths);
          setGridWidth(
            visibleCols.reduce((a, b) => (newWidths[a] || 0) + (newWidths[b] || 0), 0)
          );
        }
        else {
          // calculate all the collumns with at first (faster)
          // also takes into account the selected columns to be visible
          let result = calcColumnWidth({
            matrix: props.data,
            cols: props.cols,
            treeView: props.treeView
          });

          
          // get responsive widths
          let newWidths = {};
          Object.keys(result.widths).forEach((colKey) => {
            newWidths[colKey] = calcResponsiveColWidth(
              tableRef?.current?.props?.width,
              result.gridWidth,
              result.widths,
              props.cols.find(c => c.key === colKey)
            );
          });
          setWidths(newWidths);
          setGridWidth(
            visibleCols.reduce((a, b) => (newWidths[a] || 0) + (newWidths[b] || 0), 0)
          );
        }
      }

      // check for data/expanded rows differencies
      const expandedRowsDiffers = Object.entries(expanded).filter(([pos, status]) => expandedTreeRows[pos] !== status).length;
      const dataDiffers = props.data.filter((row, i) => {
        // data row object compatibility
        if (!Array.isArray(row)) {
          row = Object.values(row);
        }
        return row.filter((cell, j) => {
          return data[i] === undefined || data[i][j] != cell;
        }).length;
      }).length;

      if (!data.length || !props.data.length || dataDiffers || expandedRowsDiffers) {
        setData(props.data);

        if (expandedRowsDiffers) {
          setExpandedTreeRows(expanded);
        }

        // when filtering tree views from startup, make sure to expand branch
        // this is done only at startup, to avoid having all nodes reopening
        // when a row is edited
        let expand = undefined;
        if (expanded == {} || (expanded == props.expandedRows && !props.onExpandBranch)) {
          if (props.treeView && Object.values(filters).filter(q => {
            return (q != undefined && (!q || q.value != undefined)) && q.passive !== true;
          }).length) {
            let rows = {};
            props.data.forEach(row => {
              const position = Array.isArray(row[0]) ? row[0][0] : row[0];
              rows[position.toString().replace(/\s/, "")] = true;
            });
            expand = rows;
          }
        }
        else {
          expand = expanded;
        }
        generateMatrix({ expand });
      }
    }
  };

  useEffect(() => {
    update({ 
      // keep opened tree branch as controlled value only if callback is defined
      expanded: props.onExpandBranch ? props.expandedRows : undefined
    });
  }, [props.data, props.rowStatus, props.expandedRows, visibleCols]);

  // on table ID change update preferences
  useEffect(() => {
    setVisibleCols(props.visibleCols || props.cols.map(col => col.key));
  }, [props.id, props.cols]);

  // function to get the current row index converted to its original data index
  // in case some filtering/ordering has been done.
  // this is mostly used to return the correct data index to props callbacks.
  const getCurrentFilteredIndex = (rowIndex) => {
    if (!matrix[rowIndex]) {
      return rowIndex;
    }
    return matrix[rowIndex][matrix[rowIndex].length - 1];
  };
  
  let dataRows = [...matrix];
  // add empty rows at the end if requested
  if (props.emptyRows) {
    dataRows = [
      ...dataRows,
      ...[...Array(props.emptyRows).keys()].map(() => props.cols.map(() => [""]))
    ];
  }

  const dataObject = getDataObject({
    rows: dataRows,
    cols: props.cols
  });

  // header related options
  const headerOptions = {
    filters,
    dataObject,
    data: props.data,
    cols: props.cols,
    setFilters,
    selMultiLang,
    setSelMultiLang: (lang) => {
      setSelMultiLang(lang);
      if (props.onChangeSelMultiLangField) {
        props.onChangeSelMultiLangField(lang);
      }
    },
    multiLangOptions: props.multiLangOptions || [], 
    lazy: props.lazy,
    selectAdditionalOptions: props.selectAdditionalOptions,
    onSearchQuery: props.onSearchQuery ? (options) => props.onSearchQuery({
      ...options,
      ordering
    }) : undefined,
    onChangeColumn: props.onChangeColumn,
    colsData: props.colsData
  }

  // detects if mouse is highlighting a text
  const isHighlighting = () => {
    return window.getSelection && window.getSelection().type === 'Range';
  };

  const onContextMenu = async ({ e, rowIndex }) => {
    rowIndex = getCurrentFilteredIndex(rowIndex);
    
    // allow copy functionality if text selected
    if (isHighlighting()) {
      return;
    }

    e.preventDefault();
    // get menu options (supports lazy loading)
    if (props.contextMenu) {
      const menuItems = await props.contextMenu({
        rowIndex,
        row: data[rowIndex],
      });
      setContextMenuItems(menuItems || []);
    }
    setContextMenuRow([[e.pageX, e.pageY], rowIndex]);
    setSelRowIndex(rowIndex);
  };
  
  // setup options for the cell renderer
  const rendererOptions = {
    links: props.links,
    availableIn: props.availableIn,
    selMultiLang,
    originalData: props.data,
    styles: props.styles,
    treeView: props.treeView,
    expandedTreeRows,
    onTreeViewToggle: (position) => {
      const expanded = toggleBranch({ position, expandedTreeRows });
      if (props.onExpandBranch) {
        props.onExpandBranch({ position, expanded, filters });
      }
      else {
        generateMatrix({ expand: expanded });
        setExpandedTreeRows(expanded);
      }
    },
    getCurrentFilteredIndex,
    cols: props.cols,
    additionalErrors: props.additionalErrors,

    // TODO: replace with onCellClick
    onEditableCheckClick: props.onEditableCheckClick,
    onEditableClick: props.onEditableClick,
    onButtonClick: props.onButtonClick,
    
    onCellClick: props.onCellClick,
    onContextMenu,
    onAdvCellClick: ({ second_arg, rowIndex, columnIndex }) => {
      rowIndex = getCurrentFilteredIndex(rowIndex);
      if (typeof second_arg == 'function') {
        second_arg({ rowIndex, columnIndex });
      }
      else if (second_arg && second_arg.length) {
        Store.navigate(second_arg)
      }
    }
  };

  const onCheckToggle = ({ rowIndex }) => {
    let newChecksMap;
    
    // if rowIndex is undefined, it means that the header checkbox has been clicked
    if (rowIndex === undefined) {
      newChecksMap = checkAll({
        cols: props.cols,
        data: props.data,
        rowStatus: props.rowStatus,
        checksMap
      });
    }
    else {
      rowIndex = getCurrentFilteredIndex(rowIndex);
      newChecksMap = checkRow({
        rowIndex,
        checksMap,
        cols: props.cols,
        data: props.data
      });
    }
    setChecksMap(newChecksMap);
    onCheck(newChecksMap, props.onCheckChange);
  };

  // update grid on header column resize
  const onResize = ({ column, width }) => {
    // update widths and grid total width
    const newWidths = {...widths};
    newWidths[column.key] = width;
    setWidths(newWidths);
    setGridWidth(
      visibleCols.reduce((a, b) => (newWidths[a] || 0) + (newWidths[b] || 0), 0)
    );

    // save customization
    localStorage.setItem(
      "cellSplit" + props.absolute_id,
      JSON.stringify(newWidths)
    );
  };

  // on grid scroll (to enable custom onScroll)
  const onScroll = ({ scrollTop }) => {
    if (props.onScroll) {
      props.onScroll({ scrollTop });
    }
  };

  // lazy loading get more data on scroll to bottom
  const onEndReached = () => {
    if (props.lazy && data.length) {
      if (data.length < props.lazy.total && !lazyLoading && props.lazy.onLoad) {
        props.lazy.onLoad(data.length, filters, ordering);
        setLazyLoading(true);
      }
    }
  };

  const clearFilters = () => {
    const newFilters = (props.lazy && props.filters) ? props.filters : {};
    generateMatrix({
      new_filters: newFilters,
      new_order: props.ordering || [,]
    });
    
    if (props.onSearchQuery) {
      props.onSearchQuery({ filters: newFilters });
    }
    
    if (props.onClearFilters) {
      props.onClearFilters();
    }
  };

  const manageColumns = () => Store.setConfirm({
    title: "Manage columns",
    msg: "Choose the columns to maintain visible",
    fields: [
      {
        key: "columns",
        label: "Columns",
        type: "select",
        multi: true,
        required: true,
        checks: visibleCols,
        options: (
          props.cols.map(col => {
            return {
              value: col.key,
              label: col.title,
              disabled: col.removable === false
            };
          })
        )
      }
    ],
    action: {
      success: (values) => {
        setVisibleCols(values.columns);

        // endpoint callback if any
        if (props.onVisibleColsChange) {
          props.onVisibleColsChange(values.columns);
        }
      }
    }
  });

  const expandAll = () => {
    let rows = {};
    data.forEach(row => {
      const position = (
        Array.isArray(row[0])
          ? row[0][0] : row[0]
      );
      rows[
        position.replace(/\s/, "")
      ] = true;
    });
    setExpandedTreeRows(rows);
    generateMatrix({ expand: rows });
  };

  useEffect(() => {
    tableRef.current && tableRef.current.forceUpdate();
  }, [props.reset]);

  const gridSettingsMenu: IContextualMenuProps = {
    items: [
      {
        key: "columns",
        text: "Manage columns",
        disabled: props.allowColumnsCustomization === false,
        iconProps: { iconName: "DoubleColumnEdit" },
        onClick: manageColumns
      },
      {
        key: "clear_filters",
        text: "Clear filters",
        disabled: props.allowClearFilters === false,
        iconProps: { iconName: "ClearFilter" },
        onClick: clearFilters
      },
      {
        key: "expand_all",
        text: "Expand all",
        disabled: !props.expandAllBtn || !props.treeView,
        iconProps: { iconName: "MiniExpand" },
        onClick: expandAll
      },
      {
        key: "contract_all",
        text: "Contract all",
        disabled: !props.contractAllBtn || !props.treeView,
        iconProps: { iconName: "MiniContract" },
        onClick: () => {
          setExpandedTreeRows({});
          generateMatrix({ expand: {} });
        }
      }
    ]
  };

  // context menu (if opened)
  let RightClickMenu = null;
  if (contextMenuRow[0]) {
    RightClickMenu = contextMenu({
      id: props.id,
      options: contextMenuItems,
      rowIndex: contextMenuRow[1],
      onDismiss: () => setContextMenuRow([]),
      pos: contextMenuRow[0] || []
    });
  }

  const TableCell = useCallback(({ column, cellData, rowData, rowIndex }) => {
    const originalRowIndex = getCurrentFilteredIndex(rowIndex);
    return (
      <Cell
        {...{
          cellData,
          column,
          options: rendererOptions,
          rowIndex,
          rowData,
          rowStatus: props.rowStatus[originalRowIndex],
          columnIndex: props.cols.findIndex(col => col.key === column.key),
        }}
      />
    )
  }, [matrix, props.rowStatus, visibleCols]);

  const TableHeaderCell = useCallback(({ column, columnIndex }) => {
    return (
      <HeaderCell
        column={ column }
        index={ visibleCols.findIndex(colKey => colKey == column.key) }
        onPin={ props.onColumnPin }
        { ...headerOptions }
      />
    );
  }, [matrix, visibleCols, filters, selMultiLang]);

  const overlayRenderer = useCallback(() => {
    if (!lazyLoading) return null;
    return <LoadingMore />;
  }, [lazyLoading]);

  if ((!widths || !gridWidth) && props.data.length) {
    return null;
  }

  const toggleSort = ({ key, order }) => {
    // actually sort the matrix
    const ordering = [key, order === "asc"];

    // if requested by the user, fire the onOrder function
    if (props.onSort) {
      setOrdering(ordering);
      props.onSort({
        colKey: key,
        columnIndex: props.cols.findIndex(col => col.key === key),
        reverse: ordering[1],
        filters,
        ordering
      });
    }
    else {
      generateMatrix({
        new_order: ordering
      });
    }
  };
  
  const rowProps = ({ rowIndex}) => ({
    onContextMenu: (e) => onContextMenu({ e, rowIndex })
  })

  const rowRenderer = ({ cells, columns }) => {
    const rowSpanIndex = columns.findIndex(col => col.rowSpan);
    const rowSpan = columns.find(col => col.rowSpan)?.rowSpan;
    if (rowSpan > 1) {
      const cell = cells[rowSpanIndex]
      const style = {
        ...cell.props.style,
        height: rowSpan * 50 - 1,
        zIndex: 1,
      }
      cells[rowSpanIndex] = React.cloneElement(cell, { style })
    }
    return cells
  }
  
  const getGridContent = ({ width, height }) => {
    if (!widths || !gridWidth) return null;

    const columnsList = props.cols.map((col, index) => {
      if (!visibleCols.includes(col.key)) {
        return null;
      }
      const frozen = col.pinned ? Column.FrozenDirection[col.pinned.toUpperCase()] : undefined;

      return (
        <Column
          { ...col }
          index={ index }
          dataKey={ col.key }
          resizable={ true }
          frozen={ frozen }
          width={ widths[col.key] }
        />
      );
    });
    
    if (props.selectable === true) {
      columnsList.unshift(
        <Column {...{
          key: '__selection__',
          width: 45,
          flexShrink: 0,
          resizable: false,
          frozen: Column.FrozenDirection.LEFT,
          isAllSelected: checksMap.checkAll,
          selectedRows: checksMap.checks,
          onChange: onCheckToggle
        }}/>
      );
    }
    
    // anchor for context menu on mobile (iOS compatibility)
    if (isMobile) {
      columnsList.unshift(<MobileColumn />);
    }
  
    const resultsTotal = ((props.lazy && props.lazy.total !== undefined) ? props.lazy.total : matrix.length) || 0;

    return (
      <>
        <GridTitle
          width={ width }
          title={ props.title }
          resultsTotal={ resultsTotal }
          settingsMenu={ gridSettingsMenu }
          styles={ props.styles }
        />
        <Table
          fixed
          ref={ tableRef }
          data={ dataObject }
          width={ width }
          height={ height - 45 /* title height */ }
          onScroll={ onScroll }
          onEndReached={ onEndReached }
          sortBy={{ key: ordering[0], order: ordering[1] ? "asc" : "desc" }}
          onColumnSort={ toggleSort }
          overscanRowCount={ props.overScanRowCount || 3 }
          rowProps={ rowProps }
          components={{ TableCell, TableHeaderCell }}
          headerHeight={[50]}
          rowRenderer={ rowRenderer }
          overlayRenderer={ overlayRenderer }
          onColumnResizeEnd={ onResize }
        >
          { columnsList }
        </Table>
        { RightClickMenu }
      </>
    );
  };

  const singleViewModalProps = {
    id: props.id,
    selRow: (selRowIndex !== undefined && props.data[selRowIndex]) ? Object.values({...props.data[selRowIndex]}) : [],
    cols: props.cols
  };
  
  return (
    <>
      <ModalContainer
        id={ "single-view-" + props.id }
        modal={ SingleView }
        modalProps={ singleViewModalProps}
      />
      <GridContainer
        id={ props.id }
        styles={ props.styles }
      >
        { getGridContent }
      </GridContainer>
    </>
  );
}));
