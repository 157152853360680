import { Api } from "src/utils";

export const isEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// aray utilities ______________________________________________________________
export function arrayGetSum(array, column) {
  let values = array.map((item) => parseFloat(item[column]) || 0);
  return (values.length) ? values.reduce((a, b) => a + b) : 0;
}

export function isFunction(f) {
  return f && {}.toString.call(f) === '[object Function]';
}

// user image __________________________________________________________________

// image cache timeout
const HUNDRED_MINUTES = .1 * 60 * 1000000;

export const getUserImg = async ({ path }) => {
  if (!path) return "";

  // images are cached in local storage for stress relief on the APIs
  let image = "";
  const stg = await JSON.parse(localStorage.getItem("userImg" + path));

  // check if image cache is expired
  if (stg && (+stg.timestamp + HUNDRED_MINUTES) > (new Date()).getTime()) {
    image = stg.img;
  }
  // otherwise they are requested to the APIs
  else {
    const response = await Api.get({
      api: "resources/get-image",
      silent: true,
      data: { path: path }
    });

    let imgObj = {
      img: image = response,
      timestamp: (new Date()).getTime().toString(),
    };
    localStorage.setItem("userImg" + path, JSON.stringify(imgObj));
  }

  return image;
};

// SN utilities ________________________________________________________________

export function snToBeDisplayed(sn) {
  if (!sn.toString().length) return "";
  return sn.toString().split(",");
}

// PN utilities ________________________________________________________________

// get the status of a determined PN at the time of the manufacturing book being
// definitely blocked (table block_progetto_sn_definitivo on DB)
export function getFormattedPN_MB(pn) {
    let {
    Codice, Revisione, Variante, Convalidato, Progress, Archiviato,
    Progress_Sage, Archiviato_Sage,
    CodiceOld
  } = pn;
  let type = "";
  let link = (
    "/app/#/dcss/pn/code=" + Codice?.toString()?.replace(/\//g, "%2F") +
    "&rev=" + Revisione + "&var=" + Variante
  );

  if (Convalidato == "0" && Progress != "Issued" && Archiviato == "no"
    && Progress_Sage != "Issued") {
    type = "danger";
  }
  else if (Convalidato == "1" && Progress == "Issued" && Archiviato == "no"
    && Progress_Sage == "Issued") {
    type = "link-black";
  }
  else if (Convalidato == "1" && Progress != "Issued" && Archiviato == "no"
    && (Progress_Sage != "Issued")) {
    type = "success";
  }
  else if (Convalidato == "1" && Progress != "Issued" && Archiviato == "no"
    && (Progress_Sage == "Issued")) {
    type = "success";
  }
  else if (Convalidato == "1" && Progress == "Issued" && Archiviato == "no"
    && (Progress_Sage != "Issued")) {
    type = "link-blue";
  }
  else if (Archiviato == "si") {
    type = "warning line-through";
  }

  if (Archiviato_Sage == "si") {
    type += " archived-sage";
  }

  return [CodiceOld, link, type];
}

// auto params support ______________________________________________________

// little logic to avoid having to insert every
// URL params in order to have one working
// workaround to standard react-router params logic
export const getAutoMatch = (match):any => {
  if (!match || !match["*"]) {
    return {};
  }
  
  const newMatch = {};
  const params = (match["*"] || "").replace("?", "")?.split("&");
  for (let param of params) {
    const parts = param.split("=");
    newMatch[parts[0]] = parts[1]?.replace(/\%2[Ff]/, "/");
  }
  return newMatch;
};