import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Api, MainContext } from "src/utils";
import { Stack, Text, Shimmer, Link, DocumentCard, TooltipHost, PrimaryButton, Pivot, PivotItem } from "@fluentui/react";

import { loadingStyles } from "./AvailableIn.styles";

export const linkTypes = [
	{ key: "sn", label: "SN", mode: "sn", path: "dcss/sn/" },
	{ key: "pn", label: "PN", mode: "pn", path: "dcss/pn/" },
	{ key: "cpn", label: "CPN", mode: "cpn", path: "dcss/cpn/" },
	{ key: "dm", label: "Data module", mode: "data-module", path: "dcss/data-modules/" },
	{ key: "doc", label: "Document", mode: "document", path: "dcss/documents/" },
	{ key: "ofi", label: "OFI", mode: "ofi", path: "dcss/ofi/" },
	{ key: "task", label: "Task", mode: "tasks", path: "gproject/projects-view/" },
	{ key: "req", label: "Requirement", mode: "compliance" },
	{ key: "ref", label: "OFI Reference", mode: "tasks", params: { is_ref: 1 }, path: "gproject/projects-view/is_ref=1&" },
  { key: "url", label: "URL" }
];

export const getLinkModeFromKey = (key) => {
  const link = linkTypes.find(link => link.key === key);
  return link ? link.mode : null;
};

interface IProps {
  id: number;
  type: "pn"|"sn"|"dm"|"doc"|"ofi"|"task"|"sup"|"job"|"sub"|"req"|"ref"|"url";
  onEdit: Function;
  close: Function;
  enableEdit?: boolean;
  onClick?: Function;
  setMode?: Function;
};

export const Links = observer((props:IProps) => {
  const Store = useContext(MainContext);

  const [data, setData] = useState([]);

  // get links data
  const getLinks = async () => {
    const data = await Api.get({
      api: "link/get",
      data: {
        id: props.id,
        type: props.type
      }
    });
    setData(data.filter(link => link.active));
  };
  useEffect(() => {
    getLinks();
  }, [props.id, props.type]);

  // display loading while retrieving available in
  if (!data) {
    return <Shimmer styles={ loadingStyles } />;
  }

  const getItem = (item) => {
    // support for linked tasks with lag
    const isTaskLinkedLag = item.type === "task" && props.type === "task";

    const onClick = async () => {
      // Open a new tab for the following types
      if (["pn", "sn",  "doc", "dm", "ofi", "sup", "job", "sub", "ref","url"].includes(item.type)) {
        Store.navigate(item.url, false);
        return;
      }
      if (isTaskLinkedLag) {
        await props.onClick(props.id);
        props.setMode("lag");
        Store.openModal("links");
        return;
      }
      if (item.type === "req") {
        await props.onClick(props.id);
        Store.openModal("reqs");
        return;
      }
    };

    let Card = (
      <DocumentCard 
        onClick={ onClick }
        styles={{ root: { padding: 10 }}}
      >
        <Text>
          <b>{item.type.toUpperCase()}</b>&nbsp;-&nbsp;
          <span className={ item.style }>
            {item.name}
          </span>
          {
            item.third_type ? (
              <span> (<b>{item.third_type?.toUpperCase()}</b> - {item.third_name})</span>
            ) : null
          }
        </Text>
      </DocumentCard>
    );

    if (!["req", "url", "pn", "sn"].includes(item.type) && !isTaskLinkedLag) {
      Card = (
        <Link href={item.url}>
          { Card }
        </Link>
      );
    }

    return (
      <TooltipHost content={ item.description}>
        { Card }
      </TooltipHost>
    );
  };

  const getGroup = (group) => {
    const filtered = data.filter(item => item.type === group.key);
    let counter = "(" + filtered.length + ")";

    // support for linked tasks with lag
    const isTaskLinkedLag = group.key === "task" && props.type === "task";

    const onClick = async () => {
      if (group.key === "url") {
        await props.onClick(props.id);
        Store.openModal("links");
        return;
      }
      if (isTaskLinkedLag) {
        await props.onClick(props.id);
        props.setMode("lag");
        Store.openModal("links");
        return;
      }
      if (group.path) {
        return;
      }
      await props.onClick(props.id);
      Store.openModal("reqs");
    };

    if (group.key == "req") {
      const compliantCounter = filtered.filter(item => item.compliance).length;
      const nonCompliantCounter = filtered.length - compliantCounter;
      counter = (
        compliantCounter
          ? (" (" + compliantCounter + " compliant)") : ""
      ) + (
        nonCompliantCounter
          ? (" (" + nonCompliantCounter + " non-compliant)") : ""
      );
    }

    const Card = (
      <DocumentCard
        onClick={ onClick }
        styles={{ root: { padding: 10 }}}
      >
        <Text>
          <b>{group.label}</b>&nbsp;{counter}
        </Text>
      </DocumentCard>
    );

    if (["req", "url"].includes(group.key) || isTaskLinkedLag) {
      return Card;
    }

    const url = import.meta.env.VITE_BASE_URL + group.path + "link_type=" + props.type + "&link_id=" + props.id;
    return (
      <Link href={ url }>
        { Card }
      </Link>
    );
  };

  const onEdit = () => {
    props.close();
    props.onEdit();
  };

  const groupsView = linkTypes
    .filter(group => data.filter(item => item.type === group.key).length > 0)
    .map(getGroup);

  return (
    <>
      <Stack tokens={{ childrenGap: 10 }}>
        { data.length ? (
            <Pivot>
              <PivotItem
                headerText={ "Type" }
                itemKey={ "grouped" }
              >
                <Stack tokens={{ childrenGap: 10, padding: "10px 0" }}>
                  { groupsView }
                </Stack>
              </PivotItem>
              <PivotItem
                headerText={ "All (" + data.length + ")" }
                itemKey={ "all" }
              >
                <Stack tokens={{ childrenGap: 10, padding: "10px 0" }}>
                  { data.map(getItem) }
                </Stack>
              </PivotItem>
              { (props.type == "task" || props.type == "ref") ? (
                  <PivotItem
                    headerText={ "Inputs (" + data.filter(link => link.side == "in").length + ")" }
                    itemKey={ "input" }
                  >
                    <Stack tokens={{ childrenGap: 10, padding: "10px 0" }}>
                    { data.filter(link => link.side == "in").map(getItem) }
                    </Stack>
                  </PivotItem>
                ) : null
              }
              { (props.type == "task" || props.type == "ref") ? (
                <PivotItem
                  headerText={ "Deliverables (" + data.filter(link => link.side == "out").length + ")" }
                  itemKey={ "deliverables" }
                >
                  <Stack tokens={{ childrenGap: 10, padding: "10px 0" }}>
                  { data.filter(link => link.side == "out").map(getItem) }
                  </Stack>
                </PivotItem>
                ) : null
              }
            </Pivot>
          ) : null
        }
        { props.onEdit !== undefined ? (
          <PrimaryButton
            iconProps={{ iconName: "Edit" }}
            disabled={ props.enableEdit === false }
            onClick={ onEdit}
            styles={{ root: { width: 40, minWidth: 40 }}}
          />
        ) : null }
      </Stack>
    </>
  );
});
