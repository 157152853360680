// function that tells if two grids matrix are equal
// used to avoid unnecessary rerenders
export default ({ matrix, newMatrix }) => {
  // check for data equality in order to avoid unnecessary loadings
  let equal = !(newMatrix.length != matrix.length);

  // updated in order to stop at first difference
  let i = 0;
  while (equal && i < newMatrix.length) {
    if (!matrix[i] || matrix[i].length != newMatrix[i].length) {
      equal = false;
    }

    for (let j = 0; j < newMatrix[i].length; j++) {
      if (matrix[i].length < (j + 1) || matrix[i][j] != newMatrix[i][j]) {
        equal = false;
      }
    }
    i++;
  }
  return equal;
};
