import { makeStyles, shorthands } from "@fluentui/react-components";
import { isMobile } from "react-device-detect";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "var(--appBackground)",
    ...shorthands.padding("55px", "0", "0", "0"),
    ...shorthands.overflow(isMobile ? "scroll" : "hidden", "hidden"),
  },
  content: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1
  }
});