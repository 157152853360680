/*
  Nav mode is usually used for "Attributes" modals.
  // TO DO split up AttributesDialog
*/

import { ReactElement, Suspense, useContext } from "react";
import { observer } from "mobx-react-lite";

import { MainContext } from "src/utils";
import { Grid, SplitPane } from "src/comps";
import { Nav } from "@fluentui/react";
import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { useStyles } from "./Grid.styles";

interface IProps {
  id: string;
  title: string;

  size?: string;
  style?: any;
  nav?: any;
  sidebar?: ReactElement;
  header?: ReactElement;
  footer?: ReactElement;

  footerHeight?: number;

  reset?: boolean;
  onDismiss?: Function;
  options: any;
};

export default observer((props:IProps) => {
  const Store = useContext(MainContext);
  const classes = useStyles();

  if (!Store.isModalOpen(props.id)) {
    return null;
  }

  // get size to apply to modal
  let size = props.size || "large";
  size = size.charAt(0).toUpperCase() + size.slice(1);

  const navStyles = {
    root: {
      width: "100%",
      minWidth: 0,
      height: 50 * (props.nav?.groups[0]?.links?.length + 1),
      boxSizing: "border-box",
      marginTop: 4,
      overflowY: "auto",
    }
  };

  // on modal close
  const onClose = () => {
    if (props.onDismiss) {
      props.onDismiss();
    }
    Store.closeModal(true);
  };

  return (
    <Suspense>
      <Dialog
      open={ true }
      onOpenChange={ onClose }
    >
      <DialogSurface className={ classes["modalContainer" + size] }>
        <DialogBody className={ classes.modalBody }>
          <DialogTitle
            className={ classes.modalTitle }
            action={
              <DialogTrigger action="close">
                <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
              </DialogTrigger>
            }
          >
            { props.title }
          </DialogTitle>
          <DialogContent className={ classes.modalContent }>
            <div className={ classes.header }>
              { props.header ? props.header : null }
            </div>

            { props.nav ? (
              <SplitPane
                id={ "gridDialog" + props.options.id }
                split="vertical"
                defaultSize={ "20%" }
                components={ () => ([
                  <Nav
                    styles={ navStyles }
                    { ...props.nav }
                  />,
                  !props.reset ? <Grid { ...props.options } /> : null
                ]) }
              />
            ) : (props.sidebar ? (
              <SplitPane
                id={ "gridDialog" + props.options.id }
                split="vertical"
                defaultSize={ "20%" }
                components={ () => ([
                  props.sidebar,
                  !props.reset ? <Grid { ...props.options } /> : null
                ]) }
              />
            ) : (!props.reset && <Grid { ...props.options } />)) }
            
            { props.footer }
          </DialogContent>
        </DialogBody>
      </DialogSurface>
    </Dialog>
    </Suspense>
  );
});
