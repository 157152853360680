import React, { useContext, Suspense } from "react";
import { observer } from "mobx-react-lite";

import { MainContext } from "src/utils";

interface IProps {
  id: string;
  modal: any;
  selRow?: any;
  modalProps?: any;
  onDismiss?: Function;
};

export default observer((props:IProps) => {
  const Store = useContext(MainContext);

  if (!Store || !Store.isModalOpen(props.id)) {
    return null;
  }
  const Modal = props.modal;
  const modalProps = {
    ...props.modalProps,
    ...Store.getModalProps(props.id)
  };

  return ( 
    <Suspense fallback={<div></div>}>
      <Modal { ...props } { ...modalProps } />
    </Suspense>
  );
});
