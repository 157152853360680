import { createContext } from "react";
import { makeAutoObservable } from "mobx";

import { createDarkTheme, createLightTheme } from "@fluentui/react-components";
import THEMES from "../../platforms.json";

export class ThemeStore {
  theme
  platformId

  constructor() {
    makeAutoObservable(this);

    // retro-compatibility
    // TODO: remove
    if (localStorage.getItem("darkMode")) {
      localStorage.setItem("theme", "dark");
      localStorage.removeItem("darkMode");
    }

    if (typeof window !== "undefined") {
      this.theme = localStorage.getItem("theme") || "system";
    }
    else {
      this.theme = "system";
    }
  }
  
  // sets FluentUI theme palette as CSS variables for ease of use
  private setCSSVariables(platformId) {
    const theme = this.getNormalizedThemeName();
    const palette = THEMES[platformId].themes[theme];
    
    Object.entries(palette).forEach(([key, value]) => {
      document.documentElement.style.setProperty(
        "--" + key,
        value.toString()
      );
    });

    // also set color scheme for scrollbars color
    document.documentElement.style.setProperty(
      "--color-scheme",
      theme
    );
    
    const darkMode = theme === "dark";
    
    document.documentElement.style.setProperty(
      "--color-scheme",
      darkMode ? "dark" : "light"
    );

    document.documentElement.style.setProperty(
      "--grid-odd-bg",
      darkMode ? "#121212" : "#f3f3f3"
    );

    document.documentElement.style.setProperty(
      "--grid-highlighted-bg",
      darkMode ? "#222222" : "#eaeaea"
    );

    document.documentElement.style.setProperty(
      "--black-text-color",
      darkMode ? "#ffffff" : "#000000"
    );

    document.documentElement.style.setProperty(
      "--danger-color",
      darkMode ? "#f9826d" : "#c43939"
    );

    document.documentElement.style.setProperty(
      "--success-color",
      darkMode ? "#7be891" : "#1b8c31"
    );

    document.documentElement.style.setProperty(
      "--link-blue-color",
      darkMode ? "#799ee8" : "#3062c7"
    );

    document.documentElement.style.setProperty(
      "--warning-color",
      darkMode ? "#f7e38f" : "#bc9425"
    );

    document.documentElement.style.setProperty(
      "--row-disabled-color",
      darkMode ? "#000000" : "#e1e1e1"
    );
  }

  // get theme name translating the "System" option into an actual choice
  getNormalizedThemeName(theme = this.theme) {
    if (typeof window === "undefined") {
      return "light";
    }
    if (theme === "system") {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        theme = "dark";
      }
      else {
        theme = "light";
      }
    }
    return theme;
  }

  getCurrentThemeName() {
    return this.theme;
  }

  setTheme(theme) {
    if (typeof window !== "undefined") {
      localStorage.setItem("theme", theme);
    }
    this.theme = theme;
    this.setCSSVariables(this.platformId);
  }

  getTheme(platformId = this.platformId, theme = this.theme, fluent9 = false) {
    theme = this.getNormalizedThemeName();

    if (platformId !== this.platformId) {
      this.setCSSVariables(platformId);
      this.platformId = platformId;
    }

    if (fluent9 === true) {
      const palette = THEMES[platformId].themes[theme];
      if (theme === "dark") {
        return createDarkTheme(palette);
      }
      return createLightTheme(palette);
    }
  
    return THEMES[platformId].themes[theme];
  }

  getFluent9Theme(platformId = this.platformId, theme = this.theme) {
    return this.getTheme(platformId, theme, true);
  }

  getFonts() {
    return {
      fonts: {
        small: {
          fontSize: '11px',
          fontFamily: 'Verdana, Geneva, sans-serif'
        },
        medium: {
          fontSize: '12px',
          fontFamily: 'Verdana, Geneva, sans-serif'
        },
        large: {
          fontSize: '20px',
          fontWeight: 'semibold',
          fontFamily: 'Verdana, Geneva, sans-serif'
        },
        xLarge: {
          fontSize: '22px',
          fontWeight: 'semibold',
          fontFamily: 'Verdana, Geneva, sans-serif'
        },
      },
    };
  }

  getDarkMode() {
    return this.getNormalizedThemeName() === "dark";
  }
};

export const ThemeContext = createContext<ThemeStore>(null);