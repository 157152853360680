export const getType = ({ cellData, column, rowStatus, columnIndex, originalRowIndex, additionalErrors }) => {
  const adv = Array.isArray(cellData);
  let type = "grid-cell";

  if (["editable", "button", "progress"].includes(column.type)) {
    type += " no-padding";
  }

  if (adv) {
    type += " linked " + cellData[2];
  }

  if (
    column.clickable === true
    && ((!adv && cellData.length) || (adv && cellData[0].length))
  ) {
    type += " clickable";
  }

  if (
    additionalErrors
    && Array.isArray(additionalErrors[originalRowIndex])
    && additionalErrors[originalRowIndex].includes(column.key)
  ) {
    type += " error";
  }

  if (rowStatus !== null) {
    if (typeof rowStatus === 'string' || rowStatus instanceof String) {
      type += (" " + rowStatus);
    }
    else {
      type += (
        " disabled" +
        (
          (columnIndex && rowStatus && Array.isArray(rowStatus)
            && rowStatus.includes(columnIndex)
          ) ? " error" : ""
        )
      );
    }
  }

  return type
}