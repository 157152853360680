import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Button, Tab, TabList } from "@fluentui/react-components";
import { Add24Regular } from "@fluentui/react-icons";

import { MainContext, Api } from "src/utils";
import { ActionBar, GridDialog } from "src/comps";

interface IAttribute {
  id?: number;
  name: string;
  text: string;
  ref_key: string;
  fields: Array<any>;
  view_permit_id: number;
  add_permit_id: number;
  edit_permit_id: number;
  delete_permit_id?: number;
  view_api?: string;
  add_api?: string;
  edit_api?: string;
  delete_api?: string;
  pickers?: any;
}

interface IProps {
  id?: string;
  title?: string;
  pageID?: string|number;
  individualSettings?: boolean;
  fields?: any;
  deletion?: boolean;
  columns?: any;
  onDismiss?: Function;
  custom?: Array<IAttribute>;
}

export default observer((props:IProps) => {
  const Store = useContext(MainContext);

  const [data, setData] = useState([]);
  const [reset, setReset] = useState(false);
  const [pageID, setPageID] = useState<string|number>(0);
  const [fields, setFields] = useState([]);
  const [current, setCurrent] = useState<IAttribute>(undefined);

  const modalName = props.id || "attributes";
  
  const getData = async (tab = current) => {
    const result = await Api.get({
      api: tab?.view_api || "attributes/get",
      data: {
        ref_key: tab?.ref_key,
        type: tab?.id || tab?.ref_key
      }
    });
    setData(result);
  };

  useEffect(() => {
    if (current?.id || current?.ref_key) {
      getData();
    }
  }, [current]);

  useEffect(() => {
    setCurrent(props.custom ? props.custom[0] : Store.getAttributes(props.pageID)[0]);
    setPageID(props.pageID);
  }, [Store.isModalOpen(modalName)]);

  useEffect(() => {
    if (props.individualSettings === true) {
      setFields(props.fields[current?.view_permit_id]);
      return;
    }
    setFields(current?.fields || props.fields);
  }, [current]);

  useEffect(() => { setReset(false); }, [data]);

  if (!Store.isModalOpen(modalName)) {
    return null;
  }

  const reload = async (rowIndex) => {
    const result = await Api.get({
      api: current?.view_api || "attributes/get",
      data: {
        id: data[rowIndex].id,
        ref_key: current?.ref_key,
        type: current?.id || current?.ref_key
      }
    });
    let newData = [...data];
    newData[rowIndex] = result[0];
    setData(newData);
  };

  const userCanEdit = () => {
    return Store.hasPrivilege(current?.edit_permit_id)
      ? true : false;
  };

  const contextMenu = (o) => {
    let row = data[o.rowIndex];

    const options = [
      {
        name: "Edit",
        icon: "Edit",
        disabled: !userCanEdit,
        fn: async () => Store.setConfirm(
          await import("./addEdit").then(({ addEdit }) => addEdit({
            store: Store,
            attribute: current,
            columns: fields,
            row,
            callback: () => reload(o.rowIndex)
          }))
        )
      }
    ];
    if (props.deletion !== false) {
      options.push({
        name: "Delete",
        disabled: row.used || !userCanEdit,
        icon: "Delete",
        fn: async () => Store.setConfirm(
          await import("./remove").then(({ remove }) => remove({
            attribute: current,
            row,
            callback: () => getData()
          }))
        )
      });
    }
    return options;
  };

  const cols = (
    (props.individualSettings === true)
      ? props.columns[current?.view_permit_id]
      : [...(current?.fields || []), ...(
        (props.custom && !current?.fields)
          ? [...props.fields, ...props.columns]
          : props.columns
      )]
  );

  const gridData = (data || []).map(row => {
    const result = [
      ...fields.map(col => {
        // momentary fix for warehouse speedy development
        switch (col.key) {
          case "inout": {
            col.key = "description";
            break;
          }
          case "active": {
            return [row.active, false, false];
          }
          case "used": {
            return [row.used, false, false];
          }
          case "insert": {
            return [row.insert_by, row.insert_date];
          }
          case "last_edit": {
            return [row.edit_by, row.edit_date];
          }
        }
        return row[col.key];
      })
    ];
    if (cols.find(col => col.key === "used")) {
      result.push([row.used, false, false]);
    }
    result.push([row.insert_by, row.insert_date]);
    result.push([row.edit_by, row.edit_date]);
    return result;
  });
  
  const options = {
    id: "attributes-" + current?.id,
    title: current?.text,
    rowStatus: (data || []).map(d => null),
    cols,
    data: gridData,
    contextMenu
  };

  const typesList = [
    ...(props.custom || []).map(attr => ({
      ...attr,
      id: attr.ref_key,
      key: attr.ref_key,
      disabled: !Store.hasPrivilege(attr.view_permit_id)
    })),
    ...Store.getAttributes(props.pageID).map(attr => ({
      ...attr,
      key: attr.id,
      disabled: !Store.hasPrivilege(attr.view_permit_id)
    }))
  ];

  const onTypeChange = (e, item) => {
    const attribute = typesList.find(attr => attr.ref_key === item.value);
    if (attribute.ref_key !== current?.ref_key) {
      setReset(true);
      setCurrent(attribute);
    }
  };

  const sidebar = (
    <TabList
      vertical
      selectedValue={ current?.ref_key }
      onTabSelect={ onTypeChange }
    >
      { typesList.map(attr => (
        <Tab
          value={ attr.ref_key }
          disabled={ attr.disabled }
        >
          { attr.name }
        </Tab>
      )) }
    </TabList>
  );

  const handleAddNewClick = async () => {
    Store.setConfirm(
      await import("./addEdit").then(({ addEdit }) => addEdit({
        store: Store,
        attribute: current,
        columns: fields,
        callback: () => getData()
      }))
    );
  };

  const header = (
    <ActionBar>
      <ActionBar.MainCommands>
        <Button
          appearance="subtle"
          icon={ <Add24Regular /> }
          disabled={ !Store.hasPrivilege(current?.add_permit_id) }
          onClick={ handleAddNewClick }
        >
          Add { current?.name?.toLowerCase() }
        </Button>
      </ActionBar.MainCommands>
    </ActionBar>
  );

  return (
    <GridDialog
      id={ props.id || "attributes" }
      title={ props.title || "Attributes" }
      options={ options }
      onDismiss={ props.onDismiss }
      reset={ reset }
      sidebar={ sidebar }
      header={ header }
    />
  );
});
