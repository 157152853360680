import { Suspense, useContext, useEffect, useState } from "react";

import { Button, Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, DialogActions, Text } from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";

import { IConfirmDialog } from "src/types";
import { MainContext } from "src/utils";
import { Form, isFormFullfilled } from "src/comps";

import { useStyles } from "./Confirm.styles";

const DialogEmpty = {
  type: "", fields: [], title: "", msg: "", action: { success: () => {} }
};

const ConfirmDialog = (props:IConfirmDialog) => {
  const Store = useContext(MainContext);
  const classes= useStyles();
  
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);

  const isDialogOpen = !props.hidden && !!props.msg;


  const updateIsValid = () => {
    setIsValid(isFormFullfilled({ 
      fields: props.fields, 
      values, 
      errors
    }));
  };

  useEffect(() => {
    updateIsValid();
  }, [values, errors]);

  useEffect(() => {
    if (isDialogOpen) {
      updateIsValid();
    }
  }, [isDialogOpen]);

  // calc dialog type and width by fields list
  let size = props.type || (((props.fields || []).length > 25) ? "big" : (
    ((props.fields || []).length > 20) ? "large" : (
      ((props.fields || []).length > 5) ? "medium" : "small"
    )
  ));
  size = size.charAt(0).toUpperCase() + size.slice(1);

  if (!Store.getConfirm().title) {
    if (Object.values(values).length) {
      setValues({});
      setErrors({});
    }
    return null;
  }

  const onSend = (e) => {
    if (props.action.success) {
      props.action.success(values);
    }
    setValues({});
    setErrors({});
    Store.setConfirm(DialogEmpty);
  };

  const onDismiss = () => {
    Store.clearConfirm();
    setValues({});
    setErrors({});
    if (props.onDismiss) {
      props.onDismiss();
    }
  };

  const CloseButton = props.isCritical ? null : (
    <DialogTrigger action="close">
      <Button appearance="subtle" aria-label="close" icon={<Dismiss24Regular />} />
    </DialogTrigger>
  );

  return (
    <Suspense fallback={<div />}>
      <Dialog
        open={ (!props.hidden && props.msg) ? true : false }
        onOpenChange={ onDismiss }
      >
        <DialogSurface
          className={ classes["modalContainer" + size] }
        >
          <DialogBody className={ classes.modalBody }>
            <DialogTitle
              className={ classes.modalTitle }
              action={ CloseButton }
            >
              { props.title }
            </DialogTitle>
            <DialogContent
              className={ classes.modalContent }
              onKeyDown={ (e) => {
                // send on enter key pressed (optional)
                if (props.sendOnEnter === true && e.key === "Enter") {
                  onSend(e);
                }
              }}
            >
              <Text>{ props.msg }</Text>
              <Form
                fields={ props.fields }
                focusKey={ props.focusKey }
                defaultValues={ values }
                defaultErrors={ errors }
                onValuesChange={ setValues }
                onErrorsChange={ setErrors }
                onButtonClick={ updateIsValid }
              />
            </DialogContent>
            <DialogActions>
              { (props.sendOnEnter === true) ? (
                <Text>Press <b>Enter</b> to proceed</Text>
              ) : null }
              { (props.enableSequenceMode === true) ? (
                <Button
                  disabled={ !isValid }
                  onClick={ (e) => {
                    if (props.action.success) {
                      props.action.success(values);
                      Store.setConfirm(DialogEmpty);
                    }
                    if (props.action.onSequence) {
                      setValues({});
                      setErrors({});
                      props.action.onSequence({ values });
                    }
                  } }
                >
                  { props.sequenceBtnText || "Save and continue" }
                </Button>
              ) : null }
              { (props.hideActionBtn === true) ? null : (
                <Button
                  appearance="primary"
                  onClick={ onSend }
                  disabled={ !isValid }
                >
                  { (props.type == "confirm")
                    ? "Yes" : (props.actionBtnText || "Proceed")
                  }
                </Button>
              ) }
              { (props.hideDiscardBtn === true) ? null : (
                  !props.isBlocking && (
                    <Button
                      onClick={ () => {
                        if (props.action.failure) {
                          props.action.failure(values);
                        }
                        setValues({});
                        setErrors({});
                        Store.setConfirm(DialogEmpty);
                      } }
                    >
                      { 
                        (props.type == "confirm") ? "No" : 
                        (props.discardBtnText || "Discard")
                      }
                    </Button>
                  )
              )}
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </Suspense>
  );
};

export { ConfirmDialog as Dialog, DialogEmpty };
