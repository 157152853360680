import { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { Button } from "@fluentui/react-components";
import { Add24Regular } from "@fluentui/react-icons"

import { MainContext, Api } from "src/utils";
import { ActionBar, GridDialog } from "src/comps";

import { cols } from "./_columns";

const editCertificationLevelPrivilegeID = 701;
const forceEditPrivilegeID = 1027;

interface Props {
  callback?: Function;
  selPN?: number;
}

export const PNCertificationLevelModal = observer((props:Props) => {
  const Store = useContext(MainContext);

  const [selPNcode, setSelPNcode] = useState(0);
  const [data, setData] = useState([]);
  
  // 0 = not locked to anyone, 1 = locked without force, 2 = locked to anyone
  const [editLockLevel, setEditLockLevel] = useState(0);

  const actionsDisabled = (
    (
      !Store.hasPrivilege(editCertificationLevelPrivilegeID)
      || editLockLevel > 0
    )
    && !(
      Store.hasPrivilege(forceEditPrivilegeID)
      && editLockLevel == 1
    )
  );

  const getData = async () => {
    const result = await Api.get({
      api: "pn/get-certification-level",
      data: { pn_id: props.selPN }
    });
    setSelPNcode(result.pnCode);
    setData(result.data);
    setEditLockLevel(result.locked);
  };

  useEffect(() => { getData(); }, [props.selPN]);

  const actionsCallback = () => {
    getData();
    if (props.callback) {
      props.callback();
    }
  };

  const contextMenu = ({ rowIndex }) => {
    const row = data[rowIndex];
    return [
      {
        name: "Delete",
        icon: "Delete",
        disabled: actionsDisabled,
        fn: () => Store.setConfirm({
          title: "Delete",
          msg: "Are you sure you would like to remove this certification level?",
          action: { 
            success: () => {
              Api.get({
                api: "pn/delete-certification-level",
                data: {
                  pn_id: row.pn_id,
                  certification_level_id: row.certification_level_id
                },
                msg: "Removed"
              }).then(actionsCallback)
            }
          }
        })
      }
    ];
  };

  const options = {
    title: selPNcode,
    id: "certification-level",
    data: (data || []).map(row => Object.values(row)),
    rowStatus: (data || []).map(d => null),
    cols,
    contextMenu
  };

  const add = async () => {
    const levels = await Api.get({
      api: "pn/get-certification-levels-list"
    });

    Store.setConfirm({
      title: "Add",
      msg: "Add a new certification level to the pn " + selPNcode,
      fields: [
        {
          key: "certification_level_id",
          label: "Certification level",
          type: "select",
          options: levels.map(l => ({ value: l.id, label: l.title })),
          required: true
        }
      ],
      action: {
        success: (values) => {
          Api.get({
            api: "pn/add-certification-level",
            data: {
              pn_id: props.selPN,
              certification_level_id: values.certification_level_id.value
            },
            msg: "Added"
          }).then(actionsCallback)
        }
      }
    });
  };

  const header = (
    <ActionBar>
      <ActionBar.MainCommands>
        <Button
          appearance="subtle"
          icon={ <Add24Regular /> }
          disabled={ actionsDisabled }
          onClick={ add }
        >
          Add
        </Button>
      </ActionBar.MainCommands>
    </ActionBar>
  );

  return (
    <GridDialog
      id="certification-level"
      size={ "medium" }
      title={ "Certification level" }
      options={ options }
      header={ header }
    />
  );
});