import { makeStyles, shorthands } from "@fluentui/react-components";

const modalContainer = {
};

export const useStyles = makeStyles({
  modalContainerBig: {
    width: "92% !important",
    maxWidth: "92% !important",
    ...modalContainer
  },
  modalContainerSmall: {
    width: "350px !important",
    maxWidth: "350px !important",
    ...modalContainer
  },
  modalContainerMedium: {
    width: "880px !important",
    maxWidth: "880px !important",
    ...modalContainer
  },
  modalContainerLarge: {
    width: "1150px !important",
    maxWidth: "1150px !important",
    ...modalContainer
  },
  modalContainerDefault: {
    width: "450px !important",
    maxWidth: "450px !important",
    ...modalContainer
  },
  modalTitle: {
    ...shorthands.padding("10px", "10px", "10px", "10px"),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("10px"),
    ...shorthands.padding("0px", "10px", "15px", "10px"),
    ...shorthands.overflow("auto")
  },
  modalBody: {
    width: "100%",
    maxWidth: "100%"
  }
});