import { useState, useEffect, useRef, useContext, Suspense } from "react";
import { observer } from "mobx-react-lite";

import { Api, MainContext } from "src/utils";
import { Persona, Select } from "src/comps";

import {
  Card,
  CardFooter,
  CardHeader,
  Button,
  Text,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Tooltip,
  Badge,
  MessageBar,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  Eye16Regular,
  ArrowDownload24Regular,
  Delete24Regular,
  Eye24Regular,
  Link24Regular,
} from "@fluentui/react-icons";

import { useStyles } from "./Attachments.styles";
import { AttachmentsFile, IProps } from "./Attachments.types";

export default observer((props: IProps) => {
  const Store = useContext(MainContext);
  const classes = useStyles();

  const [attachments, setAttachments] = useState([]);
  const [refID, setRefID] = useState<string | number>(0);
  const [file, setFile] = useState<AttachmentsFile>({ name: "" });
  const [uploadError, setUploadError] = useState("");

  // language selection (optional)
  const [languageOptions, setLanguageOptions] = useState([]);
  const [selLanguage, setSelLanguage] = useState(undefined);
  const [selFilterLanguage, setSelFilterLanguage] = useState(undefined);

  const fileInputRef = useRef(null);

  // modal opening logic
  const modalName = props.id || "attachments";
  useEffect(() => {
    if (!Store.isModalOpen(modalName)) {
      return;
    }
    setFile({ name: "" });
    get();
  }, [Store.isModalOpen(modalName)]);

  const get = async (language = selFilterLanguage) => {
    // get language options if requested (and not read-only)
    if (
      props.languageSelection &&
      !languageOptions.length &&
      (!props.readOnly || props.languageFilter)
    ) {
      const options = await Api.get({
        api: "data-module/get-attributes",
        data: { type: "file-language-labels" },
      });
      setLanguageOptions(
        options.map((opt) => ({
          value: opt.suffix,
          label: opt.suffix + " - " + opt.label,
        }))
      );
    }

    let data;
    // allow custom get function
    if (props.get) {
      data = await props.get();
    } else {
      data = await Api.get({
        api: "uploads/get",
        data: {
          ref_id: props.refID,
          type: props.type,
          language,
        },
      });
    }
    setAttachments(data);
  };

  useEffect(() => {
    if (props.onGet) {
      props.onGet(attachments);
    }
  }, [attachments]);

  useEffect(() => {
    if (Store.isModalOpen(modalName)) {
      if (props.refID && refID != props.refID) {
        setRefID(props.refID);
        get();
      }
    }
  });

  if (!Store.isModalOpen(modalName)) {
    return null;
  }

  let no_attachments =
    !attachments || !attachments.length ? (
      <div className={classes.noFileFoundMessage}>No file found</div>
    ) : null;

  const remove = (upload_id) => {
    if (props.remove) {
      props.remove(upload_id).then(() => get());
    } else {
      Api.get({
        api: "uploads/remove",
        data: { ref_id: props.refID, upload_id },
        msg: "File removed",
      }).then(() => {
        if (props.onRemove) {
          props.onRemove();
        }
        get();
      });
    }
  };

  const onDismiss = () => Store.closeModal(true);

  const handleUploadClick = () => {
    // generate form data to be sent
    const formData = new FormData();
    formData.append("fileInput", file as Blob, file.name);

    // check file maximum size
    if (file.size > 100000000) {
      setUploadError("File size too big. Max 100 MB");
      return;
    }
    if (file.size < 1) {
      setUploadError("The file you are trying to upload is empty.");
      return;
    }

    setUploadError("");

    // upload file
    Api.get({
      type: "upload",
      api:
        props.upload ||
        "uploads/add&ref_id=" +
          props.refID +
          "&type=" +
          props.type +
          (props.languageSelection ? "&lang=" + selLanguage : ""),
      data: formData,
      failure: props.onUploadFail,
      msg: props.uploadMsg || "File added",
    }).then((data) => {
      if (props.onSuccess) {
        props.onSuccess(data);
      }
      get();
      setFile({ name: "" });
      fileInputRef.current.value = "";
    });
  };

  return (
    <Suspense>
      <Dialog open={true} onOpenChange={onDismiss}>
        <DialogSurface className={classes.modalContainer}>
          <DialogBody className={classes.modalBody}>
            <DialogTitle
              className={classes.modalTitle}
              action={
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              }
            >
              {props.title || "Attachments"}
            </DialogTitle>
            <DialogContent className={classes.modalContent}>
              {props.languageFilter && (
                <Select
                  options={[{ value: 0, label: "All" }, ...languageOptions]}
                  placeholder={"Filter by file language..."}
                  defaultValue={{ value: selFilterLanguage }}
                  isClearable={false}
                  style={{ width: "250px", marginBottom: 15 }}
                  onChange={(option) => {
                    setSelFilterLanguage(option.value);
                    get(option.value);
                  }}
                />
              )}
              {props.customBefore}
              {props.disableNoResultMsg ? null : no_attachments}
              {attachments.map((a) => {
                const disabled =
                  props.readOnly ||
                  (props.allowRemove == "onlyIfAuthor" &&
                    a.author != Store.getUser().id &&
                    // support for non-ID authors (names)
                    (a.author == +a.author ||
                      Store.getUser().id !=
                        Store.getUserFromName(a.author).id));

                // allow view mode if PDF
                let ext = a.file.split("."),
                  canBeViewed = false;
                ext = ext[ext.length - 1];
                if (ext.toLowerCase() === "pdf") {
                  canBeViewed = true;
                }
                const handleViewClick = () => {
                  Api.download({
                    api: "uploads/download",
                    data: {
                      upload_id: a.id,
                    },
                    msg: "Attachment download started",
                  });
                };

                const handleDeleteClick = disabled
                  ? undefined
                  : () =>
                      Store.setConfirm({
                        title: "Delete",
                        msg: "Are you sure you would like to remove this file?",
                        action: { success: () => remove(a.id) },
                      });

                const handleDownloadClick = () => {
                  if (props.download) {
                    props.download(a);
                  } else {
                    Api.download({
                      api: "uploads/download",
                      filename: a.file,
                      data: {
                        upload_id: a.id,
                      },
                      msg: "Attachment download started",
                    });
                  }
                };

                const handleLinkClick = () =>
                  Store.copyToClipboard(
                    "https://dwa.drass.it/app/src/api/request.php?api=file-download/pn-attachment&pn=" +
                      props.pn +
                      "&url=" +
                      a.file
                  );

                const actions = (
                  <>
                    {props.allowPNLinkCopy ? (
                      <Tooltip
                        withArrow
                        relationship="description"
                        content="Copy download link"
                      >
                        <Button
                          appearance="subtle"
                          onClick={handleLinkClick}
                          icon={<Link24Regular />}
                          aria-label="Copy download link"
                        />
                      </Tooltip>
                    ) : null}
                    {canBeViewed ? (
                      <Tooltip
                        withArrow
                        relationship="description"
                        content="View file"
                      >
                        <Button
                          appearance="subtle"
                          onClick={handleViewClick}
                          icon={<Eye24Regular />}
                          aria-label="Preview"
                        />
                      </Tooltip>
                    ) : null}
                    {props.allowRemove ? (
                      <Tooltip
                        withArrow
                        relationship="description"
                        content="Delete file"
                      >
                        <Button
                          appearance="subtle"
                          onClick={handleDeleteClick}
                          icon={<Delete24Regular />}
                          disabled={disabled}
                          aria-label="Delete"
                        />
                      </Tooltip>
                    ) : null}
                    <Tooltip
                      withArrow
                      relationship="description"
                      content="Download file"
                    >
                      <Button
                        appearance="subtle"
                        onClick={handleDownloadClick}
                        icon={<ArrowDownload24Regular />}
                        aria-label="Download"
                      />
                    </Tooltip>
                  </>
                );

                return (
                  <Card size="small" role="listitem" className={classes.card}>
                    <CardHeader
                      header={<Text weight="semibold">{a.file}</Text>}
                      action={
                        <Badge appearance="ghost">
                          <Eye16Regular />
                          &nbsp;{a.views}
                        </Badge>
                      }
                    />
                    <CardFooter action={actions}>
                      <Persona user={a.author} secondaryText={a.date} />
                    </CardFooter>
                  </Card>
                );
              })}
              {props.customAfter}
            </DialogContent>

            {uploadError ? (
              <MessageBar intent="error">{uploadError}</MessageBar>
            ) : null}

            {!props.readOnly &&
            (!props.limit || props.limit > attachments.length) ? (
              <DialogActions>
                {props.languageSelection && languageOptions.length ? (
                  <Select
                    options={languageOptions}
                    placeholder={"Language..."}
                    defaultValue={{ value: selLanguage }}
                    isClearable={false}
                    style={{ width: "105px" }}
                    onChange={(option) => setSelLanguage(option.value)}
                  />
                ) : null}

                <input
                  ref={fileInputRef}
                  type="file"
                  name="fileInput"
                  id="fileInput"
                  accept={props.accept || undefined}
                  onChange={(event) => setFile(event.target.files[0])}
                />

                <Button
                  appearance="primary"
                  type="submit"
                  onClick={handleUploadClick}
                  disabled={
                    !file.name || (props.languageSelection && !selLanguage)
                  }
                >
                  Upload
                </Button>
              </DialogActions>
            ) : null}
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </Suspense>
  );
});
