// on check change props fallback for parent component actions
// this function is called each time a checkbox is toggled
function onCheck(checksMap, onCheckChange) {
  if (onCheckChange) {
    onCheckChange(checksMap);
  }
}

// handling checkboxes (not the ones on the first col on selectable rows)
function checkCell({ columnIndex, rowIndex, status = undefined, checksMap }) {
  // calculate the target status or use the one described as a parameter if it
  // is setted and not defaulted to undefined
  if (status === undefined) {
    status = (checksMap.rowChecks[columnIndex][rowIndex] ? false : true);
  }

  // actually toggle the check
  if (!checksMap.rowChecks[columnIndex]) {
    checksMap.rowChecks[columnIndex] = [];
  }
  checksMap.rowChecks[columnIndex][rowIndex] = status;

  // on a cell untoggle, the relative row and col master checks should be
  // untoggled too. Also the master toggle should be setted as off
  if (!status) {
    checksMap.checks[rowIndex] = false;
    checksMap.checkAllInCol[columnIndex] = false;
    checksMap.checkAll = false;
  }
  return checksMap;
}

// handling of checkboxes on the first column of each row
function checkRow({ rowIndex, cols, checksMap, data }) {
  const target = (checksMap.checks[rowIndex] ? false : true);

  // mark row master check as checked
  checksMap.checks[rowIndex] = target;

  // check all row checkable columns
  for (let col = 0; col < cols.length; col++) {
    if (cols[col].selectable === true) {
      // if the cell is uncheckable
      if (!data[rowIndex][col]) continue;

      // otherwise check the cell
      Object.assign(checksMap, checkCell({
        columnIndex: col, rowIndex, status: target, checksMap
      }));
    }
    if (!target) {
      checksMap.checkAllInCol[col] = false;
    }
  }
  if (!target) {
    checksMap.checkAll = false;
  }
  return checksMap;
}

// function to toggle all the available checks in a determined column
function checkCol({ col, status = undefined, data, rowStatus, checksMap }) {
  // get and update the col master check toggle
  if (!status) status = !checksMap.checkAllInCol[col];
  checksMap.checkAllInCol[col] = status;

  // for each row toggle as target status
  for (let i = 0; i < data.length; i++) {
    // if the iterated row is checkable
    if (rowStatus[i] === null && data[i][col]) {
      // check the cell
      Object.assign(checksMap, checkCell({
        columnIndex: col, rowIndex: i, status, checksMap
      }));
    }
  }
  return checksMap;
}

// function to toggle checkboxes of the entire table without using inputs
// (toggle the check to a target status only if non-disabled)
function checkAll({ cols, data, rowStatus, checksMap }) {
  const status = !checksMap.checkAll;
  
  // check all master rows checks
  for (let i = 0; i < data.length; i++) {
    if (rowStatus[i] === null) {
      checksMap.checks[i] = status;
    }
  }

  // toggle every column and row (this is the "master check")
  for (let col = 0; col < cols.length; col++) {
    if (cols[col].selectable === true) {
      Object.assign(checksMap, checkCol({
        col, status, data, rowStatus, checksMap
      }));
    }
  }
  checksMap.checkAll = status;
  return checksMap;
}

export { onCheck, checkCell, checkRow, checkCol, checkAll };
