import { makeStyles, shorthands } from "@fluentui/react-components";

const modalContainer = {
  height: "90%",
  ...shorthands.padding("0px"),
};

export const useStyles = makeStyles({
  modalContainerLarge: {
    width: "90% !important",
    maxWidth: "90% !important",
    ...modalContainer
  },
  modalContainerMediumLarger: {
    width: "1000px !important",
    maxWidth: "1000px !important",
    ...modalContainer
  },
  modalContainerMediumLarge: {
    width: "800px !important",
    maxWidth: "800px !important",
    ...modalContainer
  },
  modalContainerMedium: {
    width: "500px !important",
    maxWidth: "500px !important",
    ...modalContainer
  },
  modalTitle: {
    ...shorthands.padding("10px", "10px", "10px", "10px"),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
  },
  modalBody: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
  },
  header: {
    width: "100%",
    ...shorthands.padding("0px", "0px", "5px", "0px"),
  }
});