import { memo } from "react";
import { Stack, Label, TagPicker, ITagPickerProps } from "@fluentui/react";

interface IProps extends ITagPickerProps {
  label?: string;
}

export default memo((props:IProps) => {
  const fieldProps:IProps = {
    ...props,
    styles: {
      text: { borderColor: "#ddd" },
      ...(props.styles || {})
    },
  };
  
  if (props.label) {
    return (
      <Stack
        horizontal
        tokens={{ childrenGap: 10 }}
      >
        { props.label ? (
          <Label styles={{ root: { marginTop: 5 } }}>
            { props.label }
          </Label>
        ) : null }
        <TagPicker { ...fieldProps } />
      </Stack>
    );
  }
  
  return <TagPicker { ...fieldProps } />;
});
