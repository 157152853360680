import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
  cell: {
    display: "flex",
    flexDirection: "row",
    width: "100%"
  },
  text: {
    ...shorthands.overflow("hidden"),
    width: "auto",
    ...shorthands.padding("0px", "10px", "0px", "0px"),
    display: "block"
  },
  tooltipContent: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "600px",
    maxHeight: "400px",
    ...shorthands.overflow("auto"),
    ...shorthands.gap("10px")
  },
  tooltipButton: {
    ...shorthands.padding("0"),
  },
  tooltipCopyButton: {

  }
});

export const archiveIcon = {
  width: 12,
  backgroundColor: "var(--danger-color)",
  borderRadius: 2,
  fontSize: 8,
  padding: "1px 2px",
  color: "black"
};