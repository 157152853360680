import { memo } from "react";
import { Dropdown, IDropdownProps } from "@fluentui/react";

export default memo((props:IDropdownProps) => {
  const fieldProps = {
    ...props,
    styles: {
      root: { borderColor: "#ddd" },
      ...(props.styles || {})
    },
  };
  return (
    <Dropdown { ...fieldProps } />
  );
});
