export default {
  isLoggedIn: undefined,
  isDataRetrieved: false,

  // current select elements
  selPlatform: { theme_color: "", theme: "", pageTitle: "" },
  selApp: "",
  selSection: "",
  selSectionID: 0,
  selModal: [],
  selModalProps: {},

  // display modes
  navIsReduced: false,
  displayMobileNav: false,
  videoHelp: [],
  darkMode: false,

  // notifications
  notifCount: { total: 0, unread: 0 },

  showVideoHelpCenter: false,

  // attributes
  attributes: {}
};
