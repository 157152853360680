import { createRoot } from "react-dom/client";

import { HashRouter as Router } from "react-router-dom";

import { configure } from "mobx";
configure({
  enforceActions: "never",
});

import { registerSW } from "virtual:pwa-register";
registerSW({ immediate: true });

import App from "./App";
import { MainContext, MainStore } from "src/utils";
import { ThemeContext, ThemeStore } from "./utils/themes";

// render app
const container = document.getElementById("app");
const root = createRoot(container!);
root.render(
  <ThemeContext.Provider value={ new ThemeStore() }>
    <MainContext.Provider value={ MainStore }>
      <Router>
        <App />
      </Router>
    </MainContext.Provider>
  </ThemeContext.Provider>
);

// fluentui icons
import { registerIcons } from "@fluentui/react/lib/Styling";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import {
  HomeIcon, ToolboxIcon, TagIcon, TableGroupIcon, SingleBookmarkIcon, PrintIcon,
  QRCodeIcon, PageHeaderEditIcon, OrgIcon, ManufacturingIcon, LineStyleIcon,
  DocumentSetIcon, CheckMarkIcon, CannedChatIcon, BookAnswersIcon, FilterIcon,
  AccountManagementIcon, AddIcon, AddFriendIcon, AddGroupIcon, ArchiveIcon,
  AssessmentGroupIcon, AttachIcon, BackIcon, BarChartHorizontalIcon, SortUpIcon,
  BranchMergeIcon, BulletedList2Icon, BulletedTreeListIcon, BoldIcon, PhoneIcon,
  BullseyeTargetEditIcon, BuildDefinitionIcon, CancelIcon, CaretSolidDownIcon,
  CaretSolidUpIcon, ChevronDownIcon, ChevronDownMedIcon, ChevronLeftMedIcon,
  ChevronRightMedIcon, ChromeCloseIcon, CirclePlusIcon, ClearFilterIcon, StopIcon,
  ColorIcon, CollapseMenuIcon, ContactInfoIcon, CopyIcon, ResetIcon,
  DateTimeIcon, DeleteIcon, DiamondIcon, DownloadIcon, DuplicateRowIcon,
  EditIcon, EntryViewIcon, FastForwardIcon, FavoriteStarFillIcon, SortDownIcon,
  FileRequestIcon, FileSymlinkIcon, FilterSettingsIcon, FlowChartIcon, PDFIcon,
  ForwardIcon, GroupIcon, HelpIcon, HistoryIcon, HourGlassIcon, InboxCheckIcon,
  InfoIcon, InsertSignatureLineIcon, LinkIcon, LockIcon, MailIcon, RedEyeIcon,
  MessageFillIcon, MiniContractIcon, MiniExpandIcon, MoreIcon, MoveIcon,
  NavigateExternalInlineIcon, NumberSymbolIcon, OfficeChatIcon, PlainTextIcon,
  PlayIcon, PeopleIcon, PhotoCollectionIcon, QuickNoteIcon, ReceiptCheckIcon,
  RefreshIcon, SaveIcon, SearchIcon, SecurityGroupIcon, SendIcon,
  SettingsIcon, SignOutIcon, CollapseContentSingleIcon, SwitchIcon, MoneyIcon,
  TaskManagerIcon, UndoIcon, UnlockIcon, UserOptionalIcon, ViewIcon, FrontCameraIcon,
  WarningIcon, WarningSolidIcon, WifiWarning4Icon, FolderHorizontalIcon,
  AnalyticsReportIcon, ProductionFloorManagementIcon, CalendarIcon, DownIcon,
  TeamworkIcon, TeamFavoriteIcon, AlertSettingsIcon, UpIcon, OpenSourceIcon, 
  PackagesIcon, AutoRacingIcon, CommentUrgentIcon, ListIcon, DocumentationIcon,
  ReleaseGateErrorIcon, InstallationIcon, TicketIcon, FeedbackRequestSolidIcon,
  OpenFolderHorizontalIcon, ErrorBadgeIcon, FavoriteStarIcon, ChevronRightIcon,
  CompletedIcon, OpenFileIcon, CellPhoneIcon, ExportIcon, ClearIcon, PasteIcon,
  FullCircleMaskIcon, PlaybackRate1xIcon, TextDocumentIcon, BranchCompareIcon,
  SearchAndAppsIcon, ComplianceAuditIcon, OnboardingIcon, GoToDashboardIcon,
  IDBadgeIcon, ShoppingCartIcon, DictionaryIcon, ReportDocumentIcon, CityNextIcon,
  FormLibraryIcon, TestPlanIcon, ShareIcon, VisualsStoreIcon, RemoteIcon,
  FieldRequiredIcon, OfflineStorageIcon, CompanyDirectoryIcon, FolderListIcon,
  AirplaneIcon, MessageIcon, DoubleColumnEditIcon, HardDriveUnlockIcon,
  BusinessHoursSignIcon, Header1Icon, Header2Icon, Header3Icon, Header4Icon,
  StrikethroughIcon, EngineeringGroupIcon, ViewDashboardIcon, HomeGroupIcon,
  DesignIcon, DataManagementSettingsIcon, PlugConnectedIcon, LaptopSecureIcon,
  InsertTextBoxIcon, BulkUploadIcon, ContactLinkIcon, TemporaryUserIcon, ProgressLoopOuterIcon,
  InternalInvestigationIcon, WavingHandIcon, TransitionIcon, DietPlanNotebookIcon
} from "@fluentui/react-icons-mdl2";

registerIcons({
  icons: {
    Up: <UpIcon />,
    Down: <DownIcon />,
    Toolbox: <ToolboxIcon />,
    Tag: <TagIcon />,
    TableGroup: <TableGroupIcon />,
    SingleBookMark: <SingleBookmarkIcon />,
    QRCode: <QRCodeIcon />,
    PageHeaderEdit: <PageHeaderEditIcon />,
    Org: <OrgIcon />,
    Manufacturing: <ManufacturingIcon />,
    LineStyle: <LineStyleIcon />,
    Home: <HomeIcon />,
    DocumentSet: <DocumentSetIcon />,
    CheckMark: <CheckMarkIcon />,
    CannedChat: <CannedChatIcon />,
    Dictionary: <DictionaryIcon />,
    BookAnswers: <BookAnswersIcon />,
    FabricUserFolder: <FolderHorizontalIcon />,
    OpenFolderHorizontal: <OpenFolderHorizontalIcon />,
    AnalyticsReport: <AnalyticsReportIcon />,
    ProductionFloorManagement: <ProductionFloorManagementIcon />,
    Calendar: <CalendarIcon />,
    Teamwork: <TeamworkIcon />,
    TeamFavorite: <TeamFavoriteIcon />,
    TextDocument: <TextDocumentIcon />,
    Admin: <AlertSettingsIcon />,
    FlowChart: <FlowChartIcon />,
    EntryView: <EntryViewIcon />,
    OpenSource: <OpenSourceIcon />,
    Packages: <PackagesIcon />,
    AutoRacing: <AutoRacingIcon />,
    ReleaseGateError: <ReleaseGateErrorIcon />,
    Installation: <InstallationIcon />,
    Ticket: <TicketIcon />,
    FeedbackRequestSolid: <FeedbackRequestSolidIcon />,
    AccountManagement: <AccountManagementIcon />,
    Add: <AddIcon />,
    AddFriend: <AddFriendIcon />,
    AddGroup: <AddGroupIcon />,
    Airplane: <AirplaneIcon />,
    Archive: <ArchiveIcon />,
    AssessmentGroup: <AssessmentGroupIcon />,
    Attach: <AttachIcon />,
    Back: <BackIcon />,
    BarChartHorizontal: <BarChartHorizontalIcon />,
    BranchMerge: <BranchMergeIcon />,
    BuildDefinition: <BuildDefinitionIcon />,
    BulletedList2: <BulletedList2Icon />,
    BulletedTreeList: <BulletedTreeListIcon />,
    BullseyeTargetEdit: <BullseyeTargetEditIcon />,
    BulkUpload: <BulkUploadIcon />,
    BusinessHoursSign: <BusinessHoursSignIcon />,
    BranchCompare: <BranchCompareIcon />,
    Cancel: <CancelIcon />,
    CaretSolidDown: <CaretSolidDownIcon />,
    CaretSolidUp: <CaretSolidUpIcon />,
    CellPhone: <CellPhoneIcon />,
    ChevronDown: <ChevronDownIcon />,
    ChevronDownMed: <ChevronDownMedIcon />,
    ChevronLeftMed: <ChevronLeftMedIcon />,
    ChevronRight: <ChevronRightIcon />,
    ChevronRightMed: <ChevronRightMedIcon />,
    ChromeClose: <ChromeCloseIcon />,
    CirclePlus: <CirclePlusIcon />,
    CityNext: <CityNextIcon />,
    ClearFilter: <ClearFilterIcon />,
    Clear: <ClearIcon />,
    CloudImportExport: <DownloadIcon />,
    Color: <ColorIcon />,
    CollapseMenu: <CollapseMenuIcon />,
    CommentUrgent: <CommentUrgentIcon />,
    CompanyDirectory: <CompanyDirectoryIcon />,
    Completed: <CompletedIcon />,
    ComplianceAudit: <ComplianceAuditIcon />,
    ContactInfo: <ContactInfoIcon />,
    ContactLink: <ContactLinkIcon />,
    Copy: <CopyIcon />,
    DataManagementSettings: <DataManagementSettingsIcon />,
    DateTime: <DateTimeIcon />,
    Delete: <DeleteIcon />,
    Design: <DesignIcon />,
    Diamond: <DiamondIcon />,
    DietPlanNotebook: <DietPlanNotebookIcon />,
    Documentation: <DocumentationIcon />,
    DoubleColumnEdit: <DoubleColumnEditIcon />,
    Download: <DownloadIcon />,
    DuplicateRow: <DuplicateRowIcon />,
    Edit: <EditIcon />,
    EngineeringGroup: <EngineeringGroupIcon />,
    Export: <ExportIcon />,
    ErrorBadge: <ErrorBadgeIcon />,
    FastForward: <FastForwardIcon />,
    FavoriteStar: <FavoriteStarIcon />,
    FavoriteStarFill: <FavoriteStarFillIcon />,
    FieldRequired: <FieldRequiredIcon />,
    FileRequest: <FileRequestIcon />,
    FileSymlink: <FileSymlinkIcon />,
    Filter: <FilterIcon />,
    FilterSettings: <FilterSettingsIcon />,
    FolderList: <FolderListIcon />,
    FormLibrary: <FormLibraryIcon />,
    Forward: <ForwardIcon />,
    FrontCamera: <FrontCameraIcon />,
    FullCircleMask: <FullCircleMaskIcon />,
    GoToDashboard: <GoToDashboardIcon />,
    Group: <GroupIcon />,
    HardDriveUnlock: <HardDriveUnlockIcon />,
    Header1: <Header1Icon />,
    Header2: <Header2Icon />,
    Header3: <Header3Icon />,
    Header4: <Header4Icon />,
    Help: <HelpIcon />,
    History: <HistoryIcon />,
    HomeGroup: <HomeGroupIcon />,
    HourGlass: <HourGlassIcon />,
    IDBadge: <IDBadgeIcon />,
    InboxCheck: <InboxCheckIcon />,
    Info: <InfoIcon />,
    InsertSignatureLine: <InsertSignatureLineIcon />,
    InsertTextBox: <InsertTextBoxIcon />,
    InternalInvestigation: <InternalInvestigationIcon />,
    LaptopSecure: <LaptopSecureIcon />,
    Link: <LinkIcon />,
    List: <ListIcon />,
    Lock: <LockIcon />,
    Mail: <MailIcon />,
    MessageFill: <MessageFillIcon />,
    Message: <MessageIcon />,
    MiniContract: <MiniContractIcon />,
    MiniExpand: <MiniExpandIcon />,
    Money: <MoneyIcon />,
    More: <MoreIcon />,
    Move: <MoveIcon />,
    VideoHelp: <PlaybackRate1xIcon />,
    NavigateExternalInline: <NavigateExternalInlineIcon />,
    NumberSymbol: <NumberSymbolIcon />,
    OfficeChat: <OfficeChatIcon />,
    OfflineStorage: <OfflineStorageIcon />,
    Onboarding: <OnboardingIcon />,
    PlainText: <PlainTextIcon />,
    Paste: <PasteIcon />,
    Play: <PlayIcon />,
    Pdf: <PDFIcon />,
    People: <PeopleIcon />,
    Phone: <PhoneIcon />,
    PhotoCollection: <PhotoCollectionIcon />,
    PlugConnected: <PlugConnectedIcon />,
    ProgressLoopOuter: <ProgressLoopOuterIcon />,
    OpenFile: <OpenFileIcon />,
    QuickNote: <QuickNoteIcon />,
    ReceiptCheck: <ReceiptCheckIcon />,
    RedEye: <RedEyeIcon />,
    Refresh: <RefreshIcon />,
    Remote: <RemoteIcon />,
    ReportDocument: <ReportDocumentIcon />,
    Reset: <ResetIcon />,
    Save: <SaveIcon />,
    Search: <SearchIcon />,
    SearchAndApps: <SearchAndAppsIcon />,
    SecurityGroup: <SecurityGroupIcon />,
    Send: <SendIcon />,
    Settings: <SettingsIcon />,
    Share: <ShareIcon />,
    ShoppingCart: <ShoppingCartIcon />,
    SignOut: <SignOutIcon />,
    SortUp: <SortUpIcon />,
    SortDown: <SortDownIcon />,
    SkypeCircleMinus: <CollapseContentSingleIcon />,
    Stop: <StopIcon />,
    Strikethrough: <StrikethroughIcon />,
    Print: <PrintIcon />,
    Switch: <SwitchIcon />,
    TaskManager: <TaskManagerIcon />,
    TemporaryUser: <TemporaryUserIcon />,
    TestPlan: <TestPlanIcon />,
    Transition: <TransitionIcon />,
    Undo: <UndoIcon />,
    Unlock: <UnlockIcon />,
    UserOptional: <UserOptionalIcon />,
    View: <ViewIcon />,
    VisualsStore: <VisualsStoreIcon />,
    Warning: <WarningIcon />,
    WarningSolid: <WarningSolidIcon />,
    WavingHand: <WavingHandIcon />,
    ViewDashboard: <ViewDashboardIcon />,
    WifiWarning4: <WifiWarning4Icon />,
    Bold: <BoldIcon />
  }
});

initializeIcons();