import { makeStyles, shorthands } from "@fluentui/react-components";

export const useStyles = makeStyles({
  modalContainer: {
    width: "500px !important",
    maxWidth: "500px !important",
    height: "auto",
    maxHeight: "500px"
  },
  modalTitle: {
    ...shorthands.padding("10px", "10px", "10px", "10px"),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("15px"),
    ...shorthands.padding("8px"),
    ...shorthands.overflow("hidden", "auto")
  },
  modalBody: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%"
  },
  card: {
    flexShrink: 0,
    ...shorthands.padding("15px", "15px", "20px", "15px")
  },
  noFileFoundMessage: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "16px",
    ...shorthands.padding("100px")
  }
});