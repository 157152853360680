// component to pick a PN, a document or a Data Module
// loads data on change to speed up the page
import { useState, useEffect, useCallback } from "react";

import { Api } from "src/utils";
import { TagPicker } from "src/comps";

import { convert } from "html-to-text";

import { renderItem, renderSuggestionItem } from "./renderItem";
import { getNameFromKey } from "./nameFromKey";
import { pickerStyles } from "./_styles";
import { api } from "./api";

// limit of suggestions to show
// TODO: this will be removed when lazy/windowed suggestions list
// rendering will be supported in this app
// (FluentUI supports getMoreResults for lazy data retrieving)
const maxSuggestions = 200;

export default (props) => {
  const [values, setValues] = useState([]);
  const [query, setQuery] = useState(undefined);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const MIN_CHARS = props.minChar || 1;

  const suggestions = {
    suggestions: results.map(res => ({ item: res, selected: false })),
    onSuggestionClick: (ev, item) => {
      const items = [...values, item];
      setValues(items);
      props.onChange((props.itemLimit > 1) ? items : (items[0] || {}))
    },
    suggestionsHeaderText: "",
    noResultsFoundText: (
      (query < MIN_CHARS)
        ? "Please type " + MIN_CHARS + " valid characters"
        : (
          isLoading
            ? "Searching..."
            : "No results"
        )
    ),
  };

  const inputProps = {
    placeholder: (
      (props.placeholder ? props.placeholder : "") +
      (
        (MIN_CHARS == 1 && props.placeholder) ? "" : (
          (props.placeholder ? " - " : "") +
          "Insert " + MIN_CHARS + " character" + (MIN_CHARS == 1 ? "" : "s") + " to search..."
        )
      )
    )
  };

  const getResults = useCallback(async (query = "", abortController = undefined) => {
    const results = await Api.get({
      api: api(props.mode),
      silent: true,
      data: { query, ...props.requestParams },
      signal: abortController?.signal
    });
    if (!results) {
      return [];
    }
    // allow custom pick creation
    if (props.allowFreeform) {
      results.unshift({ key: query, name: query });
    }
    return results.slice(0, maxSuggestions);
  }, [props.requestParams, props.mode, props.allowFreeform]);

  // update results
  // this uses abortcontroller in order to stop a request as soon as
  // the user types more characters
  useEffect(() => {
    if (query === undefined) {
      return;
    }
    const abortController = new AbortController();
    setIsLoading(true);
    getResults(query, abortController).then(results => {
      setIsLoading(false);
      setResults(results);
    });
    
    // abort on new request (user is typing more)
    return function cancel() {
      abortController.abort();
    }
  }, [query])

  // get results if at least N characters have been typed
  const getList = async (filterText) => {
    setQuery("");
    setResults([]);
    if (filterText.length >= MIN_CHARS) {
      setTimeout(() => setQuery(filterText));
    }
    return [];
  };

  const setSelected = async (selected) => {
    const isArray = Array.isArray(selected);
    
    // if requested get name of currently selected key(s) first
    if (props.getNameFromKey) {
      selected = await getNameFromKey(selected, api(props.mode), props.requestParams);
    }

    setValues(
      (isArray ? selected : (
        selected.key ? [selected] : values
      ))
    );
  };

  // keep in sync with values passed by props
  useEffect(() => {
    if (props.selected) {
      setTimeout(() => setSelected(props.selected));
    }
  }, [props.selected]);

  return (
    <TagPicker
      label={ props.label }
      selectedItems={ values }
      disabled={ props.disabled }
      removeButtonAriaLabel="Remove"
      onResolveSuggestions={ getList }
      onItemSelected={ (item) => item.disabled ? null : item }
      getTextFromItem={ (item) => convert(item.name.toString()) }
      pickerSuggestionsProps={ suggestions }
      itemLimit={ props.itemLimit ? props.itemLimit : 1 }
      inputProps={ inputProps }
      onRenderItem={ renderItem }
      onRenderSuggestionsItem={ renderSuggestionItem }
      styles={ pickerStyles }
      onEmptyResolveSuggestions={ () => setQuery("") }
      onChange={ (items) => {
        setValues(items);
        props.onChange((props.itemLimit > 1) ? items : (items[0] || {}));
      }}
    />
  );
};
