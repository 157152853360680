import React, { memo } from "react";
import { Toggle } from "@fluentui/react";

export default memo((props) => {
  return (
    <Toggle { ...props }
      styles={{
        pill: { border: "none", borderRadius: "3px", height: "31px" },
        thumb: { borderRadius: "3px", height: "20px" },
        text: { lineHeight: "30px" },
        ...props.styles
      }} />
  );
});
