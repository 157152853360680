import { makeStyles, shorthands } from "@fluentui/react-components";

const modalContainer = {
  height: "90%"
};

const stack = {
  width: "100% !important",
  height: "100% !important",
  display: "flex",
  flexDirection: "column",
};

export const useStyles = makeStyles({
  modalContainerBig: {
    width: "92% !important",
    maxWidth: "92% !important",
    ...modalContainer
  },
  modalContainerSmall: {
    width: "250px !important",
    maxWidth: "250px !important",
    ...modalContainer
  },
  modalContainerMedium: {
    width: "880px !important",
    maxWidth: "880px !important",
    ...modalContainer
  },
  modalContainerMediumLarge: {
    width: "1200px !important",
    maxWidth: "1200px !important",
    ...modalContainer
  },
  modalContainerDefault: {
    width: "450px !important",
    maxWidth: "450px !important",
    ...modalContainer
  },
  modalTitle: {
    ...shorthands.padding("10px", "10px", "10px", "10px"),
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
  },
  modalBody: {
    width: "100%",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
  },
  header: {
    width: "100%",
    ...shorthands.padding("5px", "0px", "20px", "0px"),
  },
  stack: {
    ...stack,
    ...shorthands.overflow("hidden")
  },
  scrollableStack: {
    ...stack,
    ...shorthands.overflow("auto")
  }
});