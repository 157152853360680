import { useEffect, useState, useContext, forwardRef } from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";

import { Api, MainContext } from "src/utils";
import { IGridMainProps } from "src/types";

import GridRenderer from "./GridRenderer";

interface IProps extends IGridMainProps {
  id?: string;
  cols?: any;
  ordering?: Array<any>;
  defaultColumnPreferences?: Array<any>;
  ifVisibleColsChange?: Function;
  retrieveData?: any;
  hiddenColumns?: Array<any>;
  onNoData?: any;
}

export default observer(forwardRef((props:IProps, ref) => {
  const Store = useContext(MainContext);

  const [reset, setReset] = useState(false);
  const [data, setData] = useState(props.data);

  // generate the table_id used for settings saving/retrivial based on the
  // current page path and the id specified in the props if any
  // remove optional params
  const location = useLocation();
  const path = location.pathname.split(/\/[a-z]+\=/)[0].substring(1).replace(/\/\s*$/, "");
  const table_id = path + (props.id ? ("/" + props.id) : "");

  // convert sort field name to field index
  let ordering = props.ordering ? [...props.ordering] : undefined;
  if (ordering && typeof ordering[0] != "number") {
    ordering[0] = props.cols.findIndex(col => col.key === String(ordering[0]));
  }

  // hide columns if requested
  const hiddenCols = props.hiddenColumns ? [...props.hiddenColumns] : [];
  const preferences = Store.getCols(table_id);

  // pinned columns handling
  for (let column of props.cols) {
    if (preferences.pinned_left?.includes(column.key)) {
      column.pinned = "left";
      continue;
    }
    if (preferences.pinned_right?.includes(column.key)) {
      column.pinned = "right";
      continue;
    }
    column.pinned = undefined;
  }
  
  // get user define table columns preferences
  const visibleCols = (
    ((props.allowColumnsCustomization !== false) ? preferences?.columns : undefined)
    || props.defaultColumnPreferences
    || props.cols.map(col => col.key)
  ).filter(colKey => hiddenCols.indexOf(colKey) < 0);

  // fire at beginning the callback for visibility changes (this allows Gantt
  // and Pert to get to know their respective grid width at startup)
  if (props.ifVisibleColsChange && visibleCols) {
    props.ifVisibleColsChange(visibleCols);
  }

  useEffect(() => {
    // allow retrieveData as a function that retrieves it
    if (props.retrieveData) {
      setReset(true);
      Api.get(props.retrieveData).then(data => {
        setData(data.map(row => Object.values(row)));
        setTimeout(() => setReset(false));
      });
    }
  }, []);
  
  useEffect(() => {
    let data = props.data;
    setData(data);
  }, [props.data]);

  // construct grid options based on useful default options and the ones
  // passed with props. Props can overwrite the default options.
  let options = {
    hiddenCols,
    visibleCols: (
      visibleCols
        ? props.cols
          .filter(col => visibleCols.includes(col.key))
          .map(col => col.key)
        : undefined
    ),
    onVisibleColsChange: (columnKeys) => {
      setReset(true);
      
      // tell the dispatcher to save the user preferences
      Store.setCols(table_id, columnKeys, () => {
        setReset(false);
      });

      // props callback if any
      if (props.ifVisibleColsChange) {
        props.ifVisibleColsChange(columnKeys);
      }
    },
    onColumnPin: async ({ columnKey, pin = undefined }) => {
      setReset(true);
      Store.setColsPin({
        tableId: table_id,
        columnKey,
        pin,
        callback: () => {
          setReset(false);
        }
      });
    },
    crossHovering: true,
    ...props,
    data: data || [],
    rowStatus: props.rowStatus || data.map(() => null),
    ordering,
    reset,

    // the columns list has to be converted into an array in order to be
    // compatible with Grid endpoints
    cols: props.cols,
    absolute_id: table_id
  };

  if ((!data || !data.length) && props.onNoData) {
    return props.onNoData;
  }

  return <GridRenderer ref={ ref } { ...options } />;
}));
