export const getDataObject = ({ rows, cols }) => {
  return rows.map(row => {
    if (!Array.isArray(row)) {
      return row;
    }
    const dataRow = {};
    row.forEach((cell, index) => {
      dataRow[cols[index]?.key] = cell;
    });
    return dataRow;
  });
};