function decodeHTMLEntities (text) {
  const span = document.createElement('span');

  return text.replace(/&[#a-z0-9]+;/gi, (entity)=> {
    span.innerHTML = entity;
    return span.innerText;
  });
}

export const htmlToText = (str) => {
  if (str === undefined) return "";
  return decodeHTMLEntities(str.toString()).replace(/<[^>]+>/g, '');
}