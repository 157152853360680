export default {
  user: {
    id: 0,
    name: "",
    img: "",
    company_id: 0,
    ou_id: 0,
    isLoggedIn: 0,
    token: "",
    background: "",
    n_managed_ofi: 0,
    n_assigned_ofi: 0,
    n_managed_tasks: 0,
    n_assigned_tasks: 0,
    isAdmin: 0,
    isOwner: 0,
    isManager: 0,
    hasKey: ""
  },
  users: [],
  privileges: [],
  tools: [],
  globals: [],
  companies: [],
  ou: [],
  roles: [],
  column_settings: []
};
