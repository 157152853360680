import React from "react";

import { convert } from "html-to-text";
import { TagItem, Text } from "@fluentui/react";

import { objectStyles } from "./_styles";

// apply styles and hide HTML tags
export const renderItem = (props) => {
  return (
    <TagItem 
      { ...props }
      styles={{ 
        root: { ...objectStyles[props.item.status] }
      }}
    >
      { convert(props.item.name) }
    </TagItem>
  );
};

// apply styles and hide HTML tags
export const renderSuggestionItem = (item) => {
  return (
    <Text 
      styles={{
        root: {
          ...objectStyles[item.status], 
          padding: 10
        }
      }}
    >
      { convert(item.name) }
    </Text>
  );
};