import * as CONF from "./_config";

import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react-lite";

import { MainContext, Api } from "src/utils";
import { NotesDialog, GridDialog } from "src/comps";
import { DefaultButton } from "@fluentui/react";

import { IProps, IReqLink } from "./index.types";

export const AvailableInReqModal = observer((props:IProps) => {
  const Store = useContext(MainContext);

  const [data, setData] = useState([]);
  const [objectId, setObjectId] = useState<number>(undefined)
  const [selRow, setSelRow] = useState<IReqLink>(undefined);

  const getData = async () => {
    const result = await Api.get({
      api: "compliance/get-available-in-reqs",
      data: {
        id: props.selObjectId,
        type: props.type
      }
    });

    setData(result.map(el => {
      return {
        print: [true, false, true],
        compliance: [el.compliance, false, true],
        cm: el.cm,
        pos_cm: [
          el.pos, "",
          (el.status == '2') ? "" : "danger no-link"
        ],
        title: [
          el.title,
          "/app/#/dcss/compliance-matrix/mode=1&id=" + el.id,
          "link-blue"
        ],
        description: [
          el.description, "",
          (el.status == '2') ? "" : "danger no-link"
        ],
        authority: el.authority,
        last_note: el.last_note,
        trd_prty_demo: el.trd_prty_demo,
        demo_method: el.demo_method,

        // non-visible-fields
        id: el.id,
        link_id: el.link_id,
        link_row_id: el.link_row_id
      };
    }));
  };

  useEffect(() => {
    const sel = Array.isArray(props.selObjectId) ? props.selObjectId[0] : props.selObjectId;
    if (sel !== objectId) {
      getData();
      setObjectId(sel);
    }
  }, [props.selObjectId]);

  const contextMenu = ({ rowIndex }) => {
    const row = data[rowIndex];
    setSelRow(row);

    return [
      {
        name: "Notes",
        icon: "QuickNote",
        fn: () => Store.openModal("link-notes")
      }
    ];
  };

  const onEditableCheckClick = {
    msg: "Are you sure you would like to toggle this object compliance status",
    callback: ({ colKey, rowIndex }) => {
      let newData = [...data];
      switch (colKey) {
        case "print": {
          newData[rowIndex].print[0] = !newData[rowIndex].print[0];
          break;
        }
        case "compliance": {
          const current = data[rowIndex];
          const status = (!+current.compliance[0]) ? 1 : 0;

          Api.get({
            api: "link/toggle-compliance",
            data: {
              id: current.link_row_id,
              status
            },
            msg: "Done"
          });

          let i = data.indexOf(current);
          newData[i].compliance = [status, false, true];
          break;
        }
      }
      setData(newData);
    }
  };

  const options = {
    id: "reqs",
    title: " ",
    data: data.map(d => Object.values(d)),
    rowStatus: (data || []).map(d => null),
    cols: CONF.COLS.grid,
    contextMenu,
    onEditableCheckClick
  };

  const onAddNote = (note) => {
    const newData = [...data];
    newData[data.indexOf(selRow)].last_note = note;
    setData(newData);
  };

  const onArchiveNote = ({ last_active }) => {
    const newData = [...data];
    newData[data.indexOf(selRow)].last_note = last_active.note;
    setData(newData);
  };

  const onPrintRequest = async () => Store.setConfirm(
    await import("./print").then(({ print }) => print({
      selObjectId: props.selObjectId,
      type: props.type,
      data
    }))
  );

  const header = (
    <DefaultButton
      text={ "Print" }
      iconProps={{ iconName: "Print" }}
      disabled={ !data.length }
      styles={{ root: { margin: "0 10px" }}}
      onClick={ onPrintRequest }
    />
  );

  return (
    <>
      <NotesDialog
        id={ "link-notes" }
        refID={ selRow?.link_row_id }
        title={ "Notes" }
        basePath={ "link" }
        get={ "get-notes" }
        archive={ "archive-note" }
        save={ "add-note" }
        saveData={{ id: selRow?.link_row_id }}
        onAdd={ onAddNote }
        onArchive={ onArchiveNote }
      />

      <GridDialog
        id={ props.id || "reqs"}
        title="Requirements"
        options={ options }
        header={ header }
      />
    </>
  );
});
