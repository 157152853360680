import { useContext } from "react";
import { observer } from "mobx-react-lite";

import { ThemeContext } from "src/utils/themes";
import { IDatePickerProps } from "@fluentui/react";
import { Input } from "@fluentui/react-components";

import MultiDatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import { useStyles } from "./index.style";
import "./_styles.css";

interface IProps extends IDatePickerProps {
  multi?: boolean;
  fullYear?: boolean;
  useCalendar?: boolean;
  readOnly?: boolean;
}

export default observer((props:IProps) => {
  const Theme = useContext(ThemeContext);
  const classes = useStyles();

  if (props.multi === true) {
    // TODO: check support and style non-calendar multi mode
    const Component = props.useCalendar ? Calendar : MultiDatePicker;

    return (
      <Component
        multiple
        fullYear={ props.fullYear }
        currentDate={ new DateObject() } // show calendar at current date
        shadow={ false }
        weekStartDayIndex={ 1 }
        className={ Theme.getDarkMode() ? "bg-dark theme-clr" : "theme-clr" }
        disabled={ props.disabled }
        readOnly={ props.readOnly }
        value={ props.value } 
        onChange={ (dates) => props.onSelectDate(dates) }
      />
    )
  }

  return (
    <Input
      type={ "date" }
      className={ classes.input }
      aria-label={ props.ariaLabel }
      value={ props.value?.toString() }
      placeholder={ props.placeholder }
      disabled={ props.disabled }
      onChange={ (e, data) => props.onSelectDate(data.value) }
    />
  );
});
