function searchRow({
  colKey,
  matrix,
  query,
  strictMode = false,
  exactMatch = false,
  colInfo,
  Store,
}) {
  // Early return if query is empty or not provided
  if (
    !query ||
    (Array.isArray(query) && query.length === 0)
  ) {
    return [];
  }

  let queries = [];
  let isAND = false;

  // Normalize the query into an array of queries
  if (typeof query === "string") {
    if (query.includes(" && ")) {
      isAND = true;
      queries = query.split(" && ");
    } else {
      queries = [query];
    }
  } else if (Array.isArray(query)) {
    queries = query;
  } else {
    queries = [query.toString()];
  }

  // Preprocess queries based on strictMode
  if (strictMode !== true) {
    queries = queries.map((q) => q.toString().toLowerCase());
  }

  // Handle range selection separately
  if (colInfo.filter && colInfo.filter.range === true) {
    let rangeQuery = queries;

    if (!Array.isArray(rangeQuery)) {
      rangeQuery = rangeQuery[0].split("-");
    }

    if (rangeQuery.length !== 2) {
      return [];
    }

    const [min, max] = rangeQuery.map(Number);
    const results = [];

    for (let i = 0; i < matrix.length; i++) {
      const row = matrix[i];
      const value = getCellValue(row, colKey, colInfo, Store);

      const numValue = Number(value);
      if (numValue >= min && numValue <= max) {
        results.push(i);
      }
    }
    return results;
  }

  const results = new Set();

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i];
    let value = getCellValue(row, colKey, colInfo, Store);

    if (strictMode !== true) {
      value = value.toLowerCase();
    }

    let matchCount = 0;

    for (let allowedValue of queries) {

      //! allowedValue === "" handle special cases like an empty option searched
      if ((exactMatch && !isAND)|| allowedValue === "") {
        if (value === allowedValue || value.includes(allowedValue)) {
          results.add(i);
          break;
        }
      } else {
        if (value.includes(allowedValue) || value === allowedValue) {
          if (isAND) {
            matchCount++;
          } else {
            results.add(i);
            break;
          }
        }
      }
    }

    if (isAND && matchCount === queries.length) {
      results.add(i);
    }
  }

  return Array.from(results);
}

// Helper function to extract and normalize cell value
function getCellValue(row, colKey, colInfo, Store) {
  let cell = row[colKey];
  if (!cell) return "";

  let value = Array.isArray(cell) ? cell[0] : cell;

  if (colInfo.type === "persona") {
    if (value && value.id) {
      value = value.fullName || "";
    } else if (value == +value) {
      value = (Store.getUser(value) || {}).fullName || "";
    }
  }

  const result = (value !== null && value !== undefined) ? value.toString() : "";

  return result;
}

export { searchRow };
