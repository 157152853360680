import { Api } from "./api";

// increment this when everything will be on the new platform
// (not compatible with DCSS3 superuser feature)
const TEN_SECONDS = .1 * 60 * 1000;

export async function checkLogin({ store, state = undefined, force = false}) {
  // use cached data in local storage if any (and not expired)
  let storage = await JSON.parse(localStorage.getItem("basics"));

  if (!force && (!state || state == "already") && storage) {
    // check if local storage is not older than 5 minutes (expired otherwise)
    if ((+storage.timestamp + TEN_SECONDS) > (new Date()).getTime()) {
      return storage;
    }
    else {
      localStorage.removeItem("basics");
    }
  }
  // if the session info is not available in local storage request it to APIs
  return await Api.call({
    path: "session/get-login-basics",
    store,
    success: response => {
      if (!storage) {
        response.timestamp = (new Date()).getTime().toString();
        localStorage.setItem("basics", JSON.stringify(response));
      }
      return response;
    },
    failure: (response) => login({ store })
  });
}

export function login({ store }) {
  return Api.call({
    path: "session/login",
    store,
    success: response => {
      // double check login and get data if succesful logged in
      if (response != "none") {
        if (response != "already") {
          // seek for the user current location
          window.navigator.geolocation.getCurrentPosition(
            (position) => {
              Api.call({
                path: "session/save-position",
                silent: true,
                data: {
                  lat: position.coords.latitude,
                  lon: position.coords.longitude
                }
              })
            },
            () => 0,
            {
              maximumAge: 60000,
              timeout: 4000,
              enableHighAccuracy: true
            }
          );
        }
        // calls the function to get user info from the APIs, but it avoid
        // the call in case of an SLT and create itself the needed data
        return checkLogin({ store, state: response });
      }

      // in case of invalid login credentails
      // web app should redirect to the login, while the desktop app
      // should provide the in-app login form
      return undefined;
    }
  });
};
