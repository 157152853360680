import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import { Panel } from "@fluentui/react";

import { isMobile } from "react-device-detect";
import { Button } from "@fluentui/react-components";
import { Settings24Regular } from "@fluentui/react-icons";

import { useStyles } from "./index.styles";

export interface IFilterBarOption {
  hidden?: boolean;
  label?: string;
  component: JSX.Element;
};

interface IProps {
  children?: JSX.Element|JSX.Element[];
};

type ActionBarType = React.FC<IProps> & {
  MainCommands?: React.FC<{ children: JSX.Element|JSX.Element[] }>;
  SideCommands?: React.FC<{ children: JSX.Element|JSX.Element[] }>;
};

export const ChoiceGroupFloatLeft = {
  root: {
    width: "auto",
    float: "left",
    marginRight: "10px"
  }
};

const MainCommands = observer((props:IProps) => {
  const classes = useStyles();
  return (
    <div className={ classes.mainCommads }>
      {props.children}
    </div>
  );
});

const SideCommands = observer((props:IProps) => {
  const classes = useStyles();
  return (
    <div className={ classes.sideCommands }>
      { props.children }
    </div>
  );
});

export const ActionBar: ActionBarType = observer((props:IProps) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const classes = useStyles();

  const commands = (
    <div id="actionBar">
      <div className={ classes.commands }>
        { props.children }
      </div>
    </div>
  );
  
  // return commands in a panel if on mobile
  return (isMobile && props.children) ? (
    <>
      <Panel
        isOpen={ isPanelOpen }
        onDismiss={ () => setIsPanelOpen(false) }
        isBlocking={ false }
        closeButtonAriaLabel="Close"
      >
        { commands }
      </Panel>
      <Button
        className={ classes.mobileButton }
        icon={ <Settings24Regular/> }
        onClick={ () => setIsPanelOpen(true) }
      >
        Actions
      </Button>
    </>
  ) : commands;
});
ActionBar.MainCommands = MainCommands;
ActionBar.SideCommands = SideCommands;

export default ActionBar;
