import React from "react";

import SplitPane, { Pane } from "react-split-pane";
import AutoSizer from "react-virtualized-auto-sizer";

// function to get the current first pane width if saved in local storage, this
// does not involved the default size (it is not returned)
export const getSplitPaneVal = (id) => {
  return parseInt(localStorage.getItem(id), 10);
};

export const DwaSplitPane = (props) => {
  return (
    <AutoSizer>
      {({ width, height }) => {
        // the split pane container height should take into account the actions
        // bar that is probably sitting over this component on the page
        if (props.cleanHeight !== true) {
          const actionBar = document.getElementById("actionBar");
          if (actionBar) {
            height -= actionBar.offsetHeight;
          }
        }

        return (
          <SplitPane
            style={{ ...(props.styles || {}), width, height }}
            split={ props.split }
            maxSize={ width - 50 }
            defaultSize={
              parseInt(localStorage.getItem(props.id), 10) || props.defaultSize
            }
            onChange={ (size) => {
              localStorage.setItem(props.id, size);
              if (props.onChange) {
                props.onChange(size);
              }
            }}
          >
            <Pane
              className="pane1"
              initialSize={ props.defaultSize }
            >
              <div style={{ height }}>
                { props.components({ width, height })[0] || <div/> }
              </div>
            </Pane>
            <Pane
              className="pane2"
            >
              <div style={{ height }}>
                { props.components({ width, height })[1] || <div/> }
              </div>
            </Pane>
          </SplitPane>
        );
      }}
    </AutoSizer>
  );
};
